import React from "react";
import PrimaryHeading from "components/heading/PrimaryHeading";
import SecondaryHeading from "components/heading/SecondaryHeading";
import AddContent from "./componets/addContent";
import TableContent from "./componets/tableContent";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";

const Content = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);

  if (isLoading === true) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <PrimaryHeading title={"Add Content"} />
      <AddContent />
      <SecondaryHeading title={"Content"} />
      <TableContent />
    </div>
  );
};

export default Content;
