import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useState } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SecondaryHeading from "components/heading/SecondaryHeading";
import MainTable2 from "components/table/Tablev2";
import { updateRefresher } from "features/refresher";
import { useDispatch, useSelector } from "react-redux";

const TableBook = (props) => {
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const refresh = useSelector(state=>state.refresher.value.refreshCount)
  
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setid] = React.useState("");

  // Define state variables for the book properties
  const [bookName, setBookName] = useState("");
  const [bookShortDescription, setBookShortDescription] = useState("");
  const [bookLongDescription, setBookLongDescription] = useState("");
  const [bookImage, setBookImage] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookPages, setBookPages] = useState("");
  const [bookGenre, setBookGenre] = useState("");
  const [bookGoodreadsRating, setBookGoodreadsRating] = useState("");
  const [bookUserRating, setBookUserRating] = useState("");
  const [bookAwards, setBookAwards] = useState("");
  const [bookYearPublished, setBookYearPublished] = useState("");
  const [bookBuy, setBookBuy] = useState("");
  const [bookPublisher, setBookPublisher] = useState("");
  const [bookWTR, setBookWTR] = useState(0);
  const [bookHomepage, setBookHomepage] = useState("off");
  const [bookSerialNumber, setBookSerialNumber] = useState("");
  const [bookAvailable, setBookAvailable] = useState("N/A");

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/Books/" + id, {
        bookName,
        bookShortDescription,
        bookLongDescription,
        bookImage,
        bookAuthor,
        bookPages,
        bookGenre,
        bookGoodreadsRating,
        bookUserRating,
        bookAwards,
        bookYearPublished,
        bookBuy,
        bookPublisher,
        bookWTR,
        bookHomepage,
        bookSerialNumber,
        bookAvailable,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'Book "' + res.data.bookName + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({refreshCount:refresh+1}))
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("Book with this Name already exists!");
        }
      });
    setIsLoading2(false);
  };

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " Book ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/Books/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("Book is deleted Successfully!");
            // setData(data.filter((res) => res._id !== id));
            dispatch(updateRefresher({refreshCount:refresh+1}))
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    toast.info('Looking for book in database')
    axiosInstance.get('/books/'+id).then(res=>{
    toast.success('Successfully Found!')
    const bookData = res.data;
    const {
      _id,
      bookName,
      bookShortDescription,
      bookLongDescription,
      bookImage,
      bookAuthor,
      bookPages,
      bookGenre,
      bookGoodreadsRating,
      bookUserRating,
      bookAwards,
      bookYearPublished,
      bookBuy,
      bookPublisher,
      bookWTR,
      bookHomepage,
      bookSerialNumber,
      bookAvailable,
    } = bookData;

    setBookName(bookName);
    setBookShortDescription(bookShortDescription);
    setBookLongDescription(bookLongDescription);
    setBookImage(bookImage);
    setBookAuthor(bookAuthor);
    setBookPages(bookPages);
    setBookGenre(bookGenre);
    setBookGoodreadsRating(bookGoodreadsRating);
    setBookUserRating(bookUserRating);
    setBookAwards(bookAwards);
    setBookYearPublished(bookYearPublished);
    setBookBuy(bookBuy);
    setBookPublisher(bookPublisher);
    setBookWTR(bookWTR);
    setBookHomepage(bookHomepage);
    setBookSerialNumber(bookSerialNumber);
    setBookAvailable(bookAvailable);
    setid(_id);
    }).catch(err=>{
      console.log(err)
    })
  };

  return (
    <>
      {/* <button onClick={()=>{setRefresh(refresh+1)}} className='linear mt-2 me-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'><MdRefresh/></button>  */}
      {(
        <div>
          <MainTable2
            url={'/books/allbyQuery'}
            tableKeys={[
              // "_id",
              "bookName",
              //"bookShortDescription",
              //"bookLongDescription",
              //"bookImage",
              "bookAvailable",
              "bookHomepage",
              "bookSerialNumber",
              "bookWTR",
              "bookAuthor",
              "bookPages",
              "bookGenre",
              "bookGoodreadsRating",
              "bookUserRating",
              "bookAwards",
              "bookYearPublished",
              "bookBuy",
              "bookPublisher",
            ]}
            refresh={refresh}
            editFunc={editFunc}
            deleteFunc={deleteFunc}
          />
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[25vh] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-lg border bg-white p-4 overflow-y-scroll h-[80vh] dark:border-navy-900 dark:bg-navy-800">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Book Name */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Book Name"
                      placeholder="Enter Book Name"
                      id="bookName"
                      type="text"
                      value={bookName}
                      onChange={(e) => setBookName(e.target.value)}
                    />
                  </div>
                  {/* Book Short Description */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Short Description"
                      placeholder="Enter Short Description"
                      id="bookShortDescription"
                      type="text"
                      value={bookShortDescription}
                      onChange={(e) => setBookShortDescription(e.target.value)}
                    />
                  </div>
                  {/* Book Long Description */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Long Description"
                      placeholder="Enter Long Description"
                      id="bookLongDescription"
                      type="text"
                      value={bookLongDescription}
                      onChange={(e) => setBookLongDescription(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Book Image */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Image URL"
                      placeholder="Enter Image URL"
                      id="bookImage"
                      type="text"
                      value={bookImage}
                      onChange={(e) => setBookImage(e.target.value)}
                    />
                  </div>
                  {/* Book Author */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Author"
                      placeholder="Enter Author"
                      id="bookAuthor"
                      type="text"
                      value={bookAuthor}
                      onChange={(e) => setBookAuthor(e.target.value)}
                    />
                  </div>
                  {/* Book Pages */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Number of Pages"
                      placeholder="Enter Number of Pages"
                      id="bookPages"
                      type="text"
                      value={bookPages}
                      onChange={(e) => setBookPages(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Book Genre */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Genre"
                      placeholder="Enter Genre"
                      id="bookGenre"
                      type="text"
                      value={bookGenre}
                      onChange={(e) => setBookGenre(e.target.value)}
                    />
                  </div>
                  {/* Book Goodreads Rating */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Goodreads Rating"
                      placeholder="Enter Goodreads Rating"
                      id="bookGoodreadsRating"
                      type="text"
                      value={bookGoodreadsRating}
                      onChange={(e) => setBookGoodreadsRating(e.target.value)}
                    />
                  </div>
                  {/* Book User Rating */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="User Rating"
                      placeholder="Enter User Rating"
                      id="bookUserRating"
                      type="text"
                      value={bookUserRating}
                      onChange={(e) => setBookUserRating(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Book Awards */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Awards"
                      placeholder="Enter Awards"
                      id="bookAwards"
                      type="text"
                      value={bookAwards}
                      onChange={(e) => setBookAwards(e.target.value)}
                    />
                  </div>
                  {/* Book Year Published */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Year Published"
                      placeholder="Enter Year Published"
                      id="bookYearPublished"
                      type="text"
                      value={bookYearPublished}
                      onChange={(e) => setBookYearPublished(e.target.value)}
                    />
                  </div>
                  {/* Book Buy Link */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Buy Link"
                      placeholder="Enter Buy Link"
                      id="bookBuy"
                      type="text"
                      value={bookBuy}
                      onChange={(e) => setBookBuy(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Book Publisher */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Publisher"
                      placeholder="Enter Publisher"
                      id="bookPublisher"
                      type="text"
                      value={bookPublisher}
                      onChange={(e) => setBookPublisher(e.target.value)}
                    />
                  </div>
                  {/* Book Weight to Read */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Weight to Read"
                      placeholder="Enter Weight to Read"
                      id="bookWTR"
                      type="number"
                      value={bookWTR}
                      onChange={(e) => setBookWTR(Number(e.target.value))}
                    />
                  </div>
                  {/* Book Homepage */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Homepage"
                      placeholder="Enter Homepage"
                      id="bookHomepage"
                      type="text"
                      value={bookHomepage}
                      onChange={(e) => setBookHomepage(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Book Serial Number */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Serial Number"
                      placeholder="Enter Serial Number"
                      id="bookSerialNumber"
                      type="number"
                      value={bookSerialNumber}
                      onChange={(e) =>
                        setBookSerialNumber(Number(e.target.value))
                      }
                    />
                  </div>
                  {/* Book Available */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Available"
                      placeholder="Enter Available"
                      id="bookAvailable"
                      type="text"
                      value={bookAvailable}
                      onChange={(e) => setBookAvailable(e.target.value)}
                    />
                  </div>
                  {/* Add more fields as needed */}
                </div>

                {isLoading2 ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  <button
                    type={"submit"}
                    className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Save Changes
                  </button>
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableBook;
