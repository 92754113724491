import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import DevelopmentTable from "views/admin/tables/components/DevelopmentTable";
import useAxiosInstance from "../../../../axiosInstance";
import SelectField from "components/fields/selectField";
import SecondaryHeading from "components/heading/SecondaryHeading";
import PreviewInvoice from "./previewInvoice";
import { useNavigate } from "react-router-dom";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import { MdList, MdSearch } from "react-icons/md";
import { FaCross } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import { useChannel } from "ably/react";

import { v4 as uuidv4 } from 'uuid';

const AddInvoice = (props) => {
  const userRole = useSelector((state) => state.users.value.role);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data } = props;
  const [isLoading, setIsLoading] = useState(false);

  //discount choice state
  const [selectedDiscount, setSelectedDiscount] = useState("");
  const [selectedDiscountPrice, setSelectedDiscountPrice] = useState(0);

  //States for price
  const [customerName, setCustomerName] = useState("");
  const [customerMb, setCustomerMb] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");

  const [productType, setProductType] = useState("");

  //service Informations
  const [description, setDescription] = useState("");
  const [qty, setQty] = useState(0);
  const [unitPrice, setUnitPrice] = useState();
  const [totalPrice, setTotalPrice] = useState();
  const [avaliableQty, setAvaliableQty] = useState();

  //discount Informations
  const [discountDescription, setDiscountDescription] = useState("");
  const [discountQty, setDiscountQty] = useState(0);
  const [discountUnitPrice, setDiscountUnitPrice] = useState();
  const [discountTotalPrice, setDiscountTotalPrice] = useState();
  const [discountArray, setDiscountArray] = useState([]);

  //Product Array Management

  const [productsArray, setProductsArray] = useState([]);

  //addition of product
  const handleProductAddition = (e) => {
    e.preventDefault();
    const doesProductExixts = productsArray.find(
      (elem) => elem.description === description
    );
    if (doesProductExixts) {
      const productData = productsArray;
      const uniqueData = productData.filter(
        (elem) => elem.description !== doesProductExixts.description
      );
      setProductsArray(
        uniqueData.concat({
          description: description,
          qty: Number(doesProductExixts.qty) + Number(qty),
          unitPrice: unitPrice,
          totalPrice: unitPrice * (Number(doesProductExixts.qty) + Number(qty)),
          type: productType,
          action: description,
        })
      );
    } else {
      setProductsArray([
        ...productsArray,
        {
          description: description,
          qty: Number(qty),
          unitPrice: unitPrice,
          totalPrice: totalPrice,
          type: productType,
          action: description,
        },
      ]);
    }
    toast.success(description + " Added to List Successfully");
    // setProductType("");
    // setDescription("");
    setQty(0);
    setTotalPrice(0);
    setUnitPrice(0);
  };

  //addition of discount
  const handleDiscountAddition = (e) => {
    e.preventDefault();
    const doesDiscountExixts = discountArray.find(
      (elem) => elem.description === description
    );
    if (doesDiscountExixts) {
      const discountData = discountArray;
      const uniqueData = discountData.filter(
        (elem) => elem.description !== doesDiscountExixts.description
      );
      setDiscountArray(
        uniqueData.concat({
          description: discountDescription,
          qty: Number(doesDiscountExixts.qty) + Number(discountQty),
          unitPrice: discountUnitPrice,
          totalPrice:
            discountUnitPrice *
            (Number(doesDiscountExixts.qty) + Number(discountQty)),
          action: discountDescription,
        })
      );
    } else {
      setDiscountArray([
        ...discountArray,
        {
          description: discountDescription,
          qty: Number(discountQty),
          unitPrice: discountUnitPrice,
          totalPrice: discountTotalPrice,
          action: discountDescription,
        },
      ]);
    }
    toast.success(discountDescription + " Added to Discount List Successfully");
    // setDiscountDescription("");
    setDiscountQty(0);
    setDiscountTotalPrice(0);
    setDiscountUnitPrice(0);
  };

  //errors for price
  const [nameMessage, setnameMessage] = useState("");
  const [nameState, setnameState] = useState();

  const [priceMessage, setpriceMessage] = useState("");
  const [priceState, setpriceState] = useState();

  function isValidGmailname(name) {
    // Regular expression for name validation ending with "@gmail.com"
    const gmailRegex = /^[a-zA-Z0-9._-]+@meepleton\.com$/;

    return gmailRegex.test(name);
  }

  //Total Management State and UseEffect
  const [intermidiateTotal, setIntermidiateTotal] = useState(0);
  const [gamingTotal, setGamingTotal] = useState(0);
  const [fNbTotal, setFnBTotal] = useState(0);
  const [eventsTotal, seteventsTotal] = useState(0);

  const [pendingArray, setPendingArray] = useState([]);

  //geting pending array data from localstorge
  useEffect(() => {
    let pendingData = JSON.parse(localStorage.getItem("PendingInvoices"));
    if (pendingData) {
      setPendingArray(pendingData);
    }
  }, []);

  //calculating intermididate, gaming, fnb and events total
  useEffect(() => {
    let intermidiateTotal = 0;
    let gamingTotal = 0;
    let fNbTotal = 0;
    let eventsTotal = 0;
    productsArray.forEach((elem) => {
      intermidiateTotal += elem.totalPrice;
      if (elem.type === "GAMING") {
        gamingTotal += elem.totalPrice;
      } else if (elem.type === "FNB") {
        fNbTotal += elem.totalPrice;
      } else if (elem.type === "EVENTS") {
        eventsTotal += elem.totalPrice;
      }
    });

    setIntermidiateTotal(intermidiateTotal);
    setGamingTotal(gamingTotal);
    setFnBTotal(fNbTotal);
    seteventsTotal(eventsTotal);
    console.log(productsArray);
  }, [productsArray]);

  const [games, setGames] = useState([]);

  //calcutaling toaotal discount amount and selected discounts name
  useEffect(() => {
    let discountName = [];
    let discountAmount = 0;
    discountArray.forEach((elem) => {
      discountAmount += elem.totalPrice;
      if (!discountName.includes(elem.description)) {
        discountName.push(elem.description);
      }
    });

    setSelectedDiscountPrice(discountAmount);
    setSelectedDiscount(discountName.toString());
  }, [discountArray]);

  const axiosInstance = useAxiosInstance();
  const history = useNavigate();

  //ably channel set up
  const { channel } = useChannel("Dashboard-Page", (message) => {
    console.log(message);
  });

  //submiting data to database
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log(submissionArray);
    axiosInstance
      .post("/invoice", submissionArray)
      .then((res) => {
        if (res.data) {
          //alert(res.data._id);
          // channel.publish('DashboardMessage','UPDATES_IN_DASHBOARD')
          toast.success(
            `Invoice for ${res.data.customerName} with a total of Rs${res.data.total} is created Successfully`
          );
          handleReset();
          var newData = pendingArray.filter((elem) => elem.id != pendingId);
          localStorage.setItem("PendingInvoices", JSON.stringify(newData));
          setPendingArray(newData);
          setPendingId("");
          window.open("/invoice/" + res.data._id, "_blank");
          // history();
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };
  const [pendingId, setPendingId] = useState("");
  //adding invoice to pending array
  const handleAddToPendingList = (e) => {
    let generatedId = uuidv4();
    e.preventDefault();
    console.log(submissionArray);
    if (pendingId) {
      let submissionArrayData = pendingArray.filter(
        (elem) => elem.id !== pendingId
      );
      localStorage.setItem(
        "PendingInvoices",
        JSON.stringify(
          submissionArrayData.concat({
            ...submissionArray,
            id: generatedId,
          })
        )
      );
      setPendingArray(
        submissionArrayData.concat({
          ...submissionArray,
          id: generatedId,
        })
      );
      console.log(pendingArray);
      setPendingId("");
      toast.success("Invoice Successfully updated in the Pending Invoice list");
    } else {
      localStorage.setItem(
        "PendingInvoices",
        JSON.stringify(
          pendingArray.concat({
            ...submissionArray,
            id: generatedId,
          })
        )
      );
      setPendingArray(
        pendingArray.concat({ ...submissionArray, id: generatedId })
      );
      toast.success("Invoice Successfully added to the Pending Invoice list");
    }
    handleReset();
  };

  //deleting a product from Product array
  const deleteFunc = (name) => {
    //alert(name+" delete")
    setProductsArray(productsArray.filter((elem) => elem.description !== name));
    toast.success(name + " is removed from list sucessfully");
  };

  //finding and filling product data in to product form
  const editFunc = (name) => {
    const productData = productsArray.find((elem) => elem.description === name);
    console.log(productData);
    setProductType(productData.type);
    setDescription(productData.description);
    setQty(productData.qty);
    setTotalPrice(productData.totalPrice);
    setUnitPrice(productData.unitPrice);

    toast.success(name + " data is filled in the form successfully");
    setProductsArray(productsArray.filter((elem) => elem.description !== name));
  };

  //deleting a discount
  const deleteDiscountFunc = (name) => {
    //alert(name+" delete")
    setDiscountArray(discountArray.filter((elem) => elem.description !== name));
    toast.success(name + " is removed from list sucessfully");
  };

  //editing a dicount by filling it the form
  const editDiscountFunc = (name) => {
    const discountData = discountArray.find(
      (elem) => elem.description === name
    );
    /* description:description,
            qty:qty,
            unitPrice:unitPrice,
            totalPrice:totalPrice,
            type:productType,
            action:description
        }])
        console.log(discountArray)
        toast.success(description+' Added to List Successfully');
        setProductType("")
        setDescription("")
        setQty(0)
        setTotalPrice(0)
        setUnitPrice(0) */

    setDiscountDescription(discountData.description);
    setDiscountQty(discountData.qty);
    setDiscountTotalPrice(discountData.totalPrice);
    setDiscountUnitPrice(discountData.unitPrice);

    toast.success(name + " data is filled in the form successfully");
    setDiscountArray(discountArray.filter((elem) => elem.description !== name));
  };

  //cutomerList filling on basis of customer list
  const handleMbSearch = () => {
    const customerFilteredData = data.customerData?.filter(
      (elem) => elem.customerMb === customerMb
    )[0];
    if (customerFilteredData) {
      setCustomerAddress(customerFilteredData.customerAddress);
      setCustomerName(customerFilteredData.customerName);
    }
    if (customerMb === "") {
      setCustomerAddress("");
      setCustomerName("");
    }
  };

  // States for last part of form
  const [gameHost, setGameHost] = useState("");
  const [gameHostId, setGameHostId] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  //setting up the time
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString(); // Add 1 to month because it's zero-based
    const day = today.getDate().toString();
    const formattedDate = `${month}/${day}/${year}`;
    setDate(formattedDate);

    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Asia/Kolkata",
    };
    const currentTime = today.toLocaleString("en-IN", timeOptions);
    setTime(currentTime);
  }, []);

  //formating the date in the required format
  const handleDate = (givenDate) => {
    const today = new Date(givenDate);
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString(); // Add 1 to month because it's zero-based
    const day = today.getDate().toString();
    const formattedDate = `${month}/${day}/${year}`;

    setDate(formattedDate);
  };

  //Empting the states
  const handleReset = () => {
    setSubmissionArray({});
    setCustomerName("");
    setCustomerMb("");
    setCustomerAddress("");
    setGameHost("");
    setGameHostId("");
    setModeOfPayment("");
    setGames([]);
    setProductsArray([]);
    setDiscountArray([]);
    setProductType("");
    setDescription("");
    setQty(0);
    setTotalPrice(0);
    setUnitPrice(0);
    setDiscountDescription("");
    setDiscountQty(0);
    setDiscountTotalPrice(0);
    setDiscountUnitPrice(0);
  };

  //submissionArray
  const [submissionArray, setSubmissionArray] = useState({});

  return (
    <div>
      <div className=" rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
        {/* <img src={navlogo} height={100}/> */}
        {/* name */}
        <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
          <ModalOverlay className="bg-[#000] !opacity-30" />
          <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[15vh]">
            <ModalBody className="rounded-xl bg-white p-3">
              {/* {onClose} */}
              <div className="flex w-full justify-end">
                <HiX
                  onClick={() => {
                    onClose();
                  }}
                />
              </div>
              <DevelopmentTable
                columnsData={[
                  {
                    Header: "ACTION",
                    accessor: "id",
                  },
                  {
                    Header: "CUSTOMER NAME",
                    accessor: "customerName",
                  },
                  {
                    Header: "CUSTOMER Mb",
                    accessor: "customerMb",
                  },
                  {
                    Header: "TIME",
                    accessor: "time",
                  },
                  {
                    Header: "TOTAL",
                    accessor: "total",
                  },
                  {
                    Header: "GAMING TOTAl",
                    accessor: "gamingTotal",
                  },
                  {
                    Header: "FNB TOTAl",
                    accessor: "fNbTotal",
                  },
                  {
                    Header: "CUSTOMER ADDRESS",
                    accessor: "customerAddress",
                  },
                ]}
                tableData={pendingArray}
                tableTitle={"Pending Invoices"}
                editFunc={(id) => {
                  console.log(id);
                  handleReset();
                  //pendingArray(pendingArray.filter(elem=>elem.id != id))
                  setPendingId(id);
                  console.log(pendingArray);
                  let data = pendingArray.find((elem) => elem.id === id);
                  console.log(data.customerName);
                  setCustomerName(data.customerName);
                  setCustomerMb(data.customerMb);
                  setCustomerAddress(data.customerAddress);
                  setGameHost(data.gameHost);
                  setGameHostId(data.gameHostId);
                  setModeOfPayment(data.modeOfTranscation);
                  setDate(data.date);
                  setTime(data.time);
                  setProductsArray(data.products);
                  setDiscountArray(data.discounts);
                  setGames(data.games);

                  // var newData = pendingArray.filter(elem=>elem.id != id)
                  // localStorage.setItem('PendingInvoices',JSON.stringify(newData))
                  // setPendingArray(newData)
                  onClose();
                }}
                deleteFunc={(id) => {
                  var newData = pendingArray.filter((elem) => elem.id != id);
                  localStorage.setItem(
                    "PendingInvoices",
                    JSON.stringify(newData)
                  );
                  setPendingArray(newData);
                }}
              />
            </ModalBody>
          </ModalContent>
        </Modal>

        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
            {/* Customer Mobile Number */}
            <div className="w-1/3 md-max:w-full">
              {priceMessage && (
                <span className="text-sm text-red-500 ms-1">
                  {priceMessage}
                </span>
              )}
              <div className="flex w-full items-center gap-1">
                <div className="w-full">
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Customer Mobile Number"
                    placeholder="Mb"
                    id="customerMb"
                    type="tel"
                    //state={priceState}
                    value={customerMb}
                    onChange={(e) => {
                      setCustomerMb(e.target.value);
                    }}
                  />
                </div>
                {customerMb.length === 10 && (
                  <div className="mt-4 cursor-pointer rounded-xl bg-teal-400 p-4 text-white">
                    <MdSearch
                      onClick={() => {
                        handleMbSearch();
                      }}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="w-1/3 md-max:w-full">
              {nameMessage && (
                <span className="text-red-400 ms-1">{nameMessage}</span>
              )}
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Name"
                placeholder="Name"
                id="customerName"
                type="name"
                //state={nameState}
                value={customerName}
                onChange={(e) => {
                  setCustomerName(e.target.value);
                }}
              />
            </div>
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Address"
                placeholder="Address"
                id="customerAddress"
                type="text"
                //state={priceState}
                value={customerAddress}
                onChange={(e) => {
                  setCustomerAddress(e.target.value);
                }}
              />
            </div>
          </div>
          {/* {customerMb.length >0 && (
            <>
              <label
                htmlFor="customertList"
                className="mb-2 mt-2 block text-sm font-medium text-gray-900 ms-2 dark:text-white"
              >
                Customer List
              </label>

              <select
                id="customerList"
                //value={productType}
                //onChange={(e) => {
                //  (e.target.value);
                //}}
                className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:border-gray-800 dark:text-gray-400"
              >
                <option value="" selected>
                  Choose
                </option>
                {
                  data.customerData.filter(elem=>elem.customerMb.includes(customerMb)).map(elem=>{
                    return <option value={elem._id}>{elem.customerName}</option>
                  })
                }
              </select>
            </>
          )} */}

          {/* Address */}

          {/* {isLoading?<button disabled className="linear text-center mt-2 px-4 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          <Loading/>          
        </button>:nameMessage ==="" && priceMessage ==="" && <button
        type={'submit'} 
        className="linear mt-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Add
        </button>
        } */}
        </form>
      </div>

      <div className="mt-3 rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
        <form
          onSubmit={(e) => {
            handleProductAddition(e);
          }}
        >
          <SelectField
            onChange={(e) => {
              setProductType(e.value);
            }}
            s
            isSearchable={false}
            selectedValue={[
              { label: "Choose", value: "" },
              { label: "Food and Beverages", value: "FNB" },
              { label: "Gaming", value: "GAMING" },
              { label: "Events", value: "EVENTS" },
            ].find((elem) => elem.value === productType)}
            label={"Service Type"}
            options={
              userRole === "ADMIN"
                ? [
                    { label: "Choose", value: "" },
                    { label: "Food and Beverages", value: "FNB" },
                    { label: "Gaming", value: "GAMING" },
                    { label: "Events", value: "EVENTS" },
                  ]
                : [
                    { label: "Choose", value: "" },
                    { label: "Food and Beverages", value: "FNB" },
                    { label: "Gaming", value: "GAMING" },
                  ]
            }
          />

          {productType !== "" &&
            (productType !== "EVENTS" ? (
              <>
                <SelectField
                  label="Service Description"
                  // value={description}
                  selectedValue={[
                    { label: "Choose", value: "" },
                    ...data.productData
                      .filter((elem) => elem.category === productType)
                      .map((elem) => ({
                        label: elem.name,
                        value: elem.name,
                      })),
                  ].find((elem) => elem.value === description)}
                  onChange={(e) => {
                    setDescription(e.value);
                    const productData = data.productData.find(
                      (elem) => elem.name === e.value
                    );

                    if (productData) {
                      if (productData.category === "FNB") {
                        setAvaliableQty(productData.qty);
                        setUnitPrice(productData.price);
                      } else {
                        setUnitPrice(productData.price);
                      }
                    }
                    setQty(0);
                  }}
                  options={[
                    { label: "Choose", value: "" },
                    ...data.productData
                      .filter((elem) => elem.category === productType)
                      .map((elem) => ({
                        label: elem.name,
                        value: elem.name,
                      })),
                  ]}
                />
                {description !== "" && (
                  <>
                    <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                      {productType === "FNB" ? (
                        <div className="w-1/3 md-max:w-full">
                          <InputField
                            variant="auth"
                            extra="mb-3"
                            label={"Avaliable Quantity"}
                            placeholder={"stocks"}
                            disabled={true}
                            id="productQty"
                            type="Number"
                            //state={nameState}
                            value={avaliableQty}
                          />
                        </div>
                      ) : (
                        <div className="-mt-2 w-1/3 md-max:w-full">
                          <SelectField
                            label={"Games"}
                            // value={games}
                            selectedValue={games.map((elem) => ({
                              label: elem.name,
                              value: elem.name,
                              gameId: elem.gameId,
                            }))}
                            onChange={(e) => {
                              setGames([
                                ...e.map((elem) => ({
                                  name: elem.value,
                                  gameId: elem.gameId,
                                })),
                              ]);
                            }}
                            isMulti={true}
                            options={[
                              ...data.boardGamesData.map((element) => ({
                                label: element.bgName,
                                value: element.bgName,
                                gameId: element._id,
                              })),
                            ]}
                          />
                        </div>
                      )}

                      <div className="w-1/3 md-max:w-full">
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label={"Unit Price"}
                          placeholder={"price"}
                          disabled={true}
                          id="productQty"
                          type="Number"
                          //state={nameState}
                          value={unitPrice}
                        />
                      </div>

                      <div className="w-1/3 md-max:w-full">
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label={
                            productType === "FNB"
                              ? "Quantity"
                              : "Number of People"
                          }
                          placeholder={
                            productType === "FNB"
                              ? "Quantity"
                              : "Number of People"
                          }
                          id="productQty"
                          type="Number"
                          //state={nameState}
                          value={qty}
                          onChange={(e) => {
                            setQty(e.target.value);
                            setTotalPrice(unitPrice * e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-1/3 md-max:w-full">
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label={"Total Price"}
                          placeholder={"total"}
                          disabled={true}
                          id="productTotalPrice"
                          type="Number"
                          //state={nameState}
                          value={totalPrice}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <InputField
                  label="Service Description"
                  value={description}
                  variant="auth"
                  type={"text"}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
                {description !== "" && (
                  <>
                    <div className="mt-2 flex justify-between md:space-x-2 md-max:flex-wrap">
                      <div className="w-1/3 md-max:w-full">
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label={"Unit Price"}
                          placeholder={"price"}
                          disabled={false}
                          onChange={(e) => {
                            setUnitPrice(e.target.value);
                          }}
                          id="productQty"
                          type="Number"
                          //state={nameState}
                          value={unitPrice}
                        />
                      </div>

                      <div className="w-1/3 md-max:w-full">
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label={"Quantity"}
                          id="productQty"
                          type="Number"
                          //state={nameState}
                          value={qty}
                          onChange={(e) => {
                            setQty(e.target.value);
                            setTotalPrice(unitPrice * e.target.value);
                          }}
                        />
                      </div>
                      <div className="w-1/3 md-max:w-full">
                        <InputField
                          variant="auth"
                          extra="mb-3"
                          label={"Total Price"}
                          placeholder={"total"}
                          disabled={true}
                          id="productTotalPrice"
                          type="Number"
                          //state={nameState}
                          value={totalPrice}
                        />
                      </div>
                    </div>
                  </>
                )}
              </>
            ))}

          {qty !== 0 && (
            <button
              type={"submit"}
              className="mt-2 rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add
            </button>
          )}
        </form>
      </div>
      {productsArray.length !== 0 && (
        <DevelopmentTable
          columnsData={[
            {
              Header: "ACTION",
              accessor: "action",
            },
            {
              Header: "DESCRIPTION",
              accessor: "description",
            },
            {
              Header: "TYPE",
              accessor: "type",
            },
            {
              Header: "UNIT PRICE",
              accessor: "unitPrice",
            },
            {
              Header: "QUANTITY",
              accessor: "qty",
            },
            {
              Header: "TOTAL PRICE",
              accessor: "totalPrice",
            },
          ]}
          tableData={productsArray}
          tableTitle={"List"}
          editFunc={editFunc}
          deleteFunc={deleteFunc}
        />
      )}

      <div className="mt-3 rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
        {/* <SelectField
          label="Discount Type"
          value={selectedDiscount}
          onChange={(e) => {
            if (e.value === "") {
              setSelectedDiscountPrice(0);
            }
            setSelectedDiscount(e.value);
          }}
          options={[
            { label: "Choose", value: "" },
            ...data.discountData.map((elem) => ({
              label: elem.name,
              value: elem.name,
            })),
          ]}
        />
        {selectedDiscount && (
          <button
            type={"button"}
            onClick={() => {
              const discountedPrice = data.discountData.find(
                (elem) => elem.name === selectedDiscount
              );
              if (discountedPrice) {
                setSelectedDiscountPrice(discountedPrice.price);
              }
            }}
            className="mt-2 rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Apply
          </button>
        )} */}

        <form
          onSubmit={(e) => {
            handleDiscountAddition(e);
          }}
        >
          <>
            <SelectField
              label="Discount Description"
              value={discountDescription}
              selectedValue={[
                { label: "Choose", value: "" },
                ...data.discountData.map((elem) => ({
                  label: elem.name,
                  value: elem.name,
                })),
              ].find((elem) => elem.value === discountDescription)}
              onChange={(e) => {
                setDiscountDescription(e.value);
                const discountData = data.discountData.find(
                  (elem) => elem.name === e.value
                );

                if (discountData) {
                  setDiscountUnitPrice(discountData.price);
                }
              }}
              options={[
                { label: "Choose", value: "" },
                ...data.discountData.map((elem) => ({
                  label: elem.name,
                  value: elem.name,
                })),
              ]}
            />
            {discountDescription !== "" && (
              <>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Customer Mobile Number */}
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label={"Unit Price"}
                      placeholder={"price"}
                      disabled={true}
                      id="productQty"
                      type="Number"
                      //state={nameState}
                      value={discountUnitPrice}
                    />
                  </div>
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label={"Quantity"}
                      placeholder={"Quantity"}
                      id="productQty"
                      type="Number"
                      //state={nameState}
                      value={discountQty}
                      onChange={(e) => {
                        setDiscountQty(e.target.value);
                        setDiscountTotalPrice(
                          discountUnitPrice * e.target.value
                        );
                      }}
                    />
                  </div>
                  <div className="w-1/3 md-max:w-full">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label={"Total Price"}
                      placeholder={"total"}
                      disabled={true}
                      id="productTotalPrice"
                      type="Number"
                      //state={nameState}
                      value={discountTotalPrice}
                    />
                  </div>
                </div>
              </>
            )}
          </>

          {discountQty !== 0 && (
            <button
              type={"submit"}
              className="mt-2 rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add
            </button>
          )}
        </form>
      </div>

      {discountArray.length !== 0 && (
        <DevelopmentTable
          columnsData={[
            {
              Header: "ACTION",
              accessor: "action",
            },
            {
              Header: "DESCRIPTION",
              accessor: "description",
            },
            {
              Header: "UNIT PRICE",
              accessor: "unitPrice",
            },
            {
              Header: "QUANTITY",
              accessor: "qty",
            },
            {
              Header: "TOTAL PRICE",
              accessor: "totalPrice",
            },
          ]}
          tableData={discountArray}
          tableTitle={"Discounts"}
          editFunc={editDiscountFunc}
          deleteFunc={deleteDiscountFunc}
        />
      )}

      {productsArray.length !== 0 && (
        <div className="mt-3 rounded-primary bg-white p-4 font-medium text-navy-700 dark:border-navy-900 dark:bg-navy-800 dark:text-white">
          <div className="flex justify-between">
            <p className="text-base">Food and Bevrages Total Price </p>
            {fNbTotal}
          </div>
          <div className="flex justify-between">
            <p className="text-base">Gaming Total Price </p>+ {gamingTotal}
          </div>
          {userRole === "ADMIN" && (
            <div className="flex justify-between">
              <p className="text-base">Events Total Price </p>+ {eventsTotal}
            </div>
          )}
          {selectedDiscountPrice !== 0 && (
            <div className="flex justify-between">
              <p className="text-base">Discount ({selectedDiscount}) </p>
              {selectedDiscountPrice}{" "}
            </div>
          )}
          <div className="flex justify-between">
            <p className="text-base">Total </p>={" "}
            {intermidiateTotal + selectedDiscountPrice}{" "}
          </div>
        </div>
      )}

      <div className=" mt-3 rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
        {/* <img src={navlogo} height={100}/> */}
        {/* name */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // handleSubmit(e);
            setIsLoading(true);
            setSubmissionArray({
              //"customerId": "1234567890",
              customerName: customerName,
              customerMb: customerMb,
              customerAddress: customerAddress,
              gameHost: gameHost,
              gameHostId: gameHostId,
              modeOfTranscation: modeOfPayment,
              date: date,
              time: time,
              total: intermidiateTotal + selectedDiscountPrice,
              gamingTotal: gamingTotal,
              fNbTotal: fNbTotal,
              eventsTotal: eventsTotal,
              games: games,
              //"numberOfPeople": 4,
              discountName: selectedDiscount,
              discountAmount: selectedDiscountPrice,
              products: productsArray,
              discounts: discountArray,
            });
            setIsLoading(false);
          }}
        >
          <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
            {/* Customer Mobile Number */}
            <div className="w-1/3 md-max:w-full">
              <SelectField
                label={"GameHost Name"}
                selectedValue={[
                  { label: "Choose", value: "" },
                  ...data.allSubAdmin.map((elem) => ({
                    label: elem.name,
                    value: elem.name,
                  })),
                ].find((elem) => elem.value === gameHost)}
                onChange={(e) => {
                  setGameHost(e.value);
                  const sunAdminInfoInfo = data.allSubAdmin.find(
                    (elem) => elem.name === e.value
                  );
                  if (sunAdminInfoInfo) {
                    setGameHostId(sunAdminInfoInfo._id);
                  }
                }}
                options={[
                  { label: "Choose", value: "" },
                  ...data.allSubAdmin.map((elem) => ({
                    label: elem.name,
                    value: elem.name,
                  })),
                ]}
                isSearchable={false}
              />
            </div>
            <div className="w-1/3 md-max:w-full">
              <SelectField
                label={"Mode of Transcation"}
                // value={modeOfPayment}
                selectedValue={[
                  { value: "", label: "Choose" },
                  {
                    value: "CASH",
                    label: "CASH",
                  },
                  {
                    value: "UPI",
                    label: "UPI",
                  },
                ].find((elem) => elem.value === modeOfPayment)}
                onChange={(e) => {
                  setModeOfPayment(e.value);
                }}
                isSearchable={false}
                options={[
                  { value: "", label: "Choose" },
                  {
                    value: "CASH",
                    label: "CASH",
                  },
                  {
                    value: "UPI",
                    label: "UPI",
                  },
                ]}
              />
            </div>
            <div className="mt-2 w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label={`Date (${date})`}
                placeholder="Current Date"
                id="customerAddress"
                type="date"
                // value={time}
                onChange={(e) => {
                  handleDate(e.target.value);
                }}
              />
            </div>
            <div className="mt-2 w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Time"
                placeholder="Current Time"
                id="customerAddress"
                type="text"
                //state={priceState}
                value={time}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
              />
            </div>
          </div>
          {/* <InputField
            variant="auth"
            extra="mb-3"
            label="Date"
            placeholder="Current Date"
            id="customerAddress"
            type="text"
            //state={priceState}
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
            }}
          /> */}

          {isLoading ? (
            <button
              disabled
              className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              <Loading />
            </button>
          ) : (
            gameHost && (
              <button
                type={"submit"}
                className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Genrate Invoice
              </button>
            )
          )}
              <button
                onClick={()=>{handleReset()}}
                type={"button"}
                className="linear mt-2 rounded-xl ml-2 bg-gray-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover ml-2:bg-gray-700 active ml-2:bg-gray-800 dark ml-2:bg-gray-400 dark:text-white dark:hover ml-2:bg-gray-300 dark:active ml-2:bg-gray-200"
                >
                Reset
              </button>
          
        </form>
      </div>
      <button
        type="button"
        onClick={(e) => {
          onOpen();
        }}
        className="linear mt-4 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
      >
        <MdList />
      </button>
      {submissionArray.customerName && (
        <div>
          <SecondaryHeading title={"Preview"} />
          <PreviewInvoice data={submissionArray} />
          <div className="mt-4 flex justify-start rounded-primary bg-white p-4 dark:bg-navy-800">
            {isLoading ? (
              <button
                disabled
                className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                <Loading />
              </button>
            ) : (
              <div>
                <div>
                  <button
                    type={"button"}
                    onClick={(e) => {
                      handleAddToPendingList(e);
                    }}
                    className="linear mr-2 rounded-xl bg-yellow-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-yellow-700 active:bg-yellow-800 dark:bg-yellow-400 dark:text-white dark:hover:bg-yellow-300 dark:active:bg-yellow-200"
                  >
                    Add to Pending List
                  </button>
                  <button
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                    className="linear rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Publish Invoice
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AddInvoice;
