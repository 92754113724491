import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useEffect, useState } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SecondaryHeading from "components/heading/SecondaryHeading";
import InputFieldTextArea from "components/fields/TextField";
import MainTable2 from "components/table/Tablev2";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";

const TableReview = (props) => {
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setid] = React.useState("");

  const [review, setreview] = useState("");
  const [stars, setstars] = useState("");
  const [name, setname] = useState("");

  //errors for stars
  const [reviewMessage, setreviewMessage] = useState("");
  const [reviewState, setreviewState] = useState();

  const [starsMessage, setstarsMessage] = useState("");
  const [starsState, setstarsState] = useState();

  const [nameMessage, setnameMessage] = useState("");
  const [nameState, setnameState] = useState("");

   //refresher setup
   const dispatch = useDispatch();
   const refresh = useSelector((state) => state.refresher.value.refreshCount);

  //validation logic
  useEffect(() => {
    if (review === "") {
      setreviewMessage("review cannot be empty!");
      setreviewState();
    } else {
      setreviewMessage("");
      setreviewState("success");
    }

    if (stars === "") {
      setstarsMessage("");
      setstarsState();
    } else if (stars < 0 || stars > 5) {
      setstarsMessage("stars must be in range of 0-5!");
      setstarsState("error");
    } else {
      setstarsMessage("");
      setstarsState("success");
    }

    if (name === "") {
      setnameMessage("Name Cannot be Empty!");
      setnameState();
    } else if (name < 0) {
      setnameMessage("name must be a Positive Integer!");
      setnameState("error");
    } else {
      setnameMessage("");
      setnameState("success");
    }
  }, [review, stars, name]);

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/Review/" + id, {
        review: review,
        stars: stars,
        name: name,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'Review by "' + res.data.name + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("Review with this Name already exists!");
        }
      });
    setIsLoading2(false);
  };

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " Review ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/Review/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("Review is deleted Successfully!");
            dispatch(updateRefresher({ refreshCount: refresh + 1 }));
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    toast.info("Looking for Product in database");
    axiosInstance
      .get("/Review/" + id)
      .then((res) => {
        toast.success("Successfully Found!");
        const { _id, name, review,stars } = res.data;
        setname(name);
        setreview(review)
        setstars(stars)
        setid(_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <button onClick={()=>{setRefresh(refresh+1)}} className='linear mt-2 me-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'><MdRefresh/></button>  */}
        <div>
          <MainTable2
            url='/review/allByQuery'
            tableKeys={[ "name", 'stars','review']}
            refresh={refresh}
            editFunc={editFunc}
            deleteFunc={deleteFunc}
          />
        </div>
      
      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[12vh] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-lg border bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                {reviewMessage && (
                  <span className="text-red-400 ms-1">{reviewMessage}</span>
                )}
                <InputFieldTextArea
                  variant="auth"
                  extra="mb-3"
                  label="Review"
                  placeholder="review review"
                  id="review"
                  name="review"
                  state={reviewState}
                  value={review}
                  onChange={(e) => {
                    setreview(e.target.value);
                  }}
                />

                {/* stars */}

                {starsMessage && (
                  <span className="text-sm text-red-500 ms-1">
                    {starsMessage}
                  </span>
                )}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Stars"
                  placeholder="Number of stars"
                  id="stars"
                  name="Number"
                  state={starsState}
                  value={stars}
                  onChange={(e) => {
                    setstars(e.target.value);
                  }}
                />

                {/* name */}

                <div>
                  {nameMessage && (
                    <span className="text-sm text-red-500 ms-1">
                      {nameMessage}
                    </span>
                  )}
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Name"
                    placeholder="Name"
                    id="stars"
                    name="Number"
                    state={nameState}
                    value={name}
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                  />
                </div>

                {isLoading ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  reviewMessage === "" &&
                  starsMessage === "" &&
                  nameMessage === "" && (
                    <button
                      name={"submit"}
                      className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Save Changes
                    </button>
                  )
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableReview;
