import useAxiosInstance from "../../../axiosInstance";
import InputField from "components/fields/InputField";
import Loading from "components/loading";
import UserLayout from "layouts/user";
import React from "react";
import { toast } from "react-toastify";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import { HiX } from "react-icons/hi";
import SelectField from "components/fields/selectField";
import { useChannel } from "ably/react";

const Reservations = () => {
  const [formFields, setFormFields] = React.useState({
    noOfGuest: 0,
    timeDate: "",
    name: "",
    phoneNumber: "",
  });
  const [errorMessage, setErrorMessage] = React.useState({
    noOfGuest: "",
    timeDate: "",
    name: "",
    phoneNumber: "",
  });
  const [errorStatus, setErrorStatus] = React.useState({
    noOfGuest: "",
    timeDate: "",
    name: "",
    phoneNumber: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [data, setData] = React.useState([]);
  const onChange = (fieldName, value) => {
    setFormFields({
      ...formFields,
      [fieldName]: value,
    });
  };

  const { channel } = useChannel("Reservation-Admin-Page", (message) => {
    axiosInstance
      .get(`/reservation/events/all`)
      .then((res) => {
        setData(res.data);
        // setIsLoading2(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
        console.log(err)
        // setIsLoading2(false);
      });
  });
 
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [eventId, setEventId] = React.useState("");
  const axiosInstance = useAxiosInstance();
  const handleSubmit = () => {
    setIsLoading(true);
    axiosInstance
      .post("/reservation", {
        toName: "Admin",
        toEmail: "meepleton.bgc@gmail.com",
        name: formFields.name,
        email: formFields.phoneNumber,
        guests: formFields.noOfGuest,
        status: "PENDING",
        timeSlot: formFields.timeDate,
      }) // Replace with your API endpoint
      .then((res) => {
        if (res.data) {
        
          toast.success(
            `Table booking request has been successfully sent. Our team will soon contact you.`
          );
          setFormFields({
            noOfGuest: 0,
            timeDate: "",
            name: "",
            phoneNumber: "",
          });
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error adding book");
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    setIsLoading2(true);
    axiosInstance
      .get(`/reservation/events/all`)
      .then((res) => {
        setData(res.data);
        setIsLoading2(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
        console.log(err)
        setIsLoading2(false);
      });
  }, []);

  const railwayTimeSlots = [
    "00:00",
    "00:30",
    "01:00",
    "01:30",
    "02:00",
    "02:30",
    "03:00",
    "03:30",
    "04:00",
    "04:30",
    "05:00",
    "05:30",
    "06:00",
    "06:30",
    "07:00",
    "07:30",
    "08:00",
    "08:30",
    "09:00",
    "09:30",
    "10:00",
    "10:30",
    "11:00",
    "11:30",
    "12:00",
    "12:30",
    "13:00",
    "13:30",
    "14:00",
    "14:30",
    "15:00",
    "15:30",
    "16:00",
    "16:30",
    "17:00",
    "17:30",
    "18:00",
    "18:30",
    "19:00",
    "19:30",
    "20:00",
    "20:30",
    "21:00",
    "21:30",
    "22:00",
    "22:30",
    "23:00",
    "23:30",
  ];

  const slotMaker = (start, end) => {
    const startTime = new Date(start);
    const endTime = new Date(end);
    const timeInterval = 30; // 30 minutes

    const slots = [];
    let currentTime = startTime;

    while (currentTime < endTime) {
      slots.push(currentTime);
      currentTime = new Date(currentTime.getTime() + timeInterval * 60000); // Add 30 minutes in milliseconds
    }

    // Format the slots as strings in ISO 8601 format
    const formattedSlots = slots.map((slot) => {
      const day = slot.getDate().toString().padStart(2, "0");
      const month = (slot.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
      const year = slot.getFullYear().toString();
      const hours = slot.getHours().toString().padStart(2, "0");
      const minutes = slot.getMinutes().toString().padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    });

    return formattedSlots;
  };

  const formatDate = (givenDate) => {
    const gDate = new Date(givenDate);
    const day = gDate.getDate().toString().padStart(2, "0");
    const month = (gDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const year = gDate.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  return (
    <UserLayout>
      {isLoading2 ? (
        <>
          <div className="flex h-screen flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
              <ModalBody>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setIsLoading(true);
                    axiosInstance
                      .post("/reservation", {
                        toName: "Admin",
                        toEmail: "parlanijod@gmail.com",
                        name: formFields.name,
                        email: formFields.phoneNumber,
                        guests: formFields.noOfGuest,
                        status: "PENDING",
                        timeSlot: formFields.timeDate,
                        eventId: eventId,
                      }) // Replace with your API endpoint
                      .then((res) => {
                        if (res.data) {
                          toast.success(
                            `Table booking request has been successfully sent. Our team will soon contact you.`
                          );
                          setFormFields({
                            noOfGuest: 0,
                            timeDate: "",
                            name: "",
                            phoneNumber: "",
                          });
                          setEventId("");
                          onClose()
                        } else {
                          toast.error("Something went wrong!");
                        }
                        setIsLoading(false);
                      })
                      .catch((err) => {
                        console.error(err);
                        toast.error("Error adding book");
                        setIsLoading(false);
                      });
                  }}
                  className="relative rounded-xl bg-white p-5"
                >
                  <div className="mb-4 text-center text-base text-navy-700">
                    Booking for{" "}
                    {data.find((elem) => elem._id === eventId)?.title}
                  </div>
                  <div className="absolute right-2 top-2">
                    <HiX
                      className="cursor-pointer"
                      onClick={() => {
                        onClose();
                      }}
                    />
                  </div>
                  <div className="w-full ">
                    {errorMessage.noOfGuest && (
                      <span className="text-sm text-red-500">
                        {errorMessage.noOfGuest}
                      </span>
                    )}
                    <InputField
                      label={"Number of Guests"}
                      variant={"auth"}
                      placeholder={"Enter Number of Guests"}
                      type={"Number"}
                      onChange={(e) => {
                        onChange("noOfGuest", e.target.value);
                        if (e.target.value <= 0) {
                          setErrorMessage({
                            ...errorMessage,
                            noOfGuest: "Number of Guest should be more than 0",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            noOfGuest: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            noOfGuest: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            noOfGuest: "success",
                          });
                        }
                      }}
                      value={formFields.noOfGuest}
                      state={errorStatus.noOfGuest}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    {errorMessage.timeDate && (
                      <span className="text-sm text-red-500">
                        {errorMessage.timeDate}
                      </span>
                    )}
                    <SelectField
                      label={"Time and Slot"}
                      variant={"auth"}
                      options={
                        data.find((elem) => elem._id === eventId)?.end
                          ? [
                              ...slotMaker(
                                data.find((elem) => elem._id === eventId)
                                  ?.start,
                                data.find((elem) => elem._id === eventId)?.end
                              )?.map((elem) => ({
                                value: elem,
                                label: elem,
                              })),
                            ]
                          : [
                              ...railwayTimeSlots.map((elem) => ({
                                value: `${data.find((element) => element._id === eventId)?.start} ${elem}`,
                                label: `${data.find((element) => element._id === eventId)?.start} ${elem}`,
                              })),
                            ]
                      }
                      onChange={(e) => {
                        onChange("timeDate", e.value);
                        if (e.value === "") {
                          setErrorMessage({
                            ...errorMessage,
                            timeDate: "Date and Time cannot be Empty!",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            timeDate: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            timeDate: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            timeDate: "success",
                          });
                        }
                      }}
                      value={formFields.timeDate}
                      state={errorStatus.timeDate}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    {errorMessage.name && (
                      <span className="text-sm text-red-500">
                        {errorMessage.name}
                      </span>
                    )}
                    <InputField
                      label={"Name"}
                      placeholder={"Enter your Name"}
                      variant={"auth"}
                      type={"text"}
                      onChange={(e) => {
                        onChange("name", e.target.value);
                        if (e.target.value === "") {
                          setErrorMessage({
                            ...errorMessage,
                            name: "Name cannot be Empty!",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            name: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            name: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            name: "success",
                          });
                        }
                      }}
                      value={formFields.name}
                      state={errorStatus.name}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    {errorMessage.phoneNumber && (
                      <span className="text-sm text-red-500">
                        {errorMessage.phoneNumber}
                      </span>
                    )}
                    <InputField
                      label={"Phone Number"}
                      placeholder={"Enter your Phone Number"}
                      variant={"auth"}
                      type={"tel"}
                      onChange={(e) => {
                        onChange("phoneNumber", e.target.value);
                        if (e.target.value.length !== 10) {
                          setErrorMessage({
                            ...errorMessage,
                            phoneNumber:
                              "Phone Number should be exactly 10 digits",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            phoneNumber: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            phoneNumber: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            phoneNumber: "success",
                          });
                        }
                      }}
                      value={formFields.phoneNumber}
                      state={errorStatus.phoneNumber}
                    />
                  </div>
                  {isLoading ? (
                    <button
                      type="button"
                      disabled
                      className="mt-4 inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                    >
                      <Loading />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      // href="https://github.com/themesberg/landwind"
                      className="mt-4 inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                    >
                      Book
                    </button>
                  )}
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>
          <section className="bg-white py-8 dark:bg-gray-900 md:py-8">
            <div className="mx-auto grid max-w-screen-2xl px-4 pt-20 md-max:flex md-max:flex-col lg:grid-cols-12 lg:gap-8  xl:gap-0">
              <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="mb-4 max-w-2xl text-3xl font-extrabold leading-none tracking-tight  dark:text-white md:text-4xl xl:text-5xl">
                  Reserve Your Game Night Adventure
                </h1>
                <p className="mb-6 max-w-2xl font-light text-gray-123 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
                  Your next board game adventure is just a reservation away.
                  Booking your table at Meepleton Board Game Cafe is a breeze.
                  Provide us with some simple details, click "Book Table," and
                  you're all set to embark on an evening of fun and strategy.
                  Don't delay, secure your spot now for an unforgettable game
                  night with friends and family.
                </p>
              </div>
              <div className="md-max:mt-6 lg:col-span-5 lg:mt-0 ">
                <form className="rounded-xl bg-white p-5">
                  <div className="w-full ">
                    {errorMessage.noOfGuest && (
                      <span className="text-sm text-red-500">
                        {errorMessage.noOfGuest}
                      </span>
                    )}
                    <InputField
                      label={"Number of Guests"}
                      variant={"auth"}
                      placeholder={"Enter Number of Guests"}
                      type={"Number"}
                      onChange={(e) => {
                        onChange("noOfGuest", e.target.value);
                        if (e.target.value <= 0) {
                          setErrorMessage({
                            ...errorMessage,
                            noOfGuest: "Number of Guest should be more than 0",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            noOfGuest: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            noOfGuest: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            noOfGuest: "success",
                          });
                        }
                      }}
                      value={formFields.noOfGuest}
                      state={errorStatus.noOfGuest}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    {errorMessage.timeDate && (
                      <span className="text-sm text-red-500">
                        {errorMessage.timeDate}
                      </span>
                    )}
                    <InputField
                      label={"Date and Time"}
                      type="datetime-local"
                      variant={"auth"}
                      onChange={(e) => {
                        onChange("timeDate", e.target.value);
                        if (formFields.timeDate === "") {
                          setErrorMessage({
                            ...errorMessage,
                            timeDate: "Date and Time cannot be Empty!",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            timeDate: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            timeDate: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            timeDate: "success",
                          });
                        }
                      }}
                      value={formFields.timeDate}
                      state={errorStatus.timeDate}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    {errorMessage.name && (
                      <span className="text-sm text-red-500">
                        {errorMessage.name}
                      </span>
                    )}
                    <InputField
                      label={"Name"}
                      placeholder={"Enter your Name"}
                      variant={"auth"}
                      type={"text"}
                      onChange={(e) => {
                        onChange("name", e.target.value);
                        if (e.target.value === "") {
                          setErrorMessage({
                            ...errorMessage,
                            name: "Name cannot be Empty!",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            name: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            name: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            name: "success",
                          });
                        }
                      }}
                      value={formFields.name}
                      state={errorStatus.name}
                    />
                  </div>
                  <div className="mt-4 w-full">
                    {errorMessage.phoneNumber && (
                      <span className="text-sm text-red-500">
                        {errorMessage.phoneNumber}
                      </span>
                    )}
                    <InputField
                      label={"Phone Number"}
                      placeholder={"Enter your Phone Number"}
                      variant={"auth"}
                      type={"tel"}
                      onChange={(e) => {
                        onChange("phoneNumber", e.target.value);
                        if (e.target.value.length !== 10) {
                          setErrorMessage({
                            ...errorMessage,
                            phoneNumber:
                              "Phone Number should be exactly 10 digits",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            phoneNumber: "error",
                          });
                        } else {
                          setErrorMessage({
                            ...errorMessage,
                            phoneNumber: "",
                          });
                          setErrorStatus({
                            ...errorStatus,
                            phoneNumber: "success",
                          });
                        }
                      }}
                      value={formFields.phoneNumber}
                      state={errorStatus.phoneNumber}
                    />
                  </div>
                  {isLoading ? (
                    <button
                      type="button"
                      disabled
                      className="mt-4 inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                    >
                      <Loading />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                      // href="https://github.com/themesberg/landwind"
                      className="mt-4 inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                    >
                      Book table
                    </button>
                  )}
                </form>
              </div>
            </div>
          </section>
          <section className="bg-gray-50 dark:bg-gray-900">
            <div className="mx-auto max-w-screen-2xl px-4 py-8 lg:px-6 lg:pt-10 lg:pb-10">
              <h2 className="text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:mb-8 lg:text-3xl">
                Events
              </h2>
              <div className="mx-auto">
                {data.map((elem) => {
                  return (
                    <div className="dark:bg-neutral-700 mb-4  flex w-full space-x-3 rounded-xl border bg-white p-3">
                      <img
                        className="h-52 w-52 rounded-xl object-cover "
                        src={elem.imageSrc}
                        alt=""
                      />
                      <div className="w-full flex flex-col justify-between h-full">
                        <p className="text-neutral-500 dark:text-neutral-300 mb-1 text-xs">
                          {elem.end
                            ? formatDate(elem.end) == formatDate(elem.start)
                              ? `${formatDate(elem.start)}`
                              : `${formatDate(elem.start)} - ${formatDate(
                                  elem.end
                                )}`
                            : formatDate(elem.start)}
                        </p>
                        <h5 className="text-neutral-800 dark:text-neutral-50 mb-2 text-xl font-medium">
                          {elem.title}
                        </h5>
                        <p className="text-neutral-600 dark:text-neutral-200 mb-2 text-base">
                          {elem.desc}
                        </p>
                        <p className="text-neutral-500 dark:text-neutral-300 text-xs">
                          {elem.time}
                        </p>
                      <div className="">
                        <p className="text-neutral-600 dark:text-neutral-200 text-sm text-gray-800">
                          <div className="">
                          only {elem.guests} tickets left book now
                          </div>
                         </p>
                        <button
                          onClick={() => {
                            onOpen();
                            setEventId(elem._id);
                          }}
                          className="inline-flex w-full items-center justify-center rounded-lg  bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                        >
                          Book
                        </button>
                      </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
        </>
      )}
    </UserLayout>
  );
};

export default Reservations;
