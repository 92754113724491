import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useState } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SecondaryHeading from "components/heading/SecondaryHeading";
import MainTable2 from "components/table/Tablev2";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";

const TableBoardGame = (props) => {
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setid] = React.useState("");

  // States for board game information
  const [bgName, setBgName] = useState("");
  const [bgArtist, setBgArtist] = useState("");
  const [bgLongDescription, setBgLongDescription] = useState("");
  const [bgImage, setBgImage] = useState("");
  const [bgPlayers, setBgPlayers] = useState("");
  const [bgAge, setBgAge] = useState("");
  const [bgPlayingTime, setBgPlayingTime] = useState("");
  const [bgCategory, setBgCategory] = useState("");
  const [bgDesigner, setBgDesigner] = useState("");
  const [bgPublisher, setBgPublisher] = useState("");
  const [bgComplexity, setBgComplexity] = useState("");
  const [bgRating, setBgRating] = useState(0);
  const [bgType, setBgType] = useState("");
  const [bgMech, setBgMech] = useState("");
  const [bgPlayedBestAt, setBgPlayedBestAt] = useState("");
  const [bgLabels, setBgLabels] = useState("");
  const [bgOfficialLink, setBgOfficialLink] = useState("");
  const [bgAwards, setBgAwards] = useState("");
  const [bgBuy, setBgBuy] = useState("");
  const [bgVideo, setBgVideo] = useState("");
  const [bgWTP, setBgWTP] = useState(0);
  const [bgBGGRank, setBgBGGRank] = useState(0);
  const [bgBGGScore, setBgBGGScore] = useState(0);
  const [bgHomepage, setBgHomepage] = useState("off");
  const [bgSerialNumber, setBgSerialNumber] = useState(null);
  const [bgYear, setBgYear] = useState(0);

  // Validation error messages and states
  const [bgNameMessage, setBgNameMessage] = useState("");
  const [bgNameState, setBgNameState] = useState();

  const [bgArtistMessage, setBgArtistMessage] = useState("");
  const [bgArtistState, setBgArtistState] = useState();

  const [bgLongDescriptionMessage, setBgLongDescriptionMessage] = useState("");
  const [bgLongDescriptionState, setBgLongDescriptionState] = useState();

  const [bgImageMessage, setBgImageMessage] = useState("");
  const [bgImageState, setBgImageState] = useState();

  const [bgPlayersMessage, setBgPlayersMessage] = useState("");
  const [bgPlayersState, setBgPlayersState] = useState();

  const [bgAgeMessage, setBgAgeMessage] = useState("");
  const [bgAgeState, setBgAgeState] = useState();

  const [bgPlayingTimeMessage, setBgPlayingTimeMessage] = useState("");
  const [bgPlayingTimeState, setBgPlayingTimeState] = useState();

  const [bgCategoryMessage, setBgCategoryMessage] = useState("");
  const [bgCategoryState, setBgCategoryState] = useState();

  const [bgDesignerMessage, setBgDesignerMessage] = useState("");
  const [bgDesignerState, setBgDesignerState] = useState();

  const [bgPublisherMessage, setBgPublisherMessage] = useState("");
  const [bgPublisherState, setBgPublisherState] = useState();

  const [bgComplexityMessage, setBgComplexityMessage] = useState("");
  const [bgComplexityState, setBgComplexityState] = useState();

  const [bgRatingMessage, setBgRatingMessage] = useState("");
  const [bgRatingState, setBgRatingState] = useState();

  const [bgTypeMessage, setBgTypeMessage] = useState("");
  const [bgTypeState, setBgTypeState] = useState();

  const [bgMechMessage, setBgMechMessage] = useState("");
  const [bgMechState, setBgMechState] = useState();

  const [bgPlayedBestAtMessage, setBgPlayedBestAtMessage] = useState("");
  const [bgPlayedBestAtState, setBgPlayedBestAtState] = useState();

  const [bgLabelsMessage, setBgLabelsMessage] = useState("");
  const [bgLabelsState, setBgLabelsState] = useState();

  const [bgOfficialLinkMessage, setBgOfficialLinkMessage] = useState("");
  const [bgOfficialLinkState, setBgOfficialLinkState] = useState();

  const [bgAwardsMessage, setBgAwardsMessage] = useState("");
  const [bgAwardsState, setBgAwardsState] = useState();

  const [bgBuyMessage, setBgBuyMessage] = useState("");
  const [bgBuyState, setBgBuyState] = useState();

  const [bgVideoMessage, setBgVideoMessage] = useState("");
  const [bgVideoState, setBgVideoState] = useState();

  const [bgWTPMessage, setBgWTPMessage] = useState("");
  const [bgWTPState, setBgWTPState] = useState();

  const [bgBGGRankMessage, setBgBGGRankMessage] = useState("");
  const [bgBGGRankState, setBgBGGRankState] = useState();

  const [bgBGGScoreMessage, setBgBGGScoreMessage] = useState("");
  const [bgBGGScoreState, setBgBGGScoreState] = useState();

  const [bgHomepageMessage, setBgHomepageMessage] = useState("");
  const [bgHomepageState, setBgHomepageState] = useState();

  const [bgSerialNumberMessage, setBgSerialNumberMessage] = useState("");
  const [bgSerialNumberState, setBgSerialNumberState] = useState();

  const [bgYearMessage, setBgYearMessage] = useState("");
  const [bgYearState, setBgYearState] = useState();

  const dispatch = useDispatch();
  const refresh = useSelector(state=>state.refresher.value.refreshCount)

  //validation logic
  // React.useEffect(() => {
  //   if (name === "") {
  //     setnameMessage("Name cannot be empty!");
  //     setnameState();
  //   } else {
  //     setnameMessage("");
  //     setnameState("success");
  //   }

  //   if (price === "") {
  //     setpriceMessage("");
  //     setpriceState();
  //   } else if (price < 0) {
  //     setpriceMessage("Price must be a Positive Integer!");
  //     setpriceState("error");
  //   } else {
  //     setpriceMessage("");
  //     setpriceState("success");
  //   }

  //   if(qty === ""){
  //       setQtyMessage("");
  //       setQtyState();
  //     } else if (qty < 0) {
  //       setQtyMessage("Qty must be a Positive Integer!");
  //       setQtyState("error");
  //     } else {
  //       setQtyMessage("");
  //       setQtyState("success");

  //   }
  // }, [name, price,qty]);

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/boardgames/" + id, {
        bgName,
        bgArtist,
        bgLongDescription,
        bgImage,
        bgPlayers,
        bgAge,
        bgPlayingTime,
        bgCategory,
        bgDesigner,
        bgPublisher,
        bgComplexity,
        bgRating,
        bgType,
        bgMech,
        bgPlayedBestAt,
        bgLabels,
        bgOfficialLink,
        bgAwards,
        bgBuy,
        bgVideo,
        bgWTP,
        bgBGGRank,
        bgBGGScore,
        bgHomepage,
        bgSerialNumber,
        bgYear,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'board games "' + res.data.bgName + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({refreshCount:refresh+1}))
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("Board games with this Name already exists!");
        }
      });
    setIsLoading2(false);
  };

  const deleteFunc = (id) => {
    var confirm = window.confirm(
      "Do you want to delete #" + id + " board game ?"
    );
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/boardgames/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("Board games is deleted Successfully!");
            dispatch(updateRefresher({refreshCount:refresh+1}))
            // setData(data.filter((res) => res._id !== id));
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    toast.info('Looking for board game in database')
    axiosInstance.get('/board games/'+id).then(res=>{
    toast.success('Successfully Found it')
      const discountData = res.data;
    const {
      _id,
      bgName,
      bgArtist,
      bgLongDescription,
      bgImage,
      bgPlayers,
      bgAge,
      bgPlayingTime,
      bgCategory,
      bgDesigner,
      bgPublisher,
      bgComplexity,
      bgRating,
      bgType,
      bgMech,
      bgPlayedBestAt,
      bgLabels,
      bgOfficialLink,
      bgAwards,
      bgBuy,
      bgVideo,
      bgWTP,
      bgBGGRank,
      bgBGGScore,
      bgHomepage,
      bgSerialNumber,
      bgYear,
    } = discountData;
    setBgName(bgName);
    setBgArtist(bgArtist);
    setBgLongDescription(bgLongDescription);
    setBgImage(bgImage);
    setBgPlayers(bgPlayers);
    setBgAge(bgAge);
    setBgPlayingTime(bgPlayingTime);
    setBgCategory(bgCategory);
    setBgDesigner(bgDesigner);
    setBgPublisher(bgPublisher);
    setBgComplexity(bgComplexity);
    setBgRating(bgRating);
    setBgType(bgType);
    setBgMech(bgMech);
    setBgPlayedBestAt(bgPlayedBestAt);
    setBgLabels(bgLabels);
    setBgOfficialLink(bgOfficialLink);
    setBgAwards(bgAwards);
    setBgBuy(bgBuy);
    setBgVideo(bgVideo);
    setBgWTP(bgWTP);
    setBgBGGRank(bgBGGRank);
    setBgBGGScore(bgBGGScore);
    setBgHomepage(bgHomepage);
    setBgSerialNumber(bgSerialNumber);
    setBgYear(bgYear);
    setid(_id);
    }).catch(err=>{
      console.log(err)
    })
  };

  return (
    <>
      {/* <button onClick={()=>{setRefresh(refresh+1)}} className='linear mt-2 me-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'><MdRefresh/></button>  */}
      { (
        <div>
          <MainTable2
            url={'/boardgames/allbyQuery'}
            tableKeys={[
              // "_id",
              "bgName",
              "bgArtist",
              'bgAvailable',
              'bgTimesPlayed',
              //"bgLongDescription",
              //"bgImage",
              "bgPlayers",
              "bgAge",
              "bgPlayingTime",
              "bgCategory",
              "bgDesigner",
              "bgPublisher",
              "bgComplexity",
              "bgRating",
              "bgType",
              "bgMech",
              "bgPlayedBestAt",
              "bgLabels",
              //"bgOfficialLink",
              "bgAwards",
              //"bgBuy",
              //"bgVideo",
              "bgWTP",
              "bgBGGRank",
              "bgBGGScore",
              "bgHomepage",
              "bgSerialNumber",
              "bgYear",
            ]}
            editFunc={editFunc}
            deleteFunc={deleteFunc}
          />
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] overflow-y-scroll h-[80vh] !max-w-[85%] top-[25vh] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-lg border bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Board Game Name */}
                  <div className="w-1/3 md-max:w-full">
                    {/* {bgNameMessage && (
          <span className="text-red-400 ms-1">{bgNameMessage}</span>
        )} */}

                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Board Game Name"
                      placeholder="Enter Board Game Name"
                      id="bgName"
                      type="text"
                      //state={bgNameState}
                      value={bgName}
                      onChange={(e) => {
                        setBgName(e.target.value);
                      }}
                    />
                  </div>
                  {/* Board Game Artist */}
                  <div className="w-1/3 md-max:w-full">
                    {/* {bgArtistMessage && (
          <span className="text-red-400 ms-1">{bgArtistMessage}</span>
        )} */}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Board Game Artist"
                      placeholder="Enter Board Game Artist"
                      id="bgArtist"
                      type="text"
                      //state={bgArtistState}
                      value={bgArtist}
                      onChange={(e) => {
                        setBgArtist(e.target.value);
                      }}
                    />
                  </div>
                  {/* Board Game Long Description */}
                  <div className="w-1/3 md-max:w-full">
                    {/* {bgLongDescriptionMessage && (
          <span className="text-red-400 ms-1">{bgLongDescriptionMessage}</span>
        )} */}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Long Description"
                      placeholder="Enter Long Description"
                      id="bgLongDescription"
                      type="text"
                      //state={bgLongDescriptionState}
                      value={bgLongDescription}
                      onChange={(e) => {
                        setBgLongDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Board Game Image */}
                  <div className="w-1/3 md-max:w-full">
                    {bgImageMessage && (
                      <span className="text-red-400 ms-1">
                        {bgImageMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Image URL"
                      placeholder="Enter Image URL"
                      id="bgImage"
                      type="text"
                      state={bgImageState}
                      value={bgImage}
                      onChange={(e) => {
                        setBgImage(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Players */}
                  <div className="w-1/3 md-max:w-full">
                    {bgPlayersMessage && (
                      <span className="text-red-400 ms-1">
                        {bgPlayersMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Number of Players"
                      placeholder="Enter Number of Players"
                      id="bgPlayers"
                      type="text"
                      state={bgPlayersState}
                      value={bgPlayers}
                      onChange={(e) => {
                        setBgPlayers(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Age */}
                  <div className="w-1/3 md-max:w-full">
                    {bgAgeMessage && (
                      <span className="text-red-400 ms-1">{bgAgeMessage}</span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Recommended Age"
                      placeholder="Enter Recommended Age"
                      id="bgAge"
                      type="text"
                      state={bgAgeState}
                      value={bgAge}
                      onChange={(e) => {
                        setBgAge(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  <div className="w-1/3 md-max:w-full">
                    {bgPlayingTimeMessage && (
                      <span className="text-red-400 ms-1">
                        {bgPlayingTimeMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Playing Time"
                      placeholder="Enter Playing Time"
                      id="bgPlayingTime"
                      type="text"
                      state={bgPlayingTimeState}
                      value={bgPlayingTime}
                      onChange={(e) => {
                        setBgPlayingTime(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Category */}
                  <div className="w-1/3 md-max:w-full">
                    {bgCategoryMessage && (
                      <span className="text-red-400 ms-1">
                        {bgCategoryMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Category"
                      placeholder="Enter Category"
                      id="bgCategory"
                      type="text"
                      state={bgCategoryState}
                      value={bgCategory}
                      onChange={(e) => {
                        setBgCategory(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Designer */}
                  <div className="w-1/3 md-max:w-full">
                    {bgDesignerMessage && (
                      <span className="text-red-400 ms-1">
                        {bgDesignerMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Designer"
                      placeholder="Enter Designer"
                      id="bgDesigner"
                      type="text"
                      state={bgDesignerState}
                      value={bgDesigner}
                      onChange={(e) => {
                        setBgDesigner(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  {/* Board Game Publisher */}
                  <div className="w-1/3 md-max:w-full">
                    {bgPublisherMessage && (
                      <span className="text-red-400 ms-1">
                        {bgPublisherMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Publisher"
                      placeholder="Enter Publisher"
                      id="bgPublisher"
                      type="text"
                      state={bgPublisherState}
                      value={bgPublisher}
                      onChange={(e) => {
                        setBgPublisher(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Complexity */}
                  <div className="w-1/3 md-max:w-full">
                    {bgComplexityMessage && (
                      <span className="text-red-400 ms-1">
                        {bgComplexityMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Complexity"
                      placeholder="Enter Complexity"
                      id="bgComplexity"
                      type="text"
                      state={bgComplexityState}
                      value={bgComplexity}
                      onChange={(e) => {
                        setBgComplexity(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Rating */}
                  <div className="w-1/3 md-max:w-full">
                    {bgRatingMessage && (
                      <span className="text-red-400 ms-1">
                        {bgRatingMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Rating"
                      placeholder="Enter Rating"
                      id="bgRating"
                      type="number"
                      state={bgRatingState}
                      value={bgRating}
                      onChange={(e) => {
                        setBgRating(Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  <div className="w-1/3 md-max:w-full">
                    {bgTypeMessage && (
                      <span className="text-red-400 ms-1">{bgTypeMessage}</span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Type"
                      placeholder="Enter Type"
                      id="bgType"
                      type="text"
                      state={bgTypeState}
                      value={bgType}
                      onChange={(e) => {
                        setBgType(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Mechanism */}
                  <div className="w-1/3 md-max:w-full">
                    {bgMechMessage && (
                      <span className="text-red-400 ms-1">{bgMechMessage}</span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Mechanism"
                      placeholder="Enter Mechanism"
                      id="bgMech"
                      type="text"
                      state={bgMechState}
                      value={bgMech}
                      onChange={(e) => {
                        setBgMech(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Played Best At */}
                  <div className="w-1/3 md-max:w-full">
                    {bgPlayedBestAtMessage && (
                      <span className="text-red-400 ms-1">
                        {bgPlayedBestAtMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Played Best At"
                      placeholder="Enter Played Best At"
                      id="bgPlayedBestAt"
                      type="text"
                      state={bgPlayedBestAtState}
                      value={bgPlayedBestAt}
                      onChange={(e) => {
                        setBgPlayedBestAt(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  <div className="w-1/3 md-max:w-full">
                    {bgLabelsMessage && (
                      <span className="text-red-400 ms-1">
                        {bgLabelsMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Labels"
                      placeholder="Enter Labels"
                      id="bgLabels"
                      type="text"
                      state={bgLabelsState}
                      value={bgLabels}
                      onChange={(e) => {
                        setBgLabels(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Official Link */}
                  <div className="w-1/3 md-max:w-full">
                    {bgOfficialLinkMessage && (
                      <span className="text-red-400 ms-1">
                        {bgOfficialLinkMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Official Link"
                      placeholder="Enter Official Link"
                      id="bgOfficialLink"
                      type="text"
                      state={bgOfficialLinkState}
                      value={bgOfficialLink}
                      onChange={(e) => {
                        setBgOfficialLink(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Awards */}
                  <div className="w-1/3 md-max:w-full">
                    {bgAwardsMessage && (
                      <span className="text-red-400 ms-1">
                        {bgAwardsMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Awards"
                      placeholder="Enter Awards"
                      id="bgAwards"
                      type="text"
                      state={bgAwardsState}
                      value={bgAwards}
                      onChange={(e) => {
                        setBgAwards(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  <div className="w-1/3 md-max:w-full">
                    {bgBuyMessage && (
                      <span className="text-red-400 ms-1">{bgBuyMessage}</span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Buy"
                      placeholder="Enter Buy Link"
                      id="bgBuy"
                      type="text"
                      state={bgBuyState}
                      value={bgBuy}
                      onChange={(e) => {
                        setBgBuy(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game Video */}
                  <div className="w-1/3 md-max:w-full">
                    {bgVideoMessage && (
                      <span className="text-red-400 ms-1">
                        {bgVideoMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Video Link"
                      placeholder="Enter Video Link"
                      id="bgVideo"
                      type="text"
                      state={bgVideoState}
                      value={bgVideo}
                      onChange={(e) => {
                        setBgVideo(e.target.value);
                      }}
                    />
                  </div>

                  {/* Board Game WTP */}
                  <div className="w-1/3 md-max:w-full">
                    {bgWTPMessage && (
                      <span className="text-red-400 ms-1">{bgWTPMessage}</span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="WTP (What to play)"
                      placeholder="Enter WTP"
                      id="bgWTP"
                      type="number"
                      state={bgWTPState}
                      value={bgWTP}
                      onChange={(e) => {
                        setBgWTP(Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
                <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
                  <div className="w-1/3 md-max:w-full">
                    {bgBGGRankMessage && (
                      <span className="text-red-400 ms-1">
                        {bgBGGRankMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="BGG Rank"
                      placeholder="Enter BGG Rank"
                      id="bgBGGRank"
                      type="number"
                      state={bgBGGRankState}
                      value={bgBGGRank}
                      onChange={(e) => {
                        setBgBGGRank(Number(e.target.value));
                      }}
                    />
                  </div>

                  {/* Board Game BGG Score */}
                  <div className="w-1/3 md-max:w-full">
                    {bgBGGScoreMessage && (
                      <span className="text-red-400 ms-1">
                        {bgBGGScoreMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="BGG Score"
                      placeholder="Enter BGG Score"
                      id="bgBGGScore"
                      type="number"
                      state={bgBGGScoreState}
                      value={bgBGGScore}
                      onChange={(e) => {
                        setBgBGGScore(Number(e.target.value));
                      }}
                    />
                  </div>

                  {/* Board Game Homepage */}
                  <div className="w-1/3 md-max:w-full">
                    {bgHomepageMessage && (
                      <span className="text-red-400 ms-1">
                        {bgHomepageMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Homepage"
                      placeholder="Enter Homepage"
                      id="bgHomepage"
                      type="text"
                      state={bgHomepageState}
                      value={bgHomepage}
                      onChange={(e) => {
                        setBgHomepage(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex  md:space-x-2 md-max:flex-wrap">
                  <div className="w-1/3 md-max:w-full">
                    {bgSerialNumberMessage && (
                      <span className="text-red-400 ms-1">
                        {bgSerialNumberMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Serial Number"
                      placeholder="Enter Serial Number"
                      id="bgSerialNumber"
                      type="number"
                      state={bgSerialNumberState}
                      value={bgSerialNumber}
                      onChange={(e) => {
                        setBgSerialNumber(Number(e.target.value));
                      }}
                    />
                  </div>

                  {/* Board Game Year */}
                  <div className="w-1/3 md-max:w-full">
                    {bgYearMessage && (
                      <span className="text-red-400 ms-1">{bgYearMessage}</span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Year"
                      placeholder="Enter Year"
                      id="bgYear"
                      type="number"
                      state={bgYearState}
                      value={bgYear}
                      onChange={(e) => {
                        setBgYear(Number(e.target.value));
                      }}
                    />
                  </div>
                </div>
                {bgImage && (
                  <div className="flex justify-center">
                    <img className="h-80 rounded-xl" src={bgImage} />
                  </div>
                )}
                {/* Submit Button */}

                {isLoading ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="linear mt-2  me-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Save Board Game
                  </button>
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableBoardGame;
