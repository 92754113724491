import React from "react";
import logo from "../../../../assets/img/layout/logoIco.png";
import DevelopmentTable from "views/admin/tables/components/DevelopmentTable";
import { MdCall, MdMail } from "react-icons/md";
import InvoiceTable from "components/table/invoiceTable";
const PreviewInvoice = (props) => {
  const {
    customerId,
    customerName,
    customerMb,
    customerAddress,
    gameHost,
    modeOfTranscation,
    date,
    time,
    gamingTotal,
    eventsTotal,
    total,
    _id,
    fNbTotal,
    discountAmount,
    discountName,
    products,
    discounts,
    games,
  } = props.data;
  return (
    <>
      <div className="flex justify-center">
        <div className="container mt-3 rounded-primary bg-white p-10 dark:border-navy-900 dark:bg-navy-800 md-max:text-base">
        <div className="flex justify-between">
            <div className="flex  flex-wrap">
              <img src={logo} className="mr-5 h-36" />
              <div>
                <div className="text-2xl font-extrabold">
                  Meepleton Board Game Cafe
                </div>
                <div className="mt-1 text-base ">
                  DK-2/232, Danish Kunj Kolar Road
                </div>
                <div className="mt-1 text-base ">
                  Bhopal, Madhya Pradesh, 462042
                </div>
                <div className="mt-1 flex items-center text-base">
                  <MdCall className="mr-2" /> +91 8989208432
                </div>
                <div className="mt-1 flex items-center text-base">
                  <MdMail className="mr-2" /> meepleton.bgc@gmail.com
                </div>
                {/* <div className='text-2xl font-extrabold'>Meepleton Board Game Cafe</div> */}
              </div>
            </div>

            <div className="text-sm font-bold text-right">
              <div className="text-right text-xl font-semibold text-teal-400">
                INVOICE
              </div>
              ORIGINAL FOR RECIPIENT
            </div>
          </div>

          <div className="mt-5 flex w-full flex-wrap  justify-between">
            <div className="mb-1">
              <div className="text-base font-semibold">Bill To:</div>
              <div className="text-lg font-semibold">{customerName}</div>
              <div className="text-base">Ph: {customerMb}</div>
            </div>
            <div>
              <div className="w-full md:w-[500px]">
                <div className="flex w-full flex-col md:flex-row justify-between">
                  <div className="text-base font-semibold ">Invoice#</div>
                  <div className="text-left md:text-right">{_id || 448654872}</div>
                </div>

                <div className="flex w-full flex-col md:flex-row justify-between">
                  <div className="text-base font-semibold  ">Invoice Date</div>
                  <div className="text-left md:text-right">{date}</div>
                </div>

                <div className="flex w-full flex-col md:flex-row justify-between">
                  <div className="text-base font-semibold ">Invoice Time</div>
                  <div className="text-left md:text-right">{time}</div>
                </div>
              </div>
            </div>
          </div>

          {products.filter((elem) => elem.type === "FNB").length > 0 && (
            <div className="m-[-15px] mt-4">
              <InvoiceTable
                columnsData={[
                  {
                    Header: "DESCRIPTION",
                    accessor: "description",
                  },
                  {
                    Header: "UNIT PRICE",
                    accessor: "unitPrice",
                  },
                  {
                    Header: "QUANTITY",
                    accessor: "qty",
                  },
                  {
                    Header: "TOTAL PRICE",
                    accessor: "totalPrice",
                  },
                ]}
                tableData={products.filter((elem) => elem.type === "FNB")}
                tableTitle={"Food and Beverages"}
                titleColor={"text-brand-500"}
              />
              <div className="mr-4  text-right text-navy-900 dark:text-white">
                <span className="font-medium">Subtotal : </span>
                <span className="font-semibold">Rs {fNbTotal.toLocaleString()}</span>
              </div>
            </div>
          )}

          {products.filter((elem) => elem.type === "GAMING").length > 0 && (
            <div className=" m-[-15px] mt-4">
              <InvoiceTable
                columnsData={[
                  {
                    Header: "DESCRIPTION",
                    accessor: "description",
                  },
                  {
                    Header: "UNIT PRICE",
                    accessor: "unitPrice",
                  },
                  {
                    Header: "QUANTITY",
                    accessor: "qty",
                  },
                  {
                    Header: "TOTAL PRICE",
                    accessor: "totalPrice",
                  },
                ]}
                tableData={products.filter((elem) => elem.type === "GAMING")}
                tableTitle={"Gaming"}
                titleColor={"text-brand-500"}
              />
              <div className="mr-4  text-right text-navy-900 dark:text-white">
                <span className="font-medium">Subtotal : </span>
                <span className="font-semibold">Rs {gamingTotal.toLocaleString()}</span>
              </div>
            </div>
          )}

          {products.filter((elem) => elem.type === "EVENTS").length > 0 && (
            <div className=" m-[-15px] mt-4">
              <InvoiceTable
                columnsData={[
                  {
                    Header: "DESCRIPTION",
                    accessor: "description",
                  },
                  {
                    Header: "UNIT PRICE",
                    accessor: "unitPrice",
                  },
                  {
                    Header: "QUANTITY",
                    accessor: "qty",
                  },
                  {
                    Header: "TOTAL PRICE",
                    accessor: "totalPrice",
                  },
                ]}
                tableData={products.filter((elem) => elem.type === "EVENTS")}
                tableTitle={"Events"}
                titleColor={"text-brand-500"}
              />
              <div className="mr-4  text-right text-navy-900 dark:text-white">
                <span className="font-medium">Subtotal : </span>
                <span className="font-semibold">Rs {eventsTotal.toLocaleString()}</span>
              </div>
            </div>
          )}

          {discounts.length > 0 && (
            <div className=" m-[-15px] mt-4">
              <InvoiceTable
                columnsData={[
                  {
                    Header: "DESCRIPTION",
                    accessor: "description",
                  },
                  {
                    Header: "UNIT PRICE",
                    accessor: "unitPrice",
                  },
                  {
                    Header: "QUANTITY",
                    accessor: "qty",
                  },
                  {
                    Header: "TOTAL PRICE",
                    accessor: "totalPrice",
                  },
                ]}
                tableData={discounts}
                tableTitle={"Adjustments"}
                titleColor={"text-brand-500"}
              />
              <div className="mr-4  text-right text-navy-900 dark:text-white">
                <span className="font-medium">Subtotal : </span>
                <span className="font-semibold">
                  - Rs {Math.abs(discountAmount)}
                </span>
              </div>
            </div>
          )}

          <div className="mt-[31px] flex flex-wrap justify-between border-t text-navy-900 dark:border-navy-900 dark:text-white md-max:text-sm">
            <div className=" mt-4 font-semibold">Notes :</div>
            <div className="mt-4  mr-2 text-right md-max:text-sm ">
              {games.length != 0 && (
                <div>
                  <span className="font-medium">Games : </span>
                  <span className="font-semibold">
                    {games.map((elem) => elem.name).join(", ")}
                  </span>
                </div>
              )}
              {/* <span className='font-medium'>Adjustments  border-t mt-2 dark:border-navy-900 pt-2 ({discountName}) : </span><span className='font-semibold'>- Rs {Math.abs(discountAmount).toLocaleString()}</span> */}
              <div className="text-2xl  font-bold text-brand-500 md-max:text-xl">
                Rs {total.toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreviewInvoice;

//<div className='flex justify-center'>
//<div className=' mt-3 rounded-primary bg-white container p-5 md-max:text-base dark:border-navy-900 dark:bg-navy-800'>
//    <div className='flex justify-center' >
//        <img src={logo} className='h-20 p-2'/>
//    </div>
//    <div className='text-6xl mt-4 text-brand-500 font-semibold'>
//        Invoice
//    </div>
//    <div className='mt-5 flex justify-between flex-wrap '>
//        <div >
//        <span className='text-xl text-navy-800 font-semibold  dark:text-white'>Invoice For:</span><br/>
//            <div className='text-navy-700 font-medium dark:text-gray-500 '>
//            {customerName}<br/>
//            {customerMb}<br/>
//            {customerAddress}
//            </div>
//        </div>
//        <div >
//            <span className='text-xl text-navy-800 font-semibold dark:text-white'>Date:</span><br/>
//            <div className='text-navy-700 font-medium dark:text-gray-500'>
//            {date}<br/>
//        </div>
//        <span className='text-xl text-navy-800 font-semibold dark:text-white'>Time:</span><br/>
//            <div className='text-navy-700 font-medium dark:text-gray-500'>
//            {time}<br/>
//            </div>
//
//        </div>
//
//        <div className=''>
//        <span className='text-xl text-navy-800  font-semibold dark:text-white'>Invoice Number:</span><br/>
//            <div className='text-navy-700 font-medium dark:text-gray-500'>
//           {_id || '#154254252'}<br/>
//            </div>
//            <span className='text-xl text-navy-800 font-semibold dark:text-white'>Payable to:</span><br/>
//            <div className='text-navy-700 font-medium dark:text-gray-500'>
//            Meepleton Board Game Café<br/>
//            </div>
//
//        </div>
//    </div>
//
//    {products.filter(elem=>elem.type==='FNB').length>0&&<div className='m-[-15px] mt-4'>
//    <DevelopmentTable columnsData={[
//        {
//          Header: "DESCRIPTION",
//          accessor: "description",
//        },
//        {
//          Header: "UNIT PRICE",
//          accessor: "unitPrice",
//        },
//        {
//          Header: "QUANTITY",
//          accessor: "qty",
//        },
//        {
//          Header: "TOTAL PRICE",
//          accessor: "totalPrice",
//        },
//      ]}
//      tableData={products.filter(elem=>elem.type==='FNB')}
//      tableTitle={"Food and Beverages"}
//      titleColor={'text-brand-500'}
//      />
//      <div className='text-right  mr-4 text-navy-900 dark:text-white'><span className='font-medium'>Subtotal : </span><span className='font-semibold'>Rs {fNbTotal.toLocaleString()}</span></div>
//     </div>}
//
//    {products.filter(elem=>elem.type==='GAMING').length>0&& <div className=' m-[-15px] mt-4'>
//    <DevelopmentTable columnsData={[
//        {
//          Header: "DESCRIPTION",
//          accessor: "description",
//        },
//        {
//          Header: "UNIT PRICE",
//          accessor: "unitPrice",
//        },
//        {
//          Header: "QUANTITY",
//          accessor: "qty",
//        },
//        {
//          Header: "TOTAL PRICE",
//          accessor: "totalPrice",
//        },
//      ]}
//      tableData={products.filter(elem=>elem.type==='GAMING')}
//      tableTitle={"Gaming"}
//      titleColor={'text-brand-500'}
//      />
//      <div className='text-right  mr-4 text-navy-900 dark:text-white'><span className='font-medium'>Subtotal : </span><span className='font-semibold'>Rs {gamingTotal.toLocaleString()}</span></div>
//     </div>}
//
//     {products.filter(elem=>elem.type==='EVENTS').length>0&& <div className=' m-[-15px] mt-4'>
//    <DevelopmentTable columnsData={[
//        {
//          Header: "DESCRIPTION",
//          accessor: "description",
//        },
//        {
//          Header: "UNIT PRICE",
//          accessor: "unitPrice",
//        },
//        {
//          Header: "QUANTITY",
//          accessor: "qty",
//        },
//        {
//          Header: "TOTAL PRICE",
//          accessor: "totalPrice",
//        },
//      ]}
//      tableData={products.filter(elem=>elem.type==='EVENTS')}
//      tableTitle={"Events"}
//      titleColor={'text-brand-500'}
//      />
//      <div className='text-right  mr-4 text-navy-900 dark:text-white'><span className='font-medium'>Subtotal : </span><span className='font-semibold'>Rs {eventsTotal.toLocaleString()}</span></div>
//     </div>}
//
//     {discounts.length>0&& <div className=' m-[-15px] mt-4'>
//    <DevelopmentTable columnsData={[
//        {
//          Header: "DESCRIPTION",
//          accessor: "description",
//        },
//        {
//          Header: "UNIT PRICE",
//          accessor: "unitPrice",
//        },
//        {
//          Header: "QUANTITY",
//          accessor: "qty",
//        },
//        {
//          Header: "TOTAL PRICE",
//          accessor: "totalPrice",
//        },
//      ]}
//      tableData={discounts}
//      tableTitle={"Adjustments"}
//      titleColor={'text-brand-500'}
//      />
//      <div className='text-right  mr-4 text-navy-900 dark:text-white'><span className='font-medium'>Subtotal : </span><span className='font-semibold'>- Rs {Math.abs(discountAmount).toLocaleString()}</span></div>
//     </div>}
//
//    <div className="mt-[31px] text-navy-900 border-t dark:border-navy-900 md-max:text-sm flex justify-between flex-wrap dark:text-white">
//        <div className=' mt-4 font-semibold'>
//            Notes :
//        </div>
//        <div className='text-right  mt-4 mr-2 md-max:text-sm '>
//            {games.length!=0 && <div><span className='font-medium'>Games : </span><span className='font-semibold'>{games.map(elem=>elem.name).join(",").toLocaleString()}</span></div>}
//            {/* <span className='font-medium'>Adjustments  border-t mt-2 dark:border-navy-900 pt-2 ({discountName}) : </span><span className='font-semibold'>- Rs {Math.abs(discountAmount).toLocaleString()}</span> */}
//            <div className='font-bold  text-2xl text-brand-500 md-max:text-xl'>Rs {total}</div>
//        </div>
//    </div>
//</div>
//</div>
