import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import InputFieldTextArea from "components/fields/TextField";
import SelectField from "components/fields/selectField";
import { updateRefresher } from "features/refresher";

const AddLibrary = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const refresh = useSelector(state=>state.refresher.value.refreshCount)
  const data = props.depandancies;
  //Customer Details Information
  const [customerName, setCustomerName] = useState("");
  const [customerMb, setCustomerMb] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");

  //Product Information
  const [products,setProducts] = useState([]);

  //Mode of Transcation
  const [modeOfTranscation, setModeOfTranscation] = useState("");
  const [checkOutDate, setCheckOutDate] = useState("");
  const [date, setDate] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [rentPerDayAmount, setRentPerDayAmount] = useState(0);

  //GameHost and GameHost ID
  const [gameHost, setGameHost] = useState("");
  const [gameHostId, setGameHostId] = useState("");

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    if (customerMb.length === 10) {
      toast.info("Searching in Database for user");
      axiosInstance
        .post("/library/getCustomerDetails", { customerMb: customerMb })
        .then((res) => {
          toast.success("User found");
          setCustomerMb(res.data.customerMb);
          setCustomerName(res.data.customerName);
          setCustomerAddress(res.data.customerAddress);
        })
        .catch((err) => {
          toast.error(err.response.data.errors.msg);
        });
    }
  }, [customerMb]);

  useEffect(() => {
    const today = new Date();

    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to month because it's zero-based
    const day = today.getDate().toString().padStart(2, "0");

    const formattedDate = `${month}/${day}/${year}`;
    setDate(formattedDate);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post("/library", {
        customerName: customerName,
        customerMb: customerMb,
        customerAddress: customerAddress,
        gameHost: gameHost,
        gameHostId: gameHostId,
        modeOfTranscation: modeOfTranscation,
        checkOutDate: date,
        depositAmount: depositAmount,
        rentPerDayAmount :modeOfTranscation==="EMPLOYEEBENFITS" ? 0 : Number(rentPerDayAmount),
        products:products,
        status: "CHECKOUT",
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            `Library Entry for ${res.data.customerName} is created Successfully`
          );
          dispatch(updateRefresher({refreshCount:refresh+1}))
        } else {
          toast.error("Something went wrong!");
        }
        handleReset()
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };

  const handleReset =()=>{
    // customerName: customerName,
    // customerMb: customerMb,
    // customerAddress: customerAddress,
    // gameHost: gameHost,
    // gameHostId: gameHostId,
    // modeOfTranscation: modeOfTranscation,
    // checkOutDate: date,
    // depositAmount: depositAmount,
    // rentPerDayAmount :modeOfTranscation==="EMPLOYEEBENFITS" ? 0 : Number(rentPerDayAmount),
    // products:products,
    setCustomerName("")
    setCustomerMb("")
    setCustomerAddress("")
    setGameHost("")
    setGameHostId("")
    setModeOfTranscation("")
    setRentPerDayAmount("")
    setDepositAmount("")
    setProducts([])
  }

  return (
    <>
      <div className=" rounded-primary  bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
        {/* <img src={navlogo} height={100}/> */}
        {/* name */}
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
            {/* Customer Mobile Number */}
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Mb"
                placeholder="Enter Customer Mobile Number"
                id="customerMb"
                type="text"
                value={customerMb}
                onChange={(e) => setCustomerMb(e.target.value)}
              />
            </div>

            {/* Customer Name */}
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Name"
                placeholder="Enter Customer Name"
                id="customerName"
                type="text"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>

            {/* Customer Address */}
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Address"
                placeholder="Enter Customer Address"
                id="customerAddress"
                type="text"
                value={customerAddress}
                onChange={(e) => setCustomerAddress(e.target.value)}
              />
            </div>
          </div>

          <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
            {/* Customer Name */}
            <div className="w-1/3 md-max:w-full">
              <SelectField
                label="Rent/Day Amount"
                selectedValue={[
                  { label: "Choose", value: "" },
                  ...data?.rentsAmountData?.map((elem) => ({
                    label: elem.name,
                    value: elem.price,
                  })),
                ].find(elem=>elem.value==rentPerDayAmount)}
                onChange={(e) => {
                  setRentPerDayAmount(e.value);
                }}
                options={[
                  { label: "Choose", value: "" },
                  ...data?.rentsAmountData?.map((elem) => ({
                    label: elem.name,
                    value: elem.price,
                  })),
                ]}
              />
            </div>
            <div className="w-1/3 md-max:w-full">
              <SelectField
                variant="auth"
                label="Mode of Transaction"
                placeholder="Enter Mode of Transaction"
                id="modeofTransaction"
                type="text"
                selectedValue={[ 
                  {
                  label: "Choose",
                  value: "",
                  },
                  {
                    label: "UPI",
                    value: "UPI",
                  },
                  {
                    label: "CASH",
                    value: "CASH",
                  },
                  {
                    label: "FIRST BOOK",
                    value: "FIRSTBOOK",
                  },
                  {
                    label: "EMPLOYEE BENFITS",
                    value: "EMPLOYEEBENFITS",
                  },
                ].find(elem=>elem.value==modeOfTranscation)}
                options={
                  [ 
                  {
                  label: "Choose",
                  value: "",
                  },
                  {
                    label: "UPI",
                    value: "UPI",
                  },
                  {
                    label: "CASH",
                    value: "CASH",
                  },
                  {
                    label: "FIRST BOOK",
                    value: "FIRSTBOOK",
                  },
                  {
                    label: "EMPLOYEE BENFITS",
                    value: "EMPLOYEEBENFITS",
                  },
                ]}
                value={modeOfTranscation}
                onChange={(e) => setModeOfTranscation(e.value)}
              />
            </div>
            {/* Customer Mobile Number */}
            <div className="mt-2 w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Deposit Amount"
                placeholder="Enter Deposit Amount"
                id="depositAmount"
                type="Number"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
              />
            </div>
            {/* Customer Address */}
            <div className="w-1/3 md-max:w-full">
              <SelectField
                label={"Host Name"}
                value={gameHost}
                selectedValue={[
                  { label: "Choose", value: "", _id: "" },
                  ...data.subAdminData.map((elem) => ({
                    label: elem.name,
                    value: elem.name,
                    _id: elem._id,
                  })),
                ].find(elem=>elem.value===gameHost)}
                onChange={(e) => {
                  setGameHost(e.value);
                  setGameHostId(e._id);
                }}
                options={[
                  { label: "Choose", value: "", _id: "" },
                  ...data.subAdminData.map((elem) => ({
                    label: elem.name,
                    value: elem.name,
                    _id: elem._id,
                  })),
                ]}
                isSearchable={false}
              />
            </div>
          </div>
          {/* <SelectField
                          label={"Games"}
                          // value={games}
                          onChange={(e) => {
                            setGames([...e.map((elem) => ({
                              name : elem.value,
                              gameId : elem.gameId
                            }))]);
                          }}
                          isMulti={true}
                          options={[
                            ...data.boardGamesData.map((element) => ({
                              label: element.bgName,
                              value: element.bgName,
                              gameId : element._id
                            })),
                          ]}
                        /> */}
              <SelectField
                label="Products"
                onChange={(e) => {
                  setProducts([...e.map((elem) => ({
                    name : elem.value,
                    productId : elem.productId,
                    type: elem.type
                  }))]);
                }}
                
                isMulti={true}
                options={[
                  ...data.productsData.map((elem) => ({
                    label: elem.name,
                    value:elem.name,
                    productId: elem.productId,
                    type:elem.type
                  })),
                ]}
              />
          {customerName &&
            gameHost &&
            gameHostId &&
            date &&
            depositAmount &&
            modeOfTranscation &&
             (
              isLoading?
              <button 
              disabled
              className="rounded-full bg-teal-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200">
                <Loading/>
              </button>
              :
              <button className="rounded-full bg-teal-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200">
                Add
              </button>
            )}
        </form>
      </div>
    </>
  );
};

export default AddLibrary;
