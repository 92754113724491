import React, { useState } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import UserLayout from "layouts/user";
import { toast } from "react-toastify";
import { Pool, Header, FAQ } from "./components";
import { Helmet } from "react-helmet";

const Pools = () => {
  const [data, setData] = React.useState({});
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [contentData, setContentData] = useState([]);
  const [faQData, setFaQ] = useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/pool/allCatalog?limit=${limit}&page=${page}`)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data.filtedData);
        setContentData(res.data.contentData);
        setFaQ(res.data.faqData);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading(false);
      });
  }, [limit, page]);

  React.useEffect(() => {
    setIsLoading2(true);
    axiosInstance
      .get(`/pool/allCatalog?limit=${limit}&page=${page}`)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data.filtedData);
        setContentData(res.data.contentData);
        setFaQ(res.data.faqData);
        setIsLoading2(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading2(false);
      });
  }, []);

  return (
    <UserLayout>
      <Helmet>
                    <title>Pools - Meepleton board game cafe</title>
                    <meta name="description" content='Meepleton is game cafe in bhopal which has best pool settings.'/>
                </Helmet>
        {isLoading2?<>
              <div className="flex h-screen flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <Loading />
                </div>
              </div>
          </>:<>
        
          
      <Header data={contentData} />

      <div className="flex bg-gray-50 w-full justify-center  md-max:px-5">
        <div className="container mt-5 md:mt-8">
          <div className="mb-4"></div>
          {isLoading ? (
            <>
              <div className="flex h-screen flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <Loading />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {data?.docs?.map((elem) => {
                  return <Pool data={elem} />;
                })}
              </div>
            </>
          )}
          <div className="my-8 md:my-12 flex flex-col items-center">
                <div className="flex text-white">
                  {data?.hasPrevPage && (
                    <div
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      className="mr-1 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gray-400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-chevron-left h-6 w-6"
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </div>
                  )}
                  <div className="flex h-12 rounded-full bg-white border text-gray-800 font-medium">
                    {data.prevPage !== null && (
                      <div
                        onClick={() => {
                          setPage(data.prevPage);
                        }}
                        className="hidden w-12 cursor-pointer items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
                      >
                        {data.prevPage}
                      </div>
                    )}
                    <div className="hidden w-12 cursor-pointer items-center justify-center  rounded-full leading-5 bg-gray-50 border transition  duration-150 ease-in md:flex ">
                      {data.page}
                    </div>
                    {data.nextPage !== null && (
                      <div
                        onClick={() => {
                          setPage(data.nextPage);
                        }}
                        className="hidden w-12 cursor-pointer  items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
                      >
                        {data.nextPage}
                      </div>
                    )}
                  </div>
                  {data.hasNextPage && (
                    <div
                      onClick={() => {
                        setPage(page + 1);
                      }}
                      className="ml-1 mr-4 flex h-12 w-12 cursor-pointer border items-center justify-center rounded-full bg-white text-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-chevron-right h-6 w-6"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>
                  )}
                  <select
                    value={limit}
                    className="rounded-xl bg-white p-1 hover:outline-none border text-gray-800 active:outline-none"
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={data.totalDocs}>All</option>
                  </select>
                </div>
              </div>
        </div>
      </div>
      <FAQ data={faQData} />
      </>}
    </UserLayout>
  );
};

export default Pools;
