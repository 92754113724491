import React, { useState } from "react";
import PrimaryHeading from "components/heading/PrimaryHeading";
import AddLibrary from "./components/addLibrary";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";
import TableLibrary from "./components/tableLibrary";
import SecondaryHeading from "components/heading/SecondaryHeading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import PreviewLibraryInvoice from "./components/previewLibraryInvoice";
import SelectField from "components/fields/selectField";
import InputField from "components/fields/InputField";
import TableWrapperLibrary from "./components/tableWrapper";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";

const Library = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch()
  const refresh = useSelector(state=>state.refresher.value.refreshCount)
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(true);
  const [previewData, setPreviewData] = React.useState({});
  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("/library/getDependancies")
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went Wrong!");
      });
  }, []);

  const deleteFunc = (id) => {
    var confirm = window.confirm(
      "Do you want to delete #" + id + " Library Entry ?"
    );
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/library/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("Library Entry is deleted Successfully!");
            dispatch(updateRefresher({refreshCount:refresh+1}))
            //setData(data.invoiceData.filter((res) => res._id !== id));
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (libraryEntry) => {
      toast.success('Preview Rental invoice has been presented. Scroll down.')
      console.log(libraryEntry)  
      setPreviewData(libraryEntry)
  }

   //Customer Details Information
   const [customerName, setCustomerName] = useState("");
   const [customerMb, setCustomerMb] = useState("");
   const [customerAddress, setCustomerAddress] = useState("");
 
   //Book Information
   const [bookName, setBookName] = useState("");
   const [bookId, setBookId] = useState("");
 
   //Mode of Transcation
   const [modeOfTranscation, setModeOfTranscation] = useState("");
   const [checkOutDate, setCheckOutDate] = useState("");
   const [date, setDate] = useState("");
   const [depositAmount, setDepositAmount] = useState("");
 
   //GameHost and GameHost ID
   const [gameHost, setGameHost] = useState("");
   const [gameHostId, setGameHostId] = useState("");
 
  const editForm = (val) =>{
    
    onOpen()
  }

  const handleSubmit = (e) =>{
    var confirm = window.confirm(
        "Do you want to publish this invoice for " + previewData.customerName + " ?"
      );
      if (confirm) {
          setIsLoading(true)
          axiosInstance.patch('/library/'+previewData._id,{
            ...previewData,
            status:'RETURNED'
          }).then(res=>{
            if(res.data){
              toast.success('Library Entry is updated Successfully!')
            }
            setIsLoading(false)
          }).catch(err=>{
            console.log(err)
            setIsLoading(false)
            toast.error('Something Went Wrong!')
          })
        //console.log(previewData);
        //setPreviewData(previewData)
      }
  }

  if (isLoading === true) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <PrimaryHeading title={"Add Entry"} />
      {data.length != 0 && <AddLibrary depandancies={data} />}
      <SecondaryHeading title={"Entries"} />
      {data.length != 0 && (
        <TableWrapperLibrary
          url={"/library/allbyQuery"}
          editFunc={editFunc}
          deleteFunc={deleteFunc}
          editForm={editForm}
          tableKeys={[
            "checkOutDate",
            "returnDate",
            "customerName",
            "customerMb",
            "customerAddress",
            "gameHost",
            "modeOfTranscation",
            "KeptDays",
            "depositAmount",
            "rentAmount",
            "rentPerDayAmount",
            "returnAmount",
            "products",
            "status",
          ]}
        />
      )}
      {previewData.returnAmount&&<div>
        <SecondaryHeading title={"Preview"} />
        <PreviewLibraryInvoice data={previewData} />
        <div className="mt-4 flex justify-start rounded-primary bg-white p-4 dark:bg-navy-800">
            {isLoading ? (
              <button
                disabled
                className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                <Loading />
              </button>
            ) : (
              <button
                onClick={(e) => {
                  handleSubmit(e);
                }}
                className="linear rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Publish Invoice
              </button>
            )}
          </div>
      </div>}
      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
    <ModalOverlay className="bg-[#000] !opacity-30" />
    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
      <ModalBody>
      <div className=" bg-white p-4 rounded-primary dark:border-navy-900 dark:bg-navy-800 ">
        {/* <img src={navlogo} height={100}/> */}
        {/* name */}

        <form onSubmit={(e)=>{}}>

        <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
            {/* Customer Mobile Number */}
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Mb"
                placeholder="Enter Customer Mobile Number"
                id="customerMb"
                type="text"
                value={customerMb}
                onChange={(e) => setCustomerMb(e.target.value)}
              />
            </div>

            {/* Customer Name */}
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Name"
                placeholder="Enter Customer Name"
                id="customerName"
                type="text"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </div>
           
            {/* Customer Address */}
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Customer Address"
                placeholder="Enter Customer Address"
                id="customerAddress"
                type="text"
                value={customerAddress}
                onChange={(e) => setCustomerAddress(e.target.value)}
              />
            </div>
          </div>

          {/* <SelectField
            label="Book Name"
            value={bookName}
            onChange={(e) => {
              if (e.value === "") {
                setBookName("");
                setBookId("");
              }
              setBookName(e.value);
              setBookId(e._id);
            }}
            options={[
              { label: "Choose", value: "" ,_id:""},
              ...data?.bookData?.map((elem) => ({
                label: elem.bookName,
                value: elem.bookName,
                _id: elem._id,
              })),
            ]}
          /> */}

          <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
            {/* Customer Name */}
            <div className="w-1/3 md-max:w-full">
              <SelectField
                variant="auth"
                extra="mb-3"
                label="Mode of Transaction"
                placeholder="Enter Mode of Transaction"
                id="modeofTransaction"
                type="text"
                options={[
                  {
                    label: "UPI",
                    value: "UPI",
                  },
                  {
                    label: "CASH",
                    value: "CASH",
                  },
                  {
                    label: "FIRST BOOK",
                    value: "FIRSTBOOK",
                  },
                  {
                    label: "EMPLOYEE BENFITS",
                    value: "EMPLOYEEBENFITS",
                  },
                ]}
                value={modeOfTranscation}
                onChange={(e) => setModeOfTranscation(e.value)}
              />
            </div>
            {/* Customer Mobile Number */}
            <div className="w-1/3 md-max:w-full">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Deposit Amount"
                placeholder="Enter Deposit Amount"
                id="depositAmount"
                type="Number"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
              />
            </div>
            {/* Customer Address */}
            <div className="w-1/3 md-max:w-full">
              <SelectField
                label={"Host Name"}
                value={gameHost}
                onChange={(e) => {
                  setGameHost(e.value);
                  setGameHostId(e._id);
                }}
                options={[
                  { label: "Choose", value: "",_id:"" },
                  ...data.subAdminData.map((elem) => ({
                    label: elem.name,
                    value: elem.name,
                    _id:elem._id
                  })),
                ]}
                isSearchable={false}
              />
            </div>
          </div>     


        {isLoading?<button disabled className="linear text-center mt-2 px-4 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          <Loading/>          
        </button>:<button
        type={'submit'} 
        className="linear mt-2 me-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Save Changes
        </button>
        
        }
        <button
              type='button'
              onClick={onClose}
              className="linear mt-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Close
            </button>
        </form>
      </div>
      </ModalBody>
    </ModalContent>
  </Modal>
    </div>
  );
};

export default Library;
