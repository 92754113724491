import React, { useRef } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../../axiosInstance";
import { useState, useEffect } from "react";
import Footer from "components/footer/Footer";
import { useReactToPrint } from "react-to-print";
import QRCode from "react-qr-code";
import { MdPrint, MdShare } from "react-icons/md";
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from "react-share";
import PreviewLibraryInvoice from "./previewLibraryInvoice";

const DisplayLibraryInvoice = () => {
  const [data, setData] = useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  
  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("/library/" + window.location.href.split("/").pop())
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div className="flex h-full w-full">
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          ref={componentRef}
          className={`mx-auto h-full w-full flex-none  transition-all md:pr-2`}
        >
          {/* Routes */}
          <div className="h-full w-full">
            <div className="pt-5s mx-auto mb-auto  h-full min-h-[84vh] p-2 md:pr-2">
              <div>{data._id && <PreviewLibraryInvoice data={data} />}</div>
            </div>
          </div>
          <div className="flex justify-center">
            <div className="container mt-4 flex justify-between rounded-primary bg-white p-4 dark:bg-navy-800">
              {/* <div
                  style={{
                    height: "auto",
                    margin: "0 auto",
                    maxWidth: 64,
                    width: "100%",
                  }}
                >
                  {data._id&&<QRCode
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={data._id}
                    viewBox={`0 0 256 256`}
                  />}  
                  
                  </div>*/}
              <div
                style={{
                  maxWidth: 64,
                }}
              >
                {data._id && (
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={`https://meepleton.in/libraryInvoice/`+data._id}
                    viewBox={`0 0 256 256`}
                  />
                )}
              </div>
              <div className="justify-center space-x-1 flex align-middle">
              <WhatsappShareButton
                  url={'https://meepleton.in'}
                  title={`Hello ${data.customerName},\n\nPlease find your Invoice here:\n${"https://meepleton.netlify.app/libraryInvoice/" + data._id}\n\nHope you had a memorable time at Meepleton! If you did, do leave us a review on Google using the link below.\n\nHave a nice day!\n\nReview - https://g.page/r/CVKei2R0NgvCEBM/review\n\nThanks,\nMeepleton Board Game Cafe\n8989208432`}
                  separator=","  
                 >
                  <WhatsappIcon size={54} round />
                </WhatsappShareButton>
                <EmailShareButton
                  url={window.location.href}
                  title={'Meepleton Invoice '}
                  separator=":: "
                 >
                  <EmailIcon size={54} round />
                </EmailShareButton>
                
                <FacebookShareButton
                  url={window.location.href}
                  title={'Meepleton Invoice via Facebook'}
                  separator=":: "
                 >
                  <FacebookIcon size={54} round />
                </FacebookShareButton>
                   
                   
                <button
                  onClick={(e) => {
                    handlePrint();
                  }}
                  className="linear h-14 rounded-full w-14 bg-brand-600 text-lg flex justify-center items-center font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  <MdPrint />
                </button>
              </div>
            </div>
          </div>
          <div className="p-3">
            <Footer />
          </div>
        </main>
      </div>
    </div>
  );
};

export default DisplayLibraryInvoice;
