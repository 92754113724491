import React from "react";
import { useChannel } from "ably/react";
import UserLayout from "layouts/user";
import {
  MdBookmark,
  MdInfo,
  MdInfoOutline,
  MdReply,
  MdSearch,
  MdSend,
  MdStar,
} from "react-icons/md";
import useAxiosInstance from "../../../../axiosInstance";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import UserLoginPage from "./Login";
import { HiX } from "react-icons/hi";
import { GiAlarmClock, GiCrown, GiMeeple, GiSolarTime } from "react-icons/gi";
import { BiSolidUserCircle, BiSolidUserPlus, BiUser } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";

const GlobalChatBox = () => {
  const userData = useSelector((state) => state.users.value);
  const [conversationId, setConversationId] = React.useState(
    "653a0cfccd014000b0abaf9d"
  );
  const [messages, updateMessages] = React.useState([]);
  const containerRef = React.useRef(null);
  const [perticularConversationData, setPerticularConversationData] =
    React.useState({});

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  //ably
  const { channel } = useChannel(conversationId, (message) => {
    console.log(message);
    updateMessages((prev) => [...prev, message]);
  });
  //Party Page updates

  const axiosInstance = useAxiosInstance();
  const userId = useSelector((state) => state.users.value.id);
  const userName = useSelector((state) => state.users.value.username);
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openInfoBar, setInfoBar] = React.useState(false);

  const [filter, setFilter] = React.useState("");
  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/conversation/` + conversationId)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setPerticularConversationData(res.data);
        updateMessages(res.data.messageData);
        setIsLoading(false);
        // console.log(res.data)
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went Wrong!");
      });
  }, [conversationId]);

  React.useEffect(() => {
    if (!userData.isAuthenticated) {
      onOpen();
    } else {
      onClose();
    }
  }, [userData.isAuthenticated]);

  const handleSend = (e) => {
    e.preventDefault();
    const data = {
      message: message,
      senderId: userId,
      senderName: userName,
      //messageId: message.length + 1,
      conversationId: conversationId,
      type: "NORMAL",
      time: new Date(),
    };
    channel.publish("test-message", data);
    setMessage("");
    axiosInstance
      .post("/message", data) // Replace with your API endpoint
      .then((res) => {
        if (res.data.message) {
          // toast.success(res.data.message +' is saved')
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error saving message to database!");
      });
  };
  //reply states and functions

  const [replyToMessage, setReplyToMessage] = React.useState("");
  const [replyToName, setRepltToName] = React.useState("");

  const handleReply = (e) => {
    e.preventDefault();
    const data = {
      message: message,
      senderId: userId,
      senderName: userName,
      replyToMessage: replyToMessage,
      replyToName: replyToName,
      conversationId: conversationId,
      type: "REPLYTO",
      time: new Date(),
    };
    channel.publish("test-message", data);
    setRepltToName("");
    setReplyToMessage("");
    setMessage("");
    axiosInstance
      .post("/message", data) // Replace with your API endpoint
      .then((res) => {
        if (res.data.message) {
          // toast.success(res.data.message +' is saved')
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error saving message to database!");
      });
  };

  const formatDate = (givenDate) => {
    const gDate = new Date(givenDate);
    const day = gDate.getDate().toString().padStart(2, "0");
    const month = (gDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const year = gDate.getFullYear().toString();
    const time = `${gDate.getHours().toString().padStart(2, "0")}:${gDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    return `${day}/${month}/${year} ${time}`;
  };

  return (
      <div className="flex w-full justify-center bg-white pb-3 md-max:px-5">
        <div className="container flex w-full rounded-xl border bg-white">
          <div className="relative w-full rounded-t-xl rounded-b-xl bg-gray-50">
            <div className="mb-2 flex items-center justify-between rounded-t-xl  bg-teal-400 px-3 py-2 text-xl font-medium text-white">
              {perticularConversationData.title}{" "}
            </div>
            <>
              <div
                ref={containerRef}
                className="flex h-[60vh] flex-col overflow-y-auto p-2"
              >
                {messages.map((msg, index) => {
                  return msg.data.type === "NOTIFICATION" ? (
                    <div className="flex w-full justify-center">
                      <div
                        className={
                          "mb-2 min-w-min max-w-[60%] rounded-xl border bg-white p-1 "
                        }
                        key={index}
                      >
                        <div className=" text-sm text-gray-500 ">
                          {msg.data.message}
                        </div>
                      </div>
                    </div>
                  ) : msg.data.type === "REPLYTO" ? (
                    <>
                      <div
                        className={
                          msg.data.senderId == userId
                            ? "flex w-full justify-end pr-2"
                            : "flex w-full justify-start pl-2"
                        }
                      >
                        <div
                          className={
                            msg.data.senderId == userId
                              ? "mb-2 min-w-min max-w-[60%] rounded-l-xl rounded-br-xl border bg-white p-2 px-2"
                              : "mb-2 min-w-min max-w-[60%] rounded-r-xl rounded-bl-xl border bg-white p-2 px-2"
                          }
                          key={index}
                        >
                          <div className="mb-2 rounded-xl border bg-white p-2">
                            <div className={"text-sm text-gray-500"}>
                              {msg.data.replyToName}
                            </div>
                            <div className="text-sm text-gray-800 md:text-base lg:text-base">
                              {msg.data.replyToMessage}
                            </div>
                          </div>
                          <div className="relative">
                            {msg.data.senderId != userId && (
                              <MdReply
                                onClick={() => {
                                  setRepltToName(msg.data.senderName);
                                  setReplyToMessage(msg.data.message);
                                }}
                                className="float-right ml-2 cursor-pointer text-lg"
                              />
                            )}
                            <div>
                              <div className={"text-sm text-gray-500"}>
                                {msg.data.senderId != userId &&
                                  msg.data.senderName}
                              </div>
                              <div className="text-sm text-gray-800 md:text-base lg:text-base">
                                {msg.data.message}
                              </div>
                              <div className="float-right text-[9px]">
                                {formatDate(msg.data.time)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className={
                        msg.data.senderId == userId
                          ? "flex w-full justify-end pr-2"
                          : "flex w-full justify-start pl-2"
                      }
                    >
                      <div
                        className={
                          msg.data.senderId == userId
                            ? "mb-2 min-w-min max-w-[60%] rounded-l-xl rounded-br-xl border bg-white p-2 px-2"
                            : "mb-2 min-w-min max-w-[60%] rounded-r-xl rounded-bl-xl border bg-white p-2 px-2"
                        }
                        key={index}
                      >
                        <div className="relative">
                          {msg.data.senderId != userId && (
                            <MdReply
                              onClick={() => {
                                setRepltToName(msg.data.senderName);
                                setReplyToMessage(msg.data.message);
                              }}
                              className="float-right ml-2 cursor-pointer text-lg"
                            />
                          )}
                          <div>
                            <div className={"text-sm text-gray-500"}>
                              {msg.data.senderId != userId &&
                                msg.data.senderName}
                            </div>
                            <div className="text-sm text-gray-800 md:text-base lg:text-base">
                              {msg.data.message}
                            </div>
                            <div className="float-right text-[9px]">
                              {formatDate(msg.data.time)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="h-15 flex flex-col items-center justify-center p-2">
                {replyToName && (
                  <div>
                    <div
                      className={"relative mb-1 rounded-xl border bg-white p-2"}
                    >
                      <div className="float-right">
                        <HiX
                          className="cursor-pointer"
                          onClick={() => {
                            setRepltToName("");
                            setReplyToMessage("");
                          }}
                        />
                      </div>
                      <div className={"text-sm text-gray-500"}>
                        {replyToName}
                      </div>
                      <div className="text-sm text-gray-800 md:text-base lg:text-base">
                        {replyToMessage}
                      </div>
                    </div>
                  </div>
                )}
                <form
                  onSubmit={(e) => {
                    replyToName ? handleReply(e) : handleSend(e);
                  }}
                  className="rounded-xl border bg-white flex px-5 shadow-sm"
                >
                  <input
                    required
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    className="h-16 w-full md:w-[350px] lg:w-[450px]  rounded-l-xl border-0 focus:outline-none active:border-0 active:outline-none "
                  />
                  <button
                    type="submit"
                    className="rounded-r-xl font-poppins text-base font-medium  text-teal-400 transition duration-200 hover:text-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200 "
                  >
                    <MdSend />
                  </button>
                </form>
              </div>
            </>
          </div>
        </div>
      </div>
  );
};

export default GlobalChatBox;