import React, { useRef } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";
import HeroSection from "./components/herosection";
import Services from "./components/Services";
import TopGaming from "./components/TopGaming";
import TopBook from "./components/TopBooks";
import Testimonials from "./components/Testimonials";
import UserLayout from "layouts/user";
import EventCalendar from "./components/calender";
import FAQ from "./components/faQ";
import Gallery from "./components/Gallery";
import { Link } from "react-router-dom";
import navImage from "../../../assets/img/layout/navlogo.png";
import { FaUserFriends } from "react-icons/fa";
import {
  MdArrowCircleRight,
  MdCall,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { Helmet } from "react-helmet";

const BoardGame = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("/dashboard/home")
      .then((res) => {
        setData(res.data);
        //toast.success('Data loaded Successfully!')
        console.log(res.data);

        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading(false);
      });
  }, []);

  if (isLoading === true) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <UserLayout>
        <HeroSection />
        <Services />
        {data.topBoardgames && <TopGaming data={data?.topBoardgames} />}
        {data.topBooks && <TopBook data={data?.topBooks} />}
        {data.testimonials && <Testimonials data={data?.testimonials} />}
        <EventCalendar/>
        <Gallery/>
        <FAQ data={data.faqData}/>
      </UserLayout> */}
      <UserLayout>
      <Helmet>
                <meta
                  name="description"
                  content="Welcome to the world of Board games, Books and Pool table. Meepleton is a board game cafe which has best board games and best books to read . Visit Us now ."
                />
                <title>Meepleton Board Game Café</title>
                </Helmet>
        <div>
          {/* Start block */}
          <section className="bg-white py-8 md:py-8 dark:bg-gray-900">
            <div className="mx-auto grid max-w-screen-2xl pt-20 md-max:flex md-max:flex-col px-4 lg:grid-cols-12 lg:gap-8  xl:gap-0">
              <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="mb-4 max-w-2xl text-3xl tracking-tight font-extrabold leading-none  dark:text-white md:text-4xl xl:text-5xl">
                  Welcome to Meepleton!
                </h1>
                <p className="max-w-2xl mb-6 font-light text-gray-123 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
                  Meepleton is Bhopal's only board game cafe and one of the best
                  cafes in Bhopal. Our Cafe provides a fun and family-friendly
                  environment for people to get together and play modern Board
                  games.
                </p>
                <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                  <Link
                    to={'/parties'}
                    className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                  >
                    {" "}
                    <FaUserFriends className="me-2" />
                    Create a party
                  </Link>
                  <a
                    href="tel:+91 8989095732"
                    className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 px-5 py-3 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800 sm:w-auto"
                  >
                    {" "}
                    <MdCall className="me-2" />
                    Call us
                  </a>
                </div>
              </div>
              <div className="md-max:mt-6 lg:col-span-5 lg:mt-0 lg:flex">
                <img
                  className=""
                  src="https://res.cloudinary.com/defonzszt/image/upload/v1695415681/ufsujlb3kihrwrkqsemb.png"
                  alt="hero image"
                />
              </div>
            </div>
          </section>
          {/* End block */}
          
          {/* Start block */}
          <section className="bg-white dark:bg-gray-900">
            <div className="mx-auto max-w-screen-2xl px-4 py-8 lg:py-24 lg:px-6">
            <h2 className="mb-6 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:mb-8 lg:text-3xl">
                Gallery
              </h2>
              <div className="mx-auto">
              {data.gallery &&<Gallery data={data.gallery}/>}
              </div>
            </div>
          </section>
          {/*End Block*/}

          <section className="bg-gray-50 dark:bg-gray-900">
            <div className="mx-auto max-w-screen-2xl px-4 py-8 lg:pt-10 lg:pb-24 lg:px-6">
            <h2 className="text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:mb-8 lg:text-3xl">
                Events
              </h2>
              <div className="mx-auto">
              {data.event&&<EventCalendar data={data.event}/>}
              </div>
            </div>
          </section>

          <section className="bg-white dark:bg-gray-900">
            <div className="mx-auto max-w-screen-2xl items-center px-4 py-8 lg:grid lg:grid-cols-4 lg:gap-16 lg:py-24 lg:px-6 xl:gap-24">
              <div className="col-span-2 mb-8">
                <p className="text-lg font-medium text-teal-600 dark:text-teal-500">
                  Welcome to Our Board Game Cafe
                </p>
                <h2 className="mt-3 mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white md:text-3xl">
                  Where Fun and Community Meet
                </h2>
                <p className="font-light text-gray-123 dark:text-gray-400 sm:text-xl">
                  Join our vibrant board game community where you can enjoy
                  snooker, savor delicious food and beverages, dive into
                  captivating books, and have endless hours of fun with over 100
                  board games.
                </p>
                <div className="mt-6 space-y-4 border-t border-gray-200 pt-6 dark:border-gray-700">
                  <div>
                    <Link
                      to={'/gallery'}
                      className="inline-flex items-center text-base font-medium text-teal-600 hover:text-teal-800 dark:text-teal-500 dark:hover:text-teal-700"
                    >
                      Explore Our Gallery
                      <svg
                        className="ml-1 h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={'/foodAndBeverages'}
                      className="inline-flex items-center text-base font-medium text-teal-600 hover:text-teal-800 dark:text-teal-500 dark:hover:text-teal-700"
                    >
                      Explore Our Menu
                      <svg
                        className="ml-1 h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </Link>
                  </div>
                  {/* <div>
                    <a
                      href="#"
                      className="inline-flex items-center text-base font-medium text-teal-600 hover:text-teal-800 dark:text-teal-500 dark:hover:text-teal-700"
                    >
                      Read Our Reviews
                      <svg
                        className="ml-1 h-5 w-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div> */}
                </div>
              </div>
              <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
                <div>
                  <svg
                    className="mb-2 h-10 w-10 text-teal-600 dark:text-teal-500 md:h-12 md:w-12"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 5a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm14 1a1 1 0 11-2 0 1 1 0 012 0zM2 13a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2zm14 1a1 1 0 11-2 0 1 1 0 012 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <h3 className="mb-2 text-2xl font-bold dark:text-white">
                    100+ Board Games
                  </h3>
                  <p className="font-light text-gray-123 dark:text-gray-400">
                    Explore a diverse collection of board games
                  </p>
                </div>
                <div>
                  <svg
                    className="mb-2 h-10 w-10 text-teal-600 dark:text-teal-500 md:h-12 md:w-12"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
                  </svg>
                  <h3 className="mb-2 text-2xl font-bold dark:text-white">
                    150+ Books
                  </h3>
                  <p className="font-light text-gray-123 dark:text-gray-400">
                    Get lost in captivating stories from our book collection
                  </p>
                </div>
                <div>
                  <svg
                    className="mb-2 h-10 w-10 text-teal-600 dark:text-teal-500 md:h-12 md:w-12"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zM4.332 8.027a6.012 6.012 0 011.912-2.706C6.512 5.73 6.974 6 7.5 6A1.5 1.5 0 019 7.5V8a2 2 0 004 0 2 2 0 011.523-1.943A5.977 5.977 0 0116 10c0 .34-.028 .675-.083 1H15a2 2 0 00-2 2v2.197A5.973 5.973 0 0110 16v-2a2 2 0 00-2-2 2 2 0 01-2-2 2 2 0 00-1.668-1.973z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <h3 className="mb-2 text-2xl font-bold dark:text-white">
                    50+ Food Items
                  </h3>
                  <p className="font-light text-gray-123 dark:text-gray-400">
                    Enjoy a diverse menu with over 50 delectable dishes and
                    drinks
                  </p>
                </div>
                <div>
                  <svg
                    className="mb-2 h-10 w-10 text-teal-600 dark:text-teal-500 md:h-12 md:w-12"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                  </svg>
                  <h3 className="mb-2 text-2xl font-bold dark:text-white">
                    50+ Reviews
                  </h3>
                  <p className="font-light text-gray-123 dark:text-gray-400">
                    Discover what our satisfied customers have to say
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Start block */}
          <section className="bg-gray-50 dark:bg-gray-800">
            <div className="mx-auto max-w-screen-2xl space-y-12 px-4 pb-8 lg:space-y-20 lg:py-24 lg:px-6">
              {/* Row */}
              <div className="items-center gap-8 hidden md:grid md:grid-cols-2 xl:gap-16">
                <div className="text-gray-123 dark:text-gray-400 sm:text-lg">
                  <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Dive into the World of Board Games
                  </h2>
                  <p className="mb-8 font-light lg:text-xl">
                    Immerse yourself in the exciting world of board games.
                    Explore a wide range of tabletop gaming experiences that
                    bring friends and family together for hours of fun and
                    strategy.
                  </p>
                  {/* List */}
                  <ul
                    role="list"
                    className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700"
                  >
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Explore a Variety of Game Genres
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Board Game Recommendations and Reviews
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Join Game Nights
                      </span>
                    </li>
                  </ul>
                  <p className="mb-8 font-light lg:text-xl">
                    Discover new board games, learn the rules, and find the
                    perfect game for your next game night.
                  </p>
                  <Link
                    to={"/boardgames/view"}
                    className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 px-5 py-3 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800 sm:w-auto"
                  >
                    {" "}
                    Explore all
                    <MdOutlineArrowForwardIos className="ml-2" />
                  </Link>
                </div>
                {/* <img
                  className="mb-4 hidden w-full rounded-lg lg:mb-0 lg:flex"
                  src="./images/boardgame-1.png"
                  alt="board game feature image"
                /> */}
                {data.topBoardgames && <TopGaming data={data?.topBoardgames} />}
              </div>
              <div className="items-center md:hidden gap-8 grid grid-cols-1 xl:gap-16">
                {data.topBoardgames && <TopGaming data={data?.topBoardgames} />}
                <div className="text-gray-123 dark:text-gray-400 sm:text-lg">
                  <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Dive into the World of Board Games
                  </h2>
                  <p className="mb-8 font-light lg:text-xl">
                    Immerse yourself in the exciting world of board games.
                    Explore a wide range of tabletop gaming experiences that
                    bring friends and family together for hours of fun and
                    strategy.
                  </p>
                  {/* List */}
                  <ul
                    role="list"
                    className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700"
                  >
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Explore a Variety of Game Genres
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Board Game Recommendations and Reviews
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Join Game Nights
                      </span>
                    </li>
                  </ul>
                  <p className="mb-8 font-light lg:text-xl">
                    Discover new board games, learn the rules, and find the
                    perfect game for your next game night.
                  </p>
                  <Link
                    to={"/boardgames/view"}
                    className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 px-5 py-3 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800 sm:w-auto"
                  >
                    {" "}
                    Explore all
                    <MdOutlineArrowForwardIos className="ml-2" />
                  </Link>
                </div>
              </div>
              {/* Row */}
              <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                {data.topBooks && <TopBook data={data.topBooks} />}
                <div className="text-gray-123 dark:text-gray-400 sm:text-lg md-max:mt-6">
                  <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                    Discover the Book Nook
                  </h2>
                  <p className="mb-8 font-light lg:text-xl">
                    Step into a world of literature and coffee. Immerse yourself
                    in the cozy ambiance of our cafe while connecting with
                    fellow book lovers and exploring our curated collection of
                    books.
                  </p>
                  {/* List */}
                  <ul
                    role="list"
                    className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700"
                  >
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Author Book Signings
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Book Club Meetings
                      </span>
                    </li>
                    <li className="flex space-x-3">
                      {/* Icon */}
                      <svg
                        className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 0000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                        Book Recommendations by Our Baristas
                      </span>
                    </li>
                  </ul>
                  <p className="mb-8 font-light lg:text-xl">
                    Join our vibrant book-loving community, savor your favorite
                    brew, and lose yourself in captivating stories within the
                    cozy corners of our cafe.
                  </p>
                  <Link
                    to={"/books/view"}
                    className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 px-5 py-3 text-center text-sm font-medium text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-800 sm:w-auto"
                  >
                    {" "}
                    Explore all
                    <MdOutlineArrowForwardIos className="ml-2" />
                  </Link>
                </div>
              </div>
            </div>
          </section>

          {/* End block */}
          {/* Start block */}
          

          {/* End block */}
          
          {/* Start block */}
          <section className="bg-gray-50 dark:bg-gray-800">
             {data.testimonials && <Testimonials data={data?.testimonials} />}
          </section>
          {/* End block */}
          <section className="bg-white dark:bg-gray-900">
            <div className="mx-auto max-w-screen-2xl px-4 py-8 lg:py-24 lg:px-6">
            <h2 className="mb-6 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:mb-8 lg:text-3xl">
                Frequently asked questions
              </h2>
              <div className="mx-auto max-w-screen-md">
              <FAQ data={data.faqData}/>
              </div>
            </div>
          </section>
          {/* End block */}
          {/* Start block */}
          {/* <section className="bg-gray-50 dark:bg-gray-800">
            <div className="mx-auto max-w-screen-2xl px-4 py-8 lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 dark:text-white">
                  Start your free trial today
                </h2>
                <p className="mb-6 font-light text-gray-123 dark:text-gray-400 md:text-lg">
                  Try Landwind Platform for 30 days. No credit card required.
                </p>
                <a
                  href="#"
                  className="mr-2 mb-2 rounded-lg bg-teal-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-teal-800 focus:outline-none focus:ring-4 focus:ring-teal-300 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800"
                >
                  Free trial for 30 days
                </a>
              </div>
            </div>
          </section> */}
          {/* End block */}
          {/* <footer className="bg-white dark:bg-gray-800">
    <div className="max-w-screen-2xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
      <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-5">
        <div>
          <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
            Company
          </h3>
          <ul className="text-gray-123 dark:text-gray-400">
            <li className="mb-4">
              <a href="#" className=" hover:underline">
                About
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Careers
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Brand Center
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Blog
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
            Help center
          </h3>
          <ul className="text-gray-123 dark:text-gray-400">
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Discord Server
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Twitter
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Facebook
              </a>
            </li>
            <a href="#" className="hover:underline"></a>
            <li className="mb-4">
              <a href="#" className="hover:underline"></a>
              <a href="#" className="hover:underline">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
            Legal
          </h3>
          <ul className="text-gray-123 dark:text-gray-400">
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Privacy Policy
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Licensing
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Terms
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
            Company
          </h3>
          <ul className="text-gray-123 dark:text-gray-400">
            <li className="mb-4">
              <a href="#" className=" hover:underline">
                About
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Careers
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Brand Center
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Blog
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h3 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
            Download
          </h3>
          <ul className="text-gray-123 dark:text-gray-400">
            <li className="mb-4">
              <a href="#" className="hover:underline">
                iOS
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Android
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                Windows
              </a>
            </li>
            <li className="mb-4">
              <a href="#" className="hover:underline">
                MacOS
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div className="text-center">
        <a
          href="#"
          className="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            src="./images/logo.svg"
            className="h-6 mr-3 sm:h-9"
            alt="Landwind Logo"
          />
          Landwind
        </a>
        <span className="block text-sm text-center text-gray-123 dark:text-gray-400">
          © 2021-2022 Landwind™. All Rights Reserved. Built with{" "}
          <a
            href="https://flowbite.com"
            className="text-teal-600 hover:underline dark:text-teal-500"
          >
            Flowbite
          </a>{" "}
          and{" "}
          <a
            href="https://tailwindcss.com"
            className="text-teal-600 hover:underline dark:text-teal-500"
          >
            Tailwind CSS
          </a>
          .
        </span>
        <ul className="flex justify-center mt-5 space-x-5">
          <li>
            <a
              href="#"
              className="text-gray-123 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="text-gray-123 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="text-gray-123 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="text-gray-123 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          <li>
            <a
              href="#"
              className="text-gray-123 hover:text-gray-900 dark:hover:text-white dark:text-gray-400"
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer> */}
        </div>
      </UserLayout>
    </>
  );
};

export default BoardGame;
