import React, { useState } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import UserLayout from "layouts/user";
import { toast } from "react-toastify";
import { GalleryMain, Header, } from "./components";
import { Helmet } from "react-helmet";

const GalleryMainPage = () => {
  const [data, setData] = React.useState({});
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [contentData, setContentData] = useState([]);
  const [faQData, setFaQ] = useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/gallery/allmain`)
      .then((res) => {
        console.log(res.data)
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
        console.log(err)
        setIsLoading(false);
      });
  }, []);


  return (
    <UserLayout>
      <Helmet>
                    <title>Gallery - Meepleton board game cafe</title>
                    <meta name="description" content='Meepleton is game cafe in bhopal which has best pool settings.'/>
                </Helmet>
        {isLoading2?<>
              <div className="flex h-screen flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <Loading />
                </div>
              </div>
          </>:<>
        
      <Header data={data.contentData || []} />

      <div className="flex bg-white w-full justify-center  md-max:px-5">
        <div className="container mt-5 md:mt-8">
          <div className="mb-4"></div>
          {isLoading ? (
            <>
              <div className="flex h-screen flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <Loading />
                </div>
              </div>
            </>
          ) : (
            <>
             <GalleryMain data={data.galleryData || []}/>
            </>
          )}
        </div>
      </div>
      </>}
    </UserLayout>
  );
};

export default GalleryMainPage;
