import React from "react";
import AddDiscount from "./components/addDiscount";
import TableDiscount from "./components/tableDiscount";
import PrimaryHeading from "components/heading/PrimaryHeading";
import SecondaryHeading from "components/heading/SecondaryHeading";
import useAxiosInstance from "../../../axiosInstance";
import Loading from "components/loading";
import { toast } from "react-toastify";

const Discount = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);

  if (isLoading === true) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div>
      <PrimaryHeading title={"Add Discount"} />
      <AddDiscount />
      <SecondaryHeading title={"Discounts"} />
      {<TableDiscount />}
    </div>
  );
};

export default Discount;
