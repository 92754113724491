import React from "react";
import logo from "../../../../assets/img/layout/logoIco.png";
import DevelopmentTable from "views/admin/tables/components/DevelopmentTable";
import InvoiceTable from "components/table/invoiceTable";
import { MdCall, MdMail } from "react-icons/md";
const PreviewLibraryInvoice = (props) => {
  const {
    customerName,
    customerMb,
    customerAddress,
    gameHost,
    modeOfTranscation,
    date,
    _id,
    KeptDays,
    depositAmount,
    rentAmount,
    returnDate,
    checkOutDate,
    products,
    status,
    rentPerDayAmount
  } = props.data;
  console.log(props)
  return (
    <div className="flex justify-center">
      <div className=" container mt-3 rounded-primary bg-white p-5 md:p-10 dark:border-navy-900 dark:bg-navy-800 md-max:text-base">
      <div className="flex justify-between">
            <div className="flex  flex-wrap">
              <img src={logo} className="mr-5 h-36" />
              <div>
                <div className="text-2xl font-extrabold">
                  Meepleton Board Game Cafe
                </div>
                <div className="mt-1 text-base ">
                  DK-2/232, Danish Kunj Kolar Road
                </div>
                <div className="mt-1 text-base ">
                  Bhopal, Madhya Pradesh, 462042
                </div>
                <div className="mt-1 flex items-center text-base">
                  <MdCall className="mr-2" /> +91 8989208432
                </div>
                <div className="mt-1 flex items-center text-base">
                  <MdMail className="mr-2" /> meepleton.bgc@gmail.com
                </div>
                {/* <div className='text-2xl font-extrabold'>Meepleton Board Game Cafe</div> */}
              </div>
            </div>

            <div className="text-sm font-bold text-right">
              <div className="text-right text-xl font-semibold text-teal-400">
                INVOICE
              </div>
              ORIGINAL FOR RECIPIENT
            </div>
          </div>

          <div className="mt-5 flex w-full flex-wrap  justify-between">
            <div className="mb-1">
              <div className="text-base font-semibold">Bill To:</div>
              <div className="text-lg font-semibold">{customerName}</div>
              <div className="text-base">Ph: {customerMb}</div>
            </div>
            <div>
              <div className="w-full md:w-[500px]">
                <div className="flex w-full flex-col md:flex-row justify-between">
                  <div className="text-base font-semibold ">Invoice#</div>
                  <div className="text-left md:text-right">{_id || 448654872}</div>
                </div>

                <div className="flex w-full flex-col md:flex-row justify-between">
                  <div className="text-base font-semibold  ">Invoice Date</div>
                  <div className="text-left md:text-right">{status==='CHECKOUT'? checkOutDate : returnDate} </div>
                </div>

              </div>
            </div>
          </div>  
      <div className="m-[-15px] mt-4">
        {status==='CHECKOUT' ? 
          <InvoiceTable
            columnsData={[
              {
                Header: "Products",
                accessor: "products",
              },
              {
                Header: "Date of Issue",
                accessor: "checkOutDate",
              },
              // {
              //   Header: "Date of Returning",
              //   accessor: "returnDate",
              // },
              {
                Header: "Amount Deposit",
                accessor: "depositAmount",
              },
              // {
              //   Header: "Number of Days",
              //   accessor: "KeptDays",
              // },
              {
                Header: "Rent/Day",
                accessor: "rentPerDayAmount",
              },
              // {
              //   Header: "Rent",
              //   accessor: "rentAmount",
              // },
              // {
              //   Header: "Returning Amount",
              //   accessor: "return",
              // },
            ]}
            tableData={[
              {
                products: products.map(elem=>elem.name).join(', '),
                checkOutDate: checkOutDate,
                returnDate: returnDate,
                depositAmount: depositAmount,
                KeptDays: KeptDays,
                rentPerDayAmount:rentPerDayAmount,
                rentAmount: rentAmount,
                return: props.data.returnAmount,
              },
            ]}
            tableTitle={"Details"}
            titleColor={"text-brand-500"}
          />
        : 
          <InvoiceTable
            columnsData={[
              {
                Header: "Products",
                accessor: "products",
              },
              {
                Header: "Date of Issue",
                accessor: "checkOutDate",
              },
              {
                Header: "Date of Returning",
                accessor: "returnDate",
              },
              {
                Header: "Amount Deposit",
                accessor: "depositAmount",
              },
              {
                Header: "Number of Days",
                accessor: "KeptDays",
              },
              {
                Header: "Rent/Day",
                accessor: "rentPerDayAmount",
              },
              {
                Header: "Rent",
                accessor: "rentAmount",
              },
              {
                Header: "Returning Amount",
                accessor: "return",
              },
            ]}
            tableData={[
              {
                products: products.map(elem=>elem.name).join(','),
                checkOutDate: checkOutDate,
                returnDate: returnDate,
                depositAmount: depositAmount,
                KeptDays: KeptDays,
                rentPerDayAmount:rentPerDayAmount,
                rentAmount: rentAmount,
                return: props.data.returnAmount,
              },
            ]}
            tableTitle={"Details"}
            titleColor={"text-brand-500"}
          />
        }
          <div className="mr-4  text-right text-navy-900 dark:text-white">
            <span className="font-medium">Subtotal : </span>
            <span className="font-semibold">Rs {status === 'CHECKOUT'? depositAmount.toLocaleString():rentAmount.toLocaleString()}</span>
          </div>
        </div>
        <div className="mt-[31px] flex flex-wrap justify-between border-t text-navy-900 dark:border-navy-900 dark:text-white md-max:text-sm">
          <div className=" mt-4 font-semibold">Notes :</div>
          <div className="mt-4  mr-2 text-right md-max:text-sm ">
            {/* <span className="font-medium">Subtotal : </span>
            <span className="font-semibold">Rs {rentAmount}</span>
            <br /> */}
            {/* <span className="font-medium">Adjustments ({discountName}) : </span>
            <span className="font-semibold">
              - Rs {Math.abs(discountAmount)}
            </span> */}
            <div className="mt-2  text-2xl font-bold text-brand-500 dark:border-navy-900 md-max:text-xl">
              Rs {status === 'CHECKOUT'? depositAmount.toLocaleString():rentAmount.toLocaleString()} 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewLibraryInvoice;
