import React from "react";
import { useChannel } from "ably/react";
import UserLayout from "layouts/user";
import {
  MdBookmark,
  MdInfo,
  MdInfoOutline,
  MdMessage,
  MdReply,
  MdSearch,
  MdSend,
  MdStar,
} from "react-icons/md";
import useAxiosInstance from "../../../../axiosInstance";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import UserLoginPage from "./Login";
import { HiX } from "react-icons/hi";
import { GiAlarmClock, GiCrown, GiMeeple, GiSolarTime } from "react-icons/gi";
import { BiSolidUserCircle, BiSolidUserPlus, BiUser } from "react-icons/bi";
import { TbTrash } from "react-icons/tb";
import Loading from "components/loading";

const MeesageBox = () => {
  const userData = useSelector((state) => state.users.value);
  const [conversationId, setConversationId] = React.useState(
    window.location.href.split("/").pop()
  );
  const [messages, updateMessages] = React.useState([]);
  const containerRef = React.useRef(null);
  const [perticularConversationData, setPerticularConversationData] =
    React.useState({});

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [messages]);

  //ably
  const { channel } = useChannel(conversationId, (message) => {
    console.log(message);
    if (message.data.type === "NOTIFICATION") {
      axiosInstance
        .get(`/conversation/` + conversationId)
        .then((res) => {
          // dispatch(updateData({ data: res.data.filtedData }));
          console.log("I am updates Message box api");
          setPerticularConversationData(res.data);
          updateMessages(res.data.messageData);
          // console.log(res.data)
        })
        .catch((err) => {
          toast.error("Something went Wrong!");
        });

      axiosInstance
        .post(`/conversation/all/user`, { userId: userId })
        .then((res) => {
          // dispatch(updateData({ data: res.data.filtedData }));
          setData(res.data);

          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went Wrong!");
        });
    }
    updateMessages((prev) => [...prev, message]);
  });
  //Party Page updates

  const axiosInstance = useAxiosInstance();
  const userId = useSelector((state) => state.users.value.id);
  const userName = useSelector((state) => state.users.value.username);
  const [message, setMessage] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [isLoading3, setIsLoading3] = React.useState(false);
  

  const [data, setData] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openInfoBar, setInfoBar] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/conversation/` + conversationId)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setPerticularConversationData(res.data);
        updateMessages(res.data.messageData);
        setIsLoading(false);
        // console.log(res.data)
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Something went Wrong!");
      });
  }, [conversationId]);

  React.useEffect(() => {
    if (userId) {
      console.log(userId);
      setIsLoading2(true);
      axiosInstance
        .post(`/conversation/all/user`, { userId: userId }) //genting all conversation a user id
        .then((res) => {
          // dispatch(updateData({ data: res.data.filtedData }));
          setData(res.data);

          setIsLoading2(false);
          // console.log(res.data)
        })
        .catch((err) => {
          console.log(err);
          setIsLoading2(false);
          toast.error("Something went Wrong!");
        });
    }
  }, [userId]);

  React.useEffect(() => {
    if (!userData.isAuthenticated) {
      onOpen();
    } else {
      onClose();
    }
  }, [userData.isAuthenticated]);

  const handleSend = (e) => {
    e.preventDefault();
    const data = {
      message: message,
      senderId: userId,
      senderName: userName,
      //messageId: message.length + 1,
      conversationId: conversationId,
      type: "NORMAL",
      time: new Date(),
    };
    channel.publish("test-message", data);
    setMessage("");
    axiosInstance
      .post("/message", data) // Replace with your API endpoint
      .then((res) => {
        if (res.data.message) {
          // toast.success(res.data.message +' is saved')
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error saving message to database!");
      });
  };
  const handleLeave = () => {
    let confirm = window.confirm("Do you want to leave this party ?");
    if (confirm) {
      setIsLoading3(true)
      axiosInstance
        .patch("/conversation/removeMember/" + conversationId, {
          userId: userData.id,
          userName: userData.username,
        })
        .then((res) => {
          if (res.data) {
            //alert(res.data._id);
            toast.success(`Party have been leaved Successfully`);
            setIsLoading3(false)
          } else {
            toast.error("Something went wrong!");
            setIsLoading3(false)
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.errors.msg);
          setIsLoading3(false)
        });
    }
  };
  const handleDelete = () => {
    let confirm = window.confirm("Do you wan to delete this Party ?");
    if (confirm) {
      setIsLoading3(true)
      axiosInstance
        .patch("/conversation/delete/" + conversationId, {
          userId: userId,
          userName: userName,
        })
        .then((res) => {
          if (res.data) {
            //alert(res.data._id);
            setIsLoading3(false)
            toast.success(`Party have been delete Successfully`);
          } else {
            toast.error("Something went wrong!");
            setIsLoading3(false)
          }
        })
        .catch((err) => {
          setIsLoading3(false)
          console.log(err);
          toast.error(err.response.data.errors.msg);
        });
    }
  };
  const handleJoin = () => {
    setIsLoading3(true)
    axiosInstance
      .patch("/conversation/joinConversation/" + conversationId, {
        userId: userData.id,
        userName: userData.username,
      })
      .then((res) => {
        if (res.data) {
          //alert(res.data._id);
          setIsLoading3(false)
          toast.success(`Party have been joined Successfully`);
        } else {
          setIsLoading3(false)
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        setIsLoading3(false)
        console.log(err);
        toast.error(err.response.data.errors.msg);
      });
  };
  const handleRemoveMember = (userData) => {
    console.log(userData);
    setIsLoading3(true)
    axiosInstance
      .patch("/conversation/removeMember/" + conversationId, {
        userId: userData.userId,
        userName: userData.userName,
        removeMessage: userName + " (Admin) kicked " + userData.userName,
      })
      .then((res) => {
        if (res.data) {
          //alert(res.data._id);
          setIsLoading3(false)
          toast.success(`Party member have been kicked Successfully`);
        } else {
          setIsLoading3(false)
          toast.error("Something went wrong!");
        }
      })
      .catch((err) => {
        setIsLoading3(false)
        console.log(err);
        toast.error(err.response.data.errors.msg);
      });
  };
  //reply states and functions

  const [replyToMessage, setReplyToMessage] = React.useState("");
  const [replyToName, setRepltToName] = React.useState("");

  const handleReply = (e) => {
    e.preventDefault();
    const data = {
      message: message,
      senderId: userId,
      senderName: userName,
      replyToMessage: replyToMessage,
      replyToName: replyToName,
      conversationId: conversationId,
      type: "REPLYTO",
      time: new Date(),
    };
    channel.publish("test-message", data);
    setRepltToName("");
    setReplyToMessage("");
    setMessage("");
    axiosInstance
      .post("/message", data) // Replace with your API endpoint
      .then((res) => {
        if (res.data.message) {
          // toast.success(res.data.message +' is saved')
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error saving message to database!");
      });
  };

  const formatDate = (givenDate) => {
    const gDate = new Date(givenDate);
    const day = gDate.getDate().toString().padStart(2, "0");
    const month = (gDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const year = gDate.getFullYear().toString();
    const time = `${gDate.getHours().toString().padStart(2, "0")}:${gDate
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;
    return `${day}/${month}/${year} ${time}`;
  };

  return (
    <UserLayout>
      {/* <Modal isOpen={isOpen} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[25vh] !z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <div className="relative mt-5 w-full rounded-xl bg-white p-4 text-navy-800">
              {userData.id && (
                <HiX
                  className="absolute right-3 cursor-pointer"
                  onClick={() => {
                    onClose();
                  }}
                />
              )}
              <UserLoginPage onClose={onClose} />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal> */}
      <div className="bg-white pt-20 pb-2 text-center  md-max:px-5">
        <div className="mb-2 text-xl">Chat Room</div>
        {/* <div className="text-lg">{data.title}</div>
        <div className="text-base">{data.desc}</div> */}
      </div>
      <div className="flex w-full justify-center bg-white pb-3 md-max:px-5">
        <div className="container relative flex w-full rounded-xl border bg-white">
          <div className="hidden w-30p md:block">
            {isLoading2?<div className="h-[60vh] w-full flex justify-center items-center">
              <Loading/>
            </div>:
            <>
            <div className="flex justify-between rounded-tl-xl bg-teal-400 px-2 py-2 text-xl font-semibold text-white">
              Parties
              <div className="flex items-center justify-center">
                <div className="mr-1 flex items-center justify-center rounded-full bg-white text-xl text-teal-400">
                  <BiSolidUserCircle />
                </div>
                <div className="text-sm">{userName}</div>
              </div>
            </div>
            <div className="m-2 mb-3 flex items-center justify-center rounded-xl border py-2 px-3 text-xl">
              <input
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="w-full hover:outline-none focus:outline-none active:outline-none"
              />
              <MdSearch />
            </div>
            {
            data?.map((elem) => {
              return (
                <div
                  onClick={()=>{
                    setConversationId(elem._id)
                  }}
                  className={
                    elem._id === conversationId
                      ? "m-2 flex cursor-pointer justify-between rounded-xl border bg-gray-50 p-2 hover:bg-gray-50"
                      : "m-2 flex cursor-pointer justify-between rounded-xl border bg-white p-2 hover:bg-gray-50"
                  }
                >
                  <div className="">
                    <img className="h-20 w-20 rounded-xl" src={elem.gameImg} />
                  </div>

                  <div className="ml-4 w-full">
                    <div className="mb-1 text-xl text-gray-800">
                      {elem.title}
                    </div>
                    {/* <div className="text-sm text-gray-123">{formatDate(elem.time)}</div> */}
                  </div>
                </div>
              );
            })}
            </>}
          </div>

          <div
            className={`sm:none overflow-y-auto h-full duration-175 linear absolute top-0 !z-[5] flex  flex-col rounded-xl border bg-white p-5 pb-10  shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-[5] lg:!z-[5] xl:!z-[5] ${
              open ? "translate-x-0" : "-translate-x-[500px]"
            }`}
          >
            <div className="mb-2  items-center justify-between border-b-2 border-navy-800 pb-2 text-navy-800">
              <div className="float-right">
              <span className="cursor-pointer" onClick={() => setOpen(false)}>
                <HiX />
              </span>
              </div>
              <div className="flex justify-between rounded-tl-xl  px-2 py-2 text-xl font-semibold text-navy-800">
              Parties
              <div className="flex items-center justify-center">
                <div className="mr-1 flex items-center justify-center rounded-full bg-white text-xl text-teal-400">
                  <BiSolidUserCircle />
                </div>
                <div className="text-sm">{userName}</div>
              </div>
            </div>
            <div className="m-2 mb-3 flex items-center justify-center rounded-xl border py-2 px-3 text-xl">
              <input
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="w-full hover:outline-none focus:outline-none active:outline-none"
              />
              <MdSearch />
            </div>

            {data?.map((elem) => {
              return (
                <div
                  className={
                    elem._id === conversationId
                      ? "m-2 flex cursor-pointer justify-between rounded-xl border bg-gray-50 p-2 hover:bg-gray-50"
                      : "m-2 flex cursor-pointer justify-between rounded-xl border bg-white p-2 hover:bg-gray-50"
                  }
                >
                  <div className="">
                    <img className="h-20 w-20 rounded-xl" src={elem.gameImg} />
                  </div>

                  <div className="ml-4 w-full">
                    <div className="mb-1 text-xl text-gray-800">
                      {elem.title}
                    </div>
                    {/* <div className="text-sm text-gray-123">{formatDate(elem.time)}</div> */}
                  </div>
                </div>
              );
            })}
            </div>
          </div>

          <div className="relative w-full rounded-xl bg-gray-50 md:w-70p md:rounded-tr-xl md:rounded-br-xl">
          {isLoading?<div className="flex justify-center items-center h-[60vh] w-full">
            <Loading/>
          </div>:  
           <>
            <div className="mb-2 flex items-center justify-between rounded-t-xl bg-teal-400 px-3 py-2 text-xl font-medium text-white md:rounded-tl-none md:rounded-tr-xl md:border-b md:border-l">
              {perticularConversationData.title}{" "}
              <div className="flex items-center justify-center">
                <MdMessage
                  onClick={() => {
                    open ? setOpen(false) : setOpen(true);
                  }}
                  className="block md:hidden"
                />
                {openInfoBar ? (
                  <HiX
                    onClick={() => {
                      setInfoBar(false);
                    }}
                  />
                ) : (
                  <BsThreeDotsVertical
                    onClick={() => {
                      setInfoBar(true);
                    }}
                  />
                )}
              </div>
            </div>
            <div
              className={
                openInfoBar
                  ? "absolute top-11 z-10 block h-[60vh] w-full overflow-y-auto border-l border-t border-b bg-white px-2 py-5 transition-all"
                  : "absolute hidden h-0 w-full  bg-white px-2  py-5 transition-all"
              }
            >
              <div className="mb-1 flex items-center text-base font-medium">
                <MdInfoOutline className="mr-1" /> About
              </div>
              <div>{perticularConversationData.desc}</div>
              <div className="mt-4 mb-1 flex items-center text-base font-medium">
                <GiMeeple className="mr-1" /> Game Info
              </div>
              <div className="">
                <img
                  className="h-44 w-44 rounded-xl"
                  src={perticularConversationData.gameImg}
                />
              </div>
              <div className="mt-4 mb-1 flex items-center text-base font-medium">
                <BiUser className="mr-1" />
                Participants
              </div>
              <div className="flex flex-wrap">
                {perticularConversationData.participants?.map((elem) => {
                  return (
                    <div className="relative mr-2 flex items-center justify-center rounded-xl border bg-white p-3 text-sm">
                      {elem.userId === perticularConversationData.creatorId && (
                        <div
                          style={{ fontSize: "9px" }}
                          className="absolute right-2 top-1 rounded-md bg-teal-400 px-1 font-normal text-white"
                        >
                          Admin
                        </div>
                      )}
                      {/* {elem.userId === userId && (
                        <div className="absolute right-2 top-2 ">
                          You
                        </div>
                      )} */}
                      <div className="mr-2 flex h-8 w-8 items-center justify-center rounded-full bg-teal-400 text-xl">
                        <BiSolidUserCircle />
                      </div>
                      {elem.userName}
                      {elem.userId === userId && "(you)"}
                      {elem.userId != userId && (
                        <div className="flex">
                          {/* <BiSolidUserPlus className="ml-3 cursor-pointer text-xl" /> */}
                          {userId === perticularConversationData.creatorId && (
                            isLoading3?<Loading/>:
                            <TbTrash
                              onClick={() => {
                                handleRemoveMember({
                                  userId: elem.userId,
                                  userName: elem.userName,
                                });
                              }}
                              className="ml-2 cursor-pointer text-xl"
                            />
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="mt-4 mb-1 flex items-center text-base font-medium">
                <GiAlarmClock className="mr-1" /> Visiting Time
              </div>
              <div className="">
                {formatDate(perticularConversationData.time)}
              </div>

              <button
                onClick={() => {
                  isLoading3?console.log():handleLeave();
                }}
                disabled={isLoading3}
                className="mt-4 inline-flex w-full items-center justify-center rounded-lg  bg-yellow-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-100 dark:border-yellow-700 dark:text-white dark:hover:bg-yellow-700 dark:focus:ring-yellow-800 sm:w-auto"
              >{isLoading3?<Loading/>:<>Leave Party</>}
              </button>
              {userId === perticularConversationData.creatorId && (
                <button
                  onClick={() => {
                   isLoading3?console.log(): handleDelete();
                  }}
                  disabled={isLoading3}
                  className="mt-4 inline-flex w-full items-center justify-center rounded-lg bg-red-400  px-3 py-2 text-center text-sm font-medium text-white ms-2 hover:bg-red-500 focus:ring-4 focus:ring-red-100 dark:border-red-700 dark:text-white dark:hover:bg-red-700 dark:focus:ring-red-800 sm:w-auto"
                >
                  {isLoading3?<Loading/>:<>Delete Party</>}
                </button>
              )}
            </div>
            {perticularConversationData.isDeleted === "true" ? (
              <div className="flex h-[60vh] flex-col items-center justify-center bg-gray-50">
                This Party has been deleted by Admin.
                <div className="mt-2 flex">
                  {perticularConversationData.participants?.find(
                    (elem) => elem.userId === userId
                  ) && (
                    <button
                      onClick={() => {
                        isLoading3?console.log():
                        handleLeave();
                      }}
                      disabled={isLoading3}
                      className="mr-2 inline-flex w-full items-center justify-center rounded-lg  bg-yellow-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-100 dark:border-yellow-700 dark:text-white dark:hover:bg-yellow-700 dark:focus:ring-yellow-800 sm:w-auto"
                    >
                      {isLoading3?<Loading/>:<>Leave Party</>}
                    </button>
                  )}
                  {perticularConversationData.creatorId === userData.id && (
                    <button
                      onClick={() => {
                        axiosInstance
                          .patch("/conversation/" + conversationId, {
                            ...perticularConversationData,
                            isDeleted: "false",
                          })
                          .then((res) => {
                            if (res.data) {
                              toast.success(
                                `Party have been revived Successfully`
                              );
                              channel.publish("test-messsage", {
                                type: "NOTIFICATION",
                              });
                            } else {
                              toast.error("Something went wrong!");
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            toast.error(err.response.data.errors.msg);
                          });
                      }}
                      disabled={isLoading3}
                      className=" inline-flex w-full items-center justify-center rounded-lg  bg-teal-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                    >
                      {isLoading3?<Loading/>:<>Revive Party</>}
                    </button>
                  )}
                </div>
              </div>
            ) : perticularConversationData.participants?.find(
                (elem) => elem.userId === userId
              ) ? (
              <>
                <div
                  ref={containerRef}
                  className="flex h-[60vh] flex-col overflow-y-auto p-2"
                >
                  {messages.map((msg, index) => {
                    return msg.data.type === "NOTIFICATION" ? (
                      <div className="flex w-full justify-center">
                        <div
                          className={
                            "mb-2 min-w-min max-w-[60%] rounded-xl border bg-white p-1 "
                          }
                          key={index}
                        >
                          <div className=" text-sm text-gray-500 ">
                            {msg.data.message}
                          </div>
                        </div>
                      </div>
                    ) : msg.data.type === "REPLYTO" ? (
                      <>
                        <div
                          className={
                            msg.data.senderId == userId
                              ? "flex w-full justify-end pr-2"
                              : "flex w-full justify-start pl-2"
                          }
                        >
                          <div
                            className={
                              msg.data.senderId == userId
                                ? "mb-2 min-w-min max-w-[60%] rounded-l-xl rounded-br-xl border bg-white p-2 px-2"
                                : "mb-2 min-w-min max-w-[60%] rounded-r-xl rounded-bl-xl border bg-white p-2 px-2"
                            }
                            key={index}
                          >
                            <div className="mb-2 rounded-xl border bg-white p-2">
                              <div className={"text-sm text-gray-500"}>
                                {msg.data.replyToName}
                              </div>
                              <div className="text-sm text-gray-800 md:text-base lg:text-base">
                                {msg.data.replyToMessage}
                              </div>
                            </div>
                            <div className="relative">
                              {msg.data.senderId != userId && (
                                <MdReply
                                  onClick={() => {
                                    setRepltToName(msg.data.senderName);
                                    setReplyToMessage(msg.data.message);
                                  }}
                                  className="float-right ml-2 cursor-pointer text-lg"
                                />
                              )}
                              <div>
                                <div className={"text-sm text-gray-500"}>
                                  {msg.data.senderId != userId &&
                                    msg.data.senderName}
                                </div>
                                <div className="text-sm text-gray-800 md:text-base lg:text-base">
                                  {msg.data.message}
                                </div>
                                <div className="float-right text-[9px]">
                                  {formatDate(msg.data.time)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className={
                          msg.data.senderId == userId
                            ? "flex w-full justify-end pr-2"
                            : "flex w-full justify-start pl-2"
                        }
                      >
                        <div
                          className={
                            msg.data.senderId == userId
                              ? "mb-2 min-w-min max-w-[60%] rounded-l-xl rounded-br-xl border bg-white p-2 px-2"
                              : "mb-2 min-w-min max-w-[60%] rounded-r-xl rounded-bl-xl border bg-white p-2 px-2"
                          }
                          key={index}
                        >
                          <div className="relative">
                            {msg.data.senderId != userId && (
                              <MdReply
                                onClick={() => {
                                  setRepltToName(msg.data.senderName);
                                  setReplyToMessage(msg.data.message);
                                }}
                                className="float-right ml-2 cursor-pointer text-lg"
                              />
                            )}
                            <div>
                              <div className={"text-sm text-gray-500"}>
                                {msg.data.senderId != userId &&
                                  msg.data.senderName}
                              </div>
                              <div className="text-sm text-gray-800 md:text-base lg:text-base">
                                {msg.data.message}
                              </div>
                              <div className="float-right text-[9px]">
                                {formatDate(msg.data.time)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="h-15 flex flex-col items-center justify-center p-2">
                  {replyToName && (
                    <div>
                      <div
                        className={
                          "relative mb-1 rounded-xl border bg-white p-2"
                        }
                      >
                        <div className="float-right">
                          <HiX
                            className="cursor-pointer"
                            onClick={() => {
                              setRepltToName("");
                              setReplyToMessage("");
                            }}
                          />
                        </div>
                        <div className={"text-sm text-gray-500"}>
                          {replyToName}
                        </div>
                        <div className="text-sm text-gray-800 md:text-base lg:text-base">
                          {replyToMessage}
                        </div>
                      </div>
                    </div>
                  )}
                  <form
                    onSubmit={(e) => {
                      replyToName ? handleReply(e) : handleSend(e);
                    }}
                    className="flex rounded-xl border bg-white  px-5 shadow-sm"
                  >
                    <input
                      required
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      className="h-16 w-full rounded-l-xl border-0 focus:outline-none active:border-0 active:outline-none md:w-[350px] lg:w-[450px] "
                    />
                    <button
                      type="submit"
                      className="rounded-r-xl font-poppins text-base font-medium  text-teal-400 transition duration-200 hover:text-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200 "
                    >
                      <MdSend />
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <div className="flex h-[60vh] flex-col items-center justify-center bg-gray-50">
                { perticularConversationData.noOfUsers>=perticularConversationData.participants?.length && <div>
                  Do you want to join this Party ?
                  <a
                    onClick={() => isLoading3?console.log(): handleJoin()}
                    className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-2 py-1 text-center text-sm font-medium text-white ms-2 hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                  >
                    {isLoading3?<Loading/>:<>Join</>}
                  </a>
                </div>}
              </div>
            )}
            </>}
          </div>
          
        </div>
      </div>
    </UserLayout>
  );
};

export default MeesageBox;
