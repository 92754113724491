import { toast } from 'react-toastify';
import useAxiosInstance from '../../../../axiosInstance'
import React from 'react'
import MainTable from 'components/table';
import Loading from 'components/loading';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from 'react-icons/md';
import MainTable2 from 'components/table/Tablev2';
import { useDispatch, useSelector } from 'react-redux';
import { updateRefresher } from 'features/refresher';

const TableDiscount = (props) => {

const dispatch = useDispatch();
const refresh = useSelector(state=>state.refresher.value.refreshCount)
const axiosInstance = useAxiosInstance();
const [isLoading,setIsLoading] = React.useState(false);
const [isLoading2,setIsLoading2] = React.useState(false);


const { isOpen, onOpen, onClose } = useDisclosure();

const [name,setname] = React.useState("")  
const [price,setprice] = React.useState("");
const [id , setid] = React.useState("");    

//errors for price 

const [nameMessage,setnameMessage] = React.useState("");
const [nameState,setnameState] = React.useState();

const [priceMessage,setpriceMessage] = React.useState("");
const [priceState,setpriceState] = React.useState();
    
const handleEdit =(e) =>{
  e.preventDefault()
  setIsLoading2(true)
  axiosInstance.patch('/discount/'+id,{
      name:name,
      price:price
    }).then(res=>{
      if(res.data){
        toast.success('Discount "'+ res.data.name +'" is Edited Successfully!')
        dispatch(updateRefresher({refreshCount:refresh+1}))
      }
    }).catch(err=>{
      if(err.response.data.errors.msg){
        toast.error('Discount with Name already exists!')
      }
  })
  setIsLoading2(false)
}

React.useEffect(() => {
  if(name === ""){
    setnameMessage("Name cannot be empty!");
    setnameState()
  }
  else {
    setnameMessage("");
    setnameState('success');
  }

  if(price === ""){
    setpriceMessage("")
    setpriceState()
  }else if (price> 0) {
    setpriceMessage("Price must be a Negative Integer!");
    setpriceState('error')
  } else {
    setpriceMessage("");
    setpriceState('success')
  }
}, [name, price]);

const deleteFunc = (id) =>{
  var confirm = window.confirm('Do you want to delete #'+id+ " Discount ?")
  if(confirm){
    setIsLoading(true)
    axiosInstance.delete('/discount/'+id).then(res=>{
      if(res.data.msg){
        toast.success('Discount is deleted Successfully!')
        dispatch(updateRefresher({refreshCount:refresh+1}))
      }
    }).catch(err=>{
      toast.error('Something Went Wrong!')
    })
    setIsLoading(false)
  }
}

const editFunc = (id) =>{
  onOpen();
    toast.info("Looking for book in database");
    axiosInstance
      .get("/discount/" + id)
      .then((res) => {
        toast.success("Successfully Found!");
        const discountData = res.data
        const {_id,name,price} = discountData
        setname(name)
        setprice(price)
        setid(_id)        
      })
      .catch((err) => {
        console.log(err);
      }); 
}

return (
  <>
  
   {<MainTable2 url={"/discount/allbyQuery"} tableKeys={['name','price']}  editFunc={editFunc} deleteFunc={deleteFunc}/>}
   
   <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
    <ModalOverlay className="bg-[#000] !opacity-30" />
    <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[25vh] md:top-[12vh]">
      <ModalBody>
      <div className=" bg-white p-4 rounded-primary dark:border-navy-900 dark:bg-navy-800 ">
        {/* <img src={navlogo} height={100}/> */}
        {/* name */}

        <form onSubmit={(e)=>{handleEdit(e)}}>
        {nameMessage&&<span className="text-red-400 ms-1">{nameMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Name"
          placeholder="Discount Name"
          id="name"
          type="name"
          state={nameState}
          value={name}
          onChange={(e)=>{
            setname(e.target.value)
          }}
        />

        {/* Price */}
        
        {priceMessage&&<span className="text-sm text-red-500 ms-1">{priceMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Price"
          placeholder="Price"
          id="price"
          type="Number"
          state={priceState}
          value={price}
          onChange={(e)=>{
            setprice(e.target.value)
          }}
        />
        {isLoading2?<button disabled className="linear text-center mt-2 px-4 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          <Loading/>          
        </button>:nameMessage ==="" && priceMessage ==="" && <button
        type={'submit'} 
        className="linear mt-2 me-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Save Changes
        </button>
        
        }
        <button
              type='button'
              onClick={onClose}
              className="linear mt-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Close
            </button>
        </form>
      </div>
      </ModalBody>
    </ModalContent>
  </Modal>
  </>
  )
}

export default TableDiscount
