import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import {
  MdBarChart,
  MdDashboard,
  MdFilter,
  MdFilter1,
  MdFoodBank,
  MdGamepad,
  MdInbox,
} from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import {
  FaCashRegister,
  FaDollarSign,
  FaFileInvoice,
  FaFilter,
  FaRupeeSign,
  FaUser,
} from "react-icons/fa";
import { HiCash } from "react-icons/hi";
import { TbDiscOff, TbDiscount } from "react-icons/tb";
import Top7Gaming from "./components/Top7Gaming";
import Top7FoodAndBeverages from "./components/Top7FnB";
import { useEffect, useState } from "react";
import useAxiosInstance from "../../../axiosInstance";
import Loading from "components/loading";
import CashVsUPIPieChart from "./components/CashVsUpiChart";
import Last7Transcation from "./components/last7Transcations";
import TopDiscounts from "./components/TopDiscounts";
import TopHoursChart from "./components/TopHours";
import BatchChart from "./components/batchChart";
import Card from "components/card";
import InputField from "components/fields/InputField";
import PopoverHorizon from "components/popover";
import Dropdown from "components/dropdown";
import { useChannel } from "ably/react";
import PerformanceChart from "./components/performanceChart";
import DayWiseChart from "./components/dayWiseGraph";
import MonthWiseChart from "./components/monthWiseChart";

const Dashboard = () => {
  const [data, setData] = useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState(false);

  const [startingDate,setStartingDate] = useState("");
  const [endingDate,setEndingDate] = useState("");

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .post("/dashboard/getAllWidgets", { type: "ALLTIME" })
      .then((res) => {
        setData(res.data);
        // console.log(res.data.Top7Hours)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  const { channel } = useChannel
  ("Dashboard-Page", (message) => {
    console.log(message)
    if (message) {
      axiosInstance
      .post("/dashboard/getAllWidgets", { type: "ALLTIME" })
      .then((res) => {
        setData(res.data);
        // console.log(res.data.Top7Hours)
      })
      .catch((error) => {
        console.log(error);
      })
      setData(message.data);
    }
  });

  const handleSubmit = () =>{
    setIsLoading(true);
    axiosInstance
      .post("/dashboard/getAllWidgets", { type: "FILTERED" ,startingDate:startingDate,endingDate:endingDate})
      .then((res) => {
        setData(res.data);
        // console.log(res.data.Top7Hours)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }
  const {
    details,
    top7Gaming,
    top7FnB,
    cashVsUpi,
    last7Transcation,
    top7discount,
    top7Hours,
    batchGraph,
    performanceGraph,
    dayWiseGraph,
    monthWiseGraph
  } = data;
  //console.log(batchGraph)
  const {
    total,
    fNbTotal,
    gamingTotal,
    discountAmount,
    upiTotal,
    cashTotal,
    invoice,
    uniqueCustomer,
    averageTranscation,
    averageDiscount,
  } = details || {
    total: 0,
    fNbTotal: 0,
    gamingTotal: 0,
    discountAmount: 0,
    upiTotal: 0,
    cashTotal: 0,
    invoice: 0,
    uniqueCustomer: 0,
    averageTranscation: 0,
    averageDiscount: 0,
  };
  return (
    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-2 gap-5 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Total"}
          subtitle={`Rs ` + Math.round(total)?.toLocaleString()}
        />
        <Widget
          icon={<MdFoodBank className="h-6 w-6" />}
          title={"Food and Beverages"}
          subtitle={`Rs ` + Math.round(fNbTotal)?.toLocaleString()}
        />
        <Widget
          icon={<MdGamepad className="h-7 w-7" />}
          title={"Gaming"}
          subtitle={`Rs ` + Math.round(gamingTotal)?.toLocaleString()}
        />
        <Widget
          icon={<FaFileInvoice className="h-6 w-6" />}
          title={"Invoices"}
          subtitle={invoice?.toLocaleString()}
        />
        <Widget
          icon={<FaUser className="h-7 w-7" />}
          title={"Unique Customers"}
          subtitle={uniqueCustomer?.toLocaleString()}
        />
        <Widget
          icon={<FaRupeeSign className="h-6 w-6" />}
          title={"Average Transaction"}
          subtitle={"Rs " + averageTranscation?.toLocaleString()}
        />
        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"UPI"}
          subtitle={`Rs ` + Math.round(upiTotal)?.toLocaleString()}
        />
        <Widget
          icon={<HiCash className="h-7 w-7" />}
          title={"CASH"}
          subtitle={`Rs ` + Math.round(cashTotal)?.toLocaleString()}
        />
        <Widget
          icon={<TbDiscount className="h-6 w-6" />}
          title={"Average Discount"}
          subtitle={`Rs ` + Math.abs(averageDiscount)?.toLocaleString()}
        />
      </div>

      {/* Charts */}

    <div className="flex w-full justify-end md:fixed md:z-50  right-7 mt-5">
    <Dropdown
      button={
      <button className="bg-brand-500  hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200 rounded-xl px-5 py-3 text-base font-medium text-white transition duration-200 dark:text-white">
        <FaFilter/>
      </button>
      }
      children={
        <Card extra="!flex-row flex-grow p-5 items-center mt-4 rounded-[20px]">
        <div className="flex flex-col w-auto justify-between m-2">
          <InputField value={startingDate} onChange={e=>{setStartingDate(e.target.value)}} label={'Starting Date'} type={'date'}/>
          
          <span className="mt-2"></span>
          {startingDate!="" && 
          <InputField value={endingDate} onChange={e=>{setEndingDate(e.target.value)}} label={'Ending Date'} type={'date'} />
          }
          
          {startingDate!="" && endingDate!="" &&
          <button  onClick={()=>{handleSubmit()}} className="bg-brand-500 mt-3 flex justify-center h-8 items-center hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200 rounded-xl px-5 py-3 text-base font-medium text-white transition duration-200 dark:text-white">
          <FaFilter/>
          </button>
          }
        </div>
      </Card> 
      }
      classNames={"py-2 -left-[180px] w-max"}
    />
    </div>
      <div className="mt-5 ">
        {dayWiseGraph && <DayWiseChart {...dayWiseGraph} />}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {performanceGraph&&<PerformanceChart {...performanceGraph} />}
        {/* <WeeklyRevenue /> */}
        <TopHoursChart x={top7Hours?.x} y={top7Hours?.y} />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <Top7Gaming x={top7Gaming?.x} y={top7Gaming?.y} />
        <Top7FoodAndBeverages x={top7FnB?.x} y={top7FnB?.y} />
      </div>
      {/* <DailyTraffic /> */}
      {/* Tables & Charts */}
      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        <div>
          {/* <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          />  */}
          <Last7Transcation
            labels={last7Transcation?.labels}
            gamingData={last7Transcation?.gamingData}
            FnBData={last7Transcation?.FnBData}
          />
        </div>

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
        <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
          {/* <DailyTraffic /> */}
          {cashVsUpi && (
            <CashVsUPIPieChart
              labels={cashVsUpi.labels || ["CASH", "UPI"]}
              data={cashVsUpi.data || [0, 0]}
            />
          )}
        </div>
      </div>
      
      <div className="grid mt-5 grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          {monthWiseGraph&&<MonthWiseChart {...monthWiseGraph}/>}
        {top7discount && <TopDiscounts data={top7discount} />}
        </div>
    </div>
  );
};

export default Dashboard;
