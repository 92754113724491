import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SecondaryHeading from "components/heading/SecondaryHeading";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";
import MainTable2 from "components/table/Tablev2";

const TableProduct = (props) => {
  //refresher setup
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);

  const [data, setData] = React.useState(props.data);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [name, setname] = React.useState("");
  const [price, setprice] = React.useState("");
  const [type, setType] = React.useState("");
  const [qty, setQty] = React.useState("");

  const [id, setid] = React.useState("");

  //errors for price
  const [nameMessage, setnameMessage] = React.useState("");
  const [nameState, setnameState] = React.useState();

  const [priceMessage, setpriceMessage] = React.useState("");
  const [priceState, setpriceState] = React.useState();

  const [qtyMessage, setQtyMessage] = React.useState("");
  const [qtyState, setQtyState] = React.useState("");

  //validation logic
  React.useEffect(() => {
    if (name === "") {
      setnameMessage("Name cannot be empty!");
      setnameState();
    } else {
      setnameMessage("");
      setnameState("success");
    }

    if (price === "") {
      setpriceMessage("");
      setpriceState();
    } else if (price < 0) {
      setpriceMessage("Price must be a Positive Integer!");
      setpriceState("error");
    } else {
      setpriceMessage("");
      setpriceState("success");
    }

    if (qty === "") {
      setQtyMessage("");
      setQtyState();
    } else if (qty < 0) {
      setQtyMessage("Qty must be a Positive Integer!");
      setQtyState("error");
    } else {
      setQtyMessage("");
      setQtyState("success");
    }
  }, [name, price, qty]);

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/product/" + id, {
        name: name,
        price: price,
        category: type,
        qty: qty,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'Product "' + res.data.name + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("Product with this Name already exists!");
        }
      });
    setIsLoading2(false);
  };

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " Product ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/product/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("Product is deleted Successfully!");
            dispatch(updateRefresher({ refreshCount: refresh + 1 }));
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    toast.info("Looking for Product in database");
    axiosInstance
      .get("/product/" + id)
      .then((res) => {
        toast.success("Successfully Found!");
        const { _id, name, price, qty, category } = res.data;
        setname(name);
        setprice(price);
        setType(category);
        setQty(qty);
        setid(_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {/* <button onClick={()=>{setRefresh(refresh+1)}} className='linear mt-2 me-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'><MdRefresh/></button>  */}
      { (
        <div>
          <MainTable2
            urlType='filter'
            url="/product/allbyQuery?filter=GAMING&fields=category"
            tableKeys={["name", "price"]}
            editFunc={editFunc}
            deleteFunc={deleteFunc}
          />

          <SecondaryHeading title={"Food and Beverages"} />

          <MainTable2
            tableKeys={["name", "price", "qty"]}
            url="/product/allbyQuery?filter=FNB&fields=category"
            urlType='filter'
            tableId={"#table2"}
            tableIdd={"table2"}
            editFunc={editFunc}
            deleteFunc={deleteFunc}
          />
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-lg border bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                {nameMessage && (
                  <span className="text-red-400 ms-1">{nameMessage}</span>
                )}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Name"
                  placeholder="Product Name"
                  id="name"
                  type="name"
                  state={nameState}
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />

                {/* Price */}

                {priceMessage && (
                  <span className="text-sm text-red-500 ms-1">
                    {priceMessage}
                  </span>
                )}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Price"
                  placeholder="Price"
                  id="price"
                  type="Number"
                  state={priceState}
                  value={price}
                  onChange={(e) => {
                    setprice(e.target.value);
                  }}
                />

                {/* type */}

                <label
                  htmlFor="Type"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Type
                </label>
                <select
                  id="Type"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                >
                  <option selected>Choose</option>
                  <option value="FNB">Food and Bevrages</option>
                  <option value="GAMING">Gaming</option>
                </select>

                {type === "FNB" && (
                  <div>
                    {qtyMessage && (
                      <span className="text-sm text-red-500 ms-1">
                        {qtyMessage}
                      </span>
                    )}
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Quantity"
                      placeholder="Quantity"
                      id="price"
                      type="Number"
                      state={qtyState}
                      value={qty}
                      onChange={(e) => {
                        setQty(e.target.value);
                      }}
                    />
                  </div>
                )}
                {isLoading2 ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  nameMessage === "" &&
                  priceMessage === "" && (
                    <button
                      type={"submit"}
                      className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Save Changes
                    </button>
                  )
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableProduct;
