import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useState } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SelectField from "components/fields/selectField";
import { updateRefresher } from "features/refresher";
import { useDispatch, useSelector } from "react-redux";
import MainTable2 from "components/table/Tablev2";

const TableFaQ = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setid] = React.useState("");

  const [answer, setAnswer] = useState([]);
  const [type, setType] = useState("");
  const [question, setQuestion] = useState("");

  //Answer Validation
  const [answerMessage, setAnswerMessage] = useState("");
  const [answerState, setAnswerState] = useState("");

  //Type Validation
  const [typeMessage, setTypeMessage] = useState("");
  const [typeState, setTypeState] = useState("");

  //Question Validation
  const [questionMessage, setQuestionMessage] = useState("");
  const [questionState, setQuestionState] = useState("");

  //refresher setup
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/faq/" + id, {
        Answers: answer,
        Type: type,
        Question: question,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'FAQ for"' + res.data.type + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("FAQ with Question and type already exists!");
        }
      });
    setIsLoading2(false);
  };

  // React.useEffect(() => {
  //   if(name === ""){
  //     setnameMessage("Name cannot be empty!");
  //     setnameState()
  //   }
  //   else {
  //     setnameMessage("");
  //     setnameState('success');
  //   }

  //   if(price === ""){
  //     setpriceMessage("")
  //     setpriceState()
  //   }else if (price> 0) {
  //     setpriceMessage("Price must be a Negative Integer!");
  //     setpriceState('error')
  //   } else {
  //     setpriceMessage("");
  //     setpriceState('success')
  //   }
  // }, [name, price]);

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " FaQ ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/faq/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("FaQ is deleted Successfully!");
            dispatch(updateRefresher({ refreshCount: refresh + 1 }));
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    toast.info("Looking for FaQ in database");
    axiosInstance
      .get("/faq/" + id)
      .then((res) => {
        toast.success("Successfully Found!");
        const faqData = res.data;
        const { _id, Answers, Question, Type } = faqData;
        setAnswer(Answers);
        setQuestion(Question);
        setType(Type);
        setid(_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {(
        <MainTable2
          url={'/faq/allByQuery'}
          tableKeys={["Question", "Type", "Answers"]}
          refresh={refresh}
          tableData={data}
          editFunc={editFunc}
          deleteFunc={deleteFunc}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800 ">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                {/* Question */}
                {questionMessage && (
                  <span className="text-red-400 ms-1">{questionMessage}</span>
                )}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Question"
                  placeholder="Enter Question"
                  id="Question"
                  type="text"
                  state={questionState}
                  value={question}
                  onChange={(e) => {
                    setQuestion(e.target.value);
                  }}
                />

                {/* Answer */}

                {answerMessage && (
                  <span className="text-sm text-red-500 ms-1">
                    {answerMessage}
                  </span>
                )}
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Answer(use '|'for more than one Para)"
                  placeholder="Enter Answer"
                  id="answer"
                  type="text"
                  state={answerState}
                  value={answer.join("|")}
                  onChange={(e) => {
                    setAnswer(e.target.value.split("|"));
                  }}
                />

                {/* Answer */}
                {typeMessage && (
                  <span className="text-sm text-red-500 ms-1">
                    {typeMessage}
                  </span>
                )}
                <SelectField
                  variant="auth"
                  label="Type"
                  placeholder="Enter Type"
                  id="type"
                  type="text"
                  state={typeState}
                  value={[
                    { label: "Home Page", value: "HOME" },
                    { label: "Board game Page", value: "BG" },
                    { label: "Book Page", value: "BOOK" },
                    { label: "Pool Page", value: "POOL" },
                    { label: "FNB Page", value: "FNB" },
                    { label: "Reservation Page", value: "RESERVATIONS" },
                    { label: "Parties Page", value: "PARTIES" },
                  ].find((elem) => elem.value === type)}
                  onChange={(e) => {
                    setType(e.value);
                  }}
                  options={[
                    { label: "Home Page", value: "HOME" },
                    { label: "Board game Page", value: "BG" },
                    { label: "Book Page", value: "BOOK" },
                    { label: "Pool Page", value: "POOL" },
                    { label: "FNB Page", value: "FNB" },
                    { label: "Reservation Page", value: "RESERVATIONS" },
                    { label: "Parties Page", value: "PARTIES" },
                  ]}
                />

                {isLoading2 ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  answerMessage === "" &&
                  typeMessage === "" &&
                  questionMessage === "" && (
                    <button
                      type={"submit"}
                      className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Save Changes
                    </button>
                  )
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableFaQ;
