import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import SelectField from "components/fields/selectField";
import { updateRefresher } from "features/refresher";

const AddEvents = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const refresh = useSelector(state=>state.refresher.value.refreshCount)

  //States for Deposit Forms
  const [title, settitle] = useState("");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [eventType, seteventType] = useState("");
  const [imageSrc, setimageSrc] = useState("");
  const [desc, setdesc] = useState("");
  const [time, settime] = useState("");
  const [imageType, setimageType] = useState("");
  const [guests,setguests] = useState("");

  const axiosInstance = useAxiosInstance();

  const handleImage = async (e) => {
    try {
      toast.info("Image are uploading to database");
      for (const element of e.target.files) {
        const data = new FormData();
        data.append("file", element);
        data.append("upload_preset", "Meepleton");
        data.append("cloud_name", "dqzute9m4");

        const resp = await fetch(
          "https://api.cloudinary.com/v1_1/dqzute9m4/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await resp.json();
        setimageSrc(imageData.secure_url);
      }
      toast.success("Image are successfully Uploaded to database");
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post("/events", {
        title: title,
        start: start,
        end: end,
        imageSrc: imageSrc,
        desc: desc,
        time: time,
        guests:guests
      })
      .then((res) => {
        if (res.data) {
          toast.success(`Event for ${res.data.start} created Successfully`);
          dispatch(updateRefresher({refreshCount:refresh+1}))
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };

  return (
    <div className=" rounded-primary bg-white p-4  dark:border-navy-900 dark:bg-navy-800 ">
      {/* <img src={navlogo} height={100}/> */}
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        <div className="grid md:grid-cols-4 md:gap-4 grid-cols-1 ">
        <InputField
          variant="auth"
          extra="mb-3"
          label="Title"
          placeholder="Enter title"
          id="title"
          start="text"
          value={title}
          onChange={(e) => {
            settitle(e.target.value);
          }}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Description"
          type="text"
          placeholder="Enter description"
          id="start"
          value={desc}
          onChange={(e) => {
            setdesc(e.target.value);
          }}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Time"
          type="text"
          placeholder="Enter time"
          id="time"
          value={time}
          onChange={(e) => {
            settime(e.target.value);
          }}
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Number of guests"
          type="Number"
          placeholder="Enter numbe of guests"
          id="time"
          value={guests}
          onChange={(e) => {
            setguests(e.target.value);
          }}
        />
        </div>  
        <SelectField
          label={"Is this Event an all day event?"}
          isSearchable={false}
          onChange={(e) => {
            seteventType(e.value);
          }}
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
        {eventType !== "" ? (
          eventType === true ? (
            <InputField
              variant="auth"
              extra="mb-3"
              label="Date and Time"
              type="date"
              placeholder="Enter date"
              id="start"
              start="text"
              value={start}
              onChange={(e) => {
                setstart(e.target.value);
              }}
            />
          ) : (
            <>
              <InputField
                variant="auth"
                extra="mb-3"
                label="Start Date and Time"
                type="datetime-local"
                placeholder="Enter date"
                id="start"
                start="text"
                value={start}
                onChange={(e) => {
                  setstart(e.target.value);
                }}
              />

              <InputField
                variant="auth"
                extra="mb-3"
                label="End Date and Time"
                type="datetime-local"
                placeholder="Enter date"
                id="start"
                start="text"
                value={end}
                onChange={(e) => {
                  setend(e.target.value);
                }}
              />
            </>
          )
        ) : (
          <></>
        )}

        <SelectField
          label={"Image type"}
          isSearchable={false}
          onChange={(e) => {
            setimageType(e.value);
          }}
          options={[
            { label: "Board game Image", value: true },
            { label: "Choose Image", value: false },
          ]}
        />

        {imageType !== "" ? (
          imageType === true ? (
            <div className="w-full">
              <SelectField
                label={"Games"}
                // value={games}
                onChange={(e) => {
                  setimageSrc(e.bgImage);
                }}
                options={props.data?.map((element) => ({
                  label: element.bgName,
                  value: element.bgName,
                  bgImage: element.bgImage,
                }))}
              />
            </div>
          ) : (
            <>
              <div>
                <label className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white">
                  Choose Image
                </label>
              </div>
              <input
                className="mt-2 w-full rounded-xl border py-2 px-3 text-navy-800 file:rounded-md file:border-0  file:bg-gray-100"
                variant="auth"
                type="file"
                label="Image"
                placeholder="Choose image"
                id="image"
                onChange={(e) => handleImage(e)}
              />
            </>
          )
        ) : (
          <></>
        )}

        {imageSrc && <img src={imageSrc} className="max-w-xs" />}

        

        {isLoading ? (
          <button
            disabled
            className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <Loading />
          </button>
        ) : (
          <button
            start={"submit"}
            className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Add
          </button>
        )}
      </form>
    </div>
  );
};

export default AddEvents;
