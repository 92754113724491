import React from 'react'
import Select from 'react-select';

const SelectField = (props) => {
  const {label,options,onChange,value,isSearchable,isMulti,placeHolder, extra,selectedValue} = props
  return (
    
    <div>
      <label
            htmlFor="serviceType"
            className="mb-3 mt-2 ml-1.5 block text-sm font-medium text-gray-900 ms-2 dark:text-white"
          >
            {label}
          </label>
          
        <Select
            defaultValue={value}
            value={selectedValue}
            onChange={onChange}
            unstyled
            placeholder={placeHolder}
            classNames={{
              control: () => extra ||"mt-2 p-2 h-12 bg-white/0  border text-sm dark:text-white rounded-xl mb-3 dark:border-white/10 ",
              menuList:()=> "border dark:bg-white bg-white rounded-xl shadow-sm",
              placeholder:()=>"text-gray-600 font-thin",
              option:()=>"hover:bg-teal-50 p-2"
              //container:()=>"bg-navy-900"
            }}
            isSearchable={isSearchable}
            options={options}
            isMulti={isMulti || false}
          />
    </div>
  )
}

export default SelectField
