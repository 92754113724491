import React from 'react'
import AddInvoice from './components/addInvoice'
import PrimaryHeading from 'components/heading/PrimaryHeading'
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { useState,useEffect} from "react";

const Invoice = () => {
  const [data, setData] = useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("/invoice/all/invoiceDepandants")
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);
   
  if (isLoading) {
    return <div className="h-screen flex flex-col justify-center items-center">
    <div className="flex justify-center items-center">
    <Loading/>
    </div>
    </div>
  }
  return (
    <div>
      <PrimaryHeading title={'Create Invoice'}/>  
      {data.length!=0&&<AddInvoice data={data}/>}
    </div>
  )
}

export default Invoice
