import React from "react";
import { GiBookCover, GiMeeple } from "react-icons/gi";
import {
  MdBorderLeft,
  MdEmojiEvents,
  MdOutlineBorderLeft,
} from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//EVENT, BOARDGAME, BOOK ,POOL

const Gallery = (props) => {
  const [active, setActive] = React.useState("BOARDGAME");
  const data = props.data;
  // const data = [
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/Pwk9Jl9Wu4I0DPNuMrrZrQ__imagepage/img/ABBUz_ktv-OM_nIiXNu5A_gUIrg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1916682.jpg",
  //       "https://cf.geekdo-images.com/Pwk9Jl9Wu4I0DPNuMrrZrQ__imagepage/img/ABBUz_ktv-OM_nIiXNu5A_gUIrg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1916682.jpg",
  //       "https://cf.geekdo-images.com/bl7v4Ue-DupavwfO9HuFQg__imagepage/img/6kgAIe2tSaFM2HPhcpecmgvS8fM=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1937381.jpg",
  //       "https://cf.geekdo-images.com/AwjCc6N937LNOhB_NxGsFQ__imagepage/img/fGNIs77HWqhGxfBKOgdadPf_UVo=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1937382.jpg",
  //     ],
  //     type: "BOARDGAME",
  //   },
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/vPIg0iAW4000UjmKuWzCOw__imagepage/img/MlgMQp083lZPtoHN54aZ6CN2h7E=/fit-in/900x600/filters:no_upscale():strip_icc()/pic2034401.jpg",
  //       "https://cf.geekdo-images.com/hP779f_Y5KWgQ8tqXkisFA__imagepage/img/FATeCzTar5cwYwPPrHEXMDq_qJg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic2033892.jpg",
  //       "https://cf.geekdo-images.com/9qURnbv8xkNgWa1n_oD5AQ__imagepage/img/ViDDPeLRM1P-yWFmZwEnz5zEb5k=/fit-in/900x600/filters:no_upscale():strip_icc()/pic53087.jpg",
  //       "https://cf.geekdo-images.com/b7IeTmGDbfQsogyQIETvGw__imagepage/img/VfAlEZWB3Ig0_TiEbDD9-XiHk0k=/fit-in/900x600/filters:no_upscale():strip_icc()/pic43019.jpg",
  //     ],
  //     type: "BOARDGAME",
  //   },
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/iO0mGcLYuK0vbQTBd9ifZg__imagepage/img/XGmGNSvsyXxpsN5rAIf1-dvUerc=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7688227.jpg",
  //       "https://cf.geekdo-images.com/kHqMqgeN6NMe4NiVXBTVYQ__imagepage/img/7v-W6CzeUdedtr1Cus6mK7SAFac=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7688228.jpg",
  //       "https://cf.geekdo-images.com/L7HvoEZY5rzuMG9EAzEa3A__imagepage/img/dbCiUiFLp8jM-gpuw2MLqIvmo_w=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7709162.jpg",
  //       "https://cf.geekdo-images.com/L7HvoEZY5rzuMG9EAzEa3A__imagepage/img/dbCiUiFLp8jM-gpuw2MLqIvmo_w=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7709162.jpg",
  //     ],
  //     type: "BOARDGAME",
  //   },
    
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/kHqMqgeN6NMe4NiVXBTVYQ__imagepage/img/7v-W6CzeUdedtr1Cus6mK7SAFac=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7688228.jpg",
  //       "https://cf.geekdo-images.com/L7HvoEZY5rzuMG9EAzEa3A__imagepage/img/dbCiUiFLp8jM-gpuw2MLqIvmo_w=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7709162.jpg",
  //       "https://cf.geekdo-images.com/L7HvoEZY5rzuMG9EAzEa3A__imagepage/img/dbCiUiFLp8jM-gpuw2MLqIvmo_w=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7709162.jpg",
  //       "https://cf.geekdo-images.com/iO0mGcLYuK0vbQTBd9ifZg__imagepage/img/XGmGNSvsyXxpsN5rAIf1-dvUerc=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7688227.jpg",
        
  //     ],
  //     type: "POOL",
  //   },
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/hP779f_Y5KWgQ8tqXkisFA__imagepage/img/FATeCzTar5cwYwPPrHEXMDq_qJg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic2033892.jpg",
  //       "https://cf.geekdo-images.com/9qURnbv8xkNgWa1n_oD5AQ__imagepage/img/ViDDPeLRM1P-yWFmZwEnz5zEb5k=/fit-in/900x600/filters:no_upscale():strip_icc()/pic53087.jpg",
  //       "https://cf.geekdo-images.com/b7IeTmGDbfQsogyQIETvGw__imagepage/img/VfAlEZWB3Ig0_TiEbDD9-XiHk0k=/fit-in/900x600/filters:no_upscale():strip_icc()/pic43019.jpg",
  //       "https://cf.geekdo-images.com/vPIg0iAW4000UjmKuWzCOw__imagepage/img/MlgMQp083lZPtoHN54aZ6CN2h7E=/fit-in/900x600/filters:no_upscale():strip_icc()/pic2034401.jpg",
        
  //     ],
  //     type: "POOL",
  //   },
    
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/Pwk9Jl9Wu4I0DPNuMrrZrQ__imagepage/img/ABBUz_ktv-OM_nIiXNu5A_gUIrg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1916682.jpg",
  //       "https://cf.geekdo-images.com/bl7v4Ue-DupavwfO9HuFQg__imagepage/img/6kgAIe2tSaFM2HPhcpecmgvS8fM=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1937381.jpg",
  //       "https://cf.geekdo-images.com/AwjCc6N937LNOhB_NxGsFQ__imagepage/img/fGNIs77HWqhGxfBKOgdadPf_UVo=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1937382.jpg",
  //       "https://cf.geekdo-images.com/Pwk9Jl9Wu4I0DPNuMrrZrQ__imagepage/img/ABBUz_ktv-OM_nIiXNu5A_gUIrg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1916682.jpg",
        
  //     ],
  //     type: "POOL",
  //   },
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/bl7v4Ue-DupavwfO9HuFQg__imagepage/img/6kgAIe2tSaFM2HPhcpecmgvS8fM=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1937381.jpg",
  //       "https://cf.geekdo-images.com/AwjCc6N937LNOhB_NxGsFQ__imagepage/img/fGNIs77HWqhGxfBKOgdadPf_UVo=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1937382.jpg",
  //       "https://cf.geekdo-images.com/Pwk9Jl9Wu4I0DPNuMrrZrQ__imagepage/img/ABBUz_ktv-OM_nIiXNu5A_gUIrg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1916682.jpg",
  //       "https://cf.geekdo-images.com/Pwk9Jl9Wu4I0DPNuMrrZrQ__imagepage/img/ABBUz_ktv-OM_nIiXNu5A_gUIrg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1916682.jpg",
       
  //     ],
  //     type: "BOOK",
  //   },
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/vPIg0iAW4000UjmKuWzCOw__imagepage/img/MlgMQp083lZPtoHN54aZ6CN2h7E=/fit-in/900x600/filters:no_upscale():strip_icc()/pic2034401.jpg",
  //       "https://cf.geekdo-images.com/hP779f_Y5KWgQ8tqXkisFA__imagepage/img/FATeCzTar5cwYwPPrHEXMDq_qJg=/fit-in/900x600/filters:no_upscale():strip_icc()/pic2033892.jpg",
  //       "https://cf.geekdo-images.com/9qURnbv8xkNgWa1n_oD5AQ__imagepage/img/ViDDPeLRM1P-yWFmZwEnz5zEb5k=/fit-in/900x600/filters:no_upscale():strip_icc()/pic53087.jpg",
  //       "https://cf.geekdo-images.com/b7IeTmGDbfQsogyQIETvGw__imagepage/img/VfAlEZWB3Ig0_TiEbDD9-XiHk0k=/fit-in/900x600/filters:no_upscale():strip_icc()/pic43019.jpg",
       
  //     ],
  //     type: "BOOK",
  //   },
  //   {
  //     images: [
  //       "https://cf.geekdo-images.com/L7HvoEZY5rzuMG9EAzEa3A__imagepage/img/dbCiUiFLp8jM-gpuw2MLqIvmo_w=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7709162.jpg",
  //       "https://cf.geekdo-images.com/L7HvoEZY5rzuMG9EAzEa3A__imagepage/img/dbCiUiFLp8jM-gpuw2MLqIvmo_w=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7709162.jpg",
  //       "https://cf.geekdo-images.com/iO0mGcLYuK0vbQTBd9ifZg__imagepage/img/XGmGNSvsyXxpsN5rAIf1-dvUerc=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7688227.jpg",
  //       "https://cf.geekdo-images.com/kHqMqgeN6NMe4NiVXBTVYQ__imagepage/img/7v-W6CzeUdedtr1Cus6mK7SAFac=/fit-in/900x600/filters:no_upscale():strip_icc()/pic7688228.jpg",
       
  //     ],
  //     type: "BOOK",
  //   },
    
  // ];
  // const data = props.data
  const types = (new Set(data.map(elem=>elem.type))) 

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div>    
          <div className="mt-6 flex justify-center w-full">
            {[...types].map(elem=>{
               <button
              onClick={()=>{
                setActive(elem)
              }}
              style={{clipPath:"polygon(0 0, 100% 0%, 87% 100%, 0% 100%)"}}
              className={
                active === elem
                  ? "flex items-center  w-[120px] justify-center rounded-tl-md rounded-bl-md bg-white py-2     font-medium text-teal-400 me-2  hover:text-teal-400"
                  : "flex items-center  w-[120px] justify-center rounded-tl-md rounded-bl-md bg-white   py-2  font-medium text-gray-123 me-2  hover:text-teal-400"
              }
            >
              {" "}
              {/* <GiMeeple className="me-2" /> */}
              <span style={{textTransform:'lowercase'}} className="first-letter:capitalize">{elem}</span>
            </button>
            })}
          </div>  
          <div className="mt-8 grid lg:grid-cols-4 gap-4 grid-cols-2 md:grid-cols-2">
            {data
              // .filter((elem) => elem.type === active)
              .map((element, index) => {
                return (
                  <div key={index} className="w-full">
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      //ssr={true} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={2500}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass=""
                      // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      //deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {element.images.map((elem, index) => {
                        return (
                          <div key={index}>
                            <div
                            style={{
                              backgroundImage:`url('${elem}')`
                            }}
                            className="h-40 md:h-60 lg:h-80 w-full rounded-xl bg-cover bg-center bg-no-repeat"
                            />
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                );
              })}
          </div>
        </div>
  );
};

export default Gallery;
