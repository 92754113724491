import { createSlice } from "@reduxjs/toolkit";

export const boardgameSlice = createSlice({
  name: "boardgames",
  initialState: { value: {
    data:[],
    } 
  },
  reducers: {
    updateData: (state, action) => {
      state.value.data=action.payload.data
    },
  },
});

export const { updateData } = boardgameSlice.actions;
export default boardgameSlice.reducer;