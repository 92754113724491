import React from "react";
import PrimaryHeading from "components/heading/PrimaryHeading";
import SecondaryHeading from "components/heading/SecondaryHeading";
// import AddReservation from "./componets/addReservation";
import TableReservation from "./components/tableReservations";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import { IoMdEye } from "react-icons/io";

const AdminReservation = () => {
  const [data, setData] = React.useState({});
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [id, setId] = React.useState("");

  const [status, setStatus] = React.useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .patch("/reservation/updateStatus/" + id,{
        status:status
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'Reservation for customer "' + res.data.name + '" is accepted Successfully!'
          );
          setId("")
          setStatus("")
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("Something went Wrong!");
          console.log(err)
        }
      });
    setIsLoading(false);
  };

  const deleteFunc = (id) => {
    var confirm = window.confirm(
      "Do you want to delete #" + id + " Reservation ?"
    );
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/reservation/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("Reservation is deleted Successfully!");
            // setData(data.filter((res) => res._id !== id));
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    toast.info("Finding Reservation in database");
    setIsLoading(true);
    setId(id)
    axiosInstance
      .get("/reservation/" + id)
      .then((res) => {
        if (res.data) {
          setData(res.data);
          setStatus(res.data.reservationData.status)
          toast.success("Reservation founded in database");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error(err.response.data.errors.msg);
        }
        setIsLoading(false);
      });
    onOpen();
    // const discountData = data.find((elem) => elem._id === id);
    // const { _id, name, price,qty,category } = discountData;
    // setname(name);
    // setprice(price);
    // setType(category);
    // setQty(qty)
    // setid(_id);
    // onOpen();
  };

  const formatDate = (givenDate) => {
    const gDate = new Date(givenDate);
    const day = gDate.getDate().toString().padStart(2, "0");
    const month = (gDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
    const year = gDate.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  // if (isLoading === true) {
  //   return (
  //     <div className="flex h-screen flex-col items-center justify-center">
  //       <div className="flex items-center justify-center">
  //         <Loading />
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div>
      <PrimaryHeading title={"Table Bookings"} />
      <TableReservation
        deleteFunc={deleteFunc}
        tableTitle={"Table Bookings"}
        tableKeys={[ "timeSlot", "status","name", "email", "guests",]}
        editFunc={editFunc}
        tableId={"#table2"}
        tableIdd={"table2"}
        url={"/reservation/allbyQuery"}
      />
      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[20vh]">
          <ModalBody>
            <div className=" rounded-lg border bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}
              {isLoading ? (
                <>
                  <div className="flex h-screen flex-col items-center justify-center">
                    <div className="flex items-center justify-center">
                      <Loading />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <form
                    onSubmit={(e) => {
                      handleEdit(e);
                    }}
                  >
                    <label
                      htmlFor="Type"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Do you want to accept this Reservation Request ?
                    </label>
                    {data.eventData?.imageSrc && (
                      <>
                        <div className="dark:bg-neutral-700 mb-4  flex w-full space-x-3 rounded-xl border bg-white p-3">
                          <img
                            className="h-52 w-52 rounded-xl object-cover "
                            src={data?.eventData?.imageSrc}
                            alt=""
                          />
                          <div className="flex h-full w-full flex-col justify-between">
                            <p className="text-neutral-500 dark:text-neutral-300 mb-1 text-xs">
                              {data.eventData?.end
                                ? formatDate(data.eventData?.end) ==
                                  formatDate(data.eventData?.start)
                                  ? `${formatDate(data.eventData?.start)}`
                                  : `${formatDate(
                                      data.eventData?.start
                                    )} - ${formatDate(data.eventData?.end)}`
                                : formatDate(data.eventData?.start)}
                            </p>
                            <h5 className="text-neutral-800 dark:text-neutral-50 mb-2 text-xl font-medium">
                              {data.eventData?.title}
                            </h5>
                            <p className="text-neutral-600 dark:text-neutral-200 mb-2 text-base">
                              {data.eventData?.desc}
                            </p>
                            <p className="text-neutral-500 dark:text-neutral-300 text-xs">
                              {data.eventData?.time}
                            </p>
                            <div className="">
                              <p className="text-neutral-600 dark:text-neutral-200 text-sm text-gray-800">
                                <div className="text-red-400">
                                  {data.eventData?.guests} are tickets left
                                </div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <label
                      htmlFor="Type"
                      className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Status
                    </label>
                    <select
                      id="Type"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                      className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    >
                      <option selected>Choose</option>
                      <option value="PENDING">Pending</option>
                      <option value="REJECTED">Reject</option>
                      <option value="ACCEPTED">Accept</option>
                    </select>

                    {isLoading ? (
                      <button
                        disabled
                        className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        <Loading />
                      </button>
                    ) : (
                      <button
                        type={"submit"}
                        className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                      >
                        Save Changes
                      </button>
                    )}
                    <button
                      type="button"
                      onClick={onClose}
                      className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Close
                    </button>
                    {data.reservationData?.status === 'ACCEPTED' && 
                    <button
                      type="button"
                      className="linear ml-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      View
                    </button>
                    }
                  </form>
                </>
              )}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AdminReservation;
