import React, { useState } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../../axiosInstance";
import { toast } from "react-toastify";
import DevelopmentTable from "views/admin/tables/components/DevelopmentTable";
import MainTable from "components/table";
import TableLibrary from "./tableLibrary";
import { useSelector } from "react-redux";

const TableWrapperLibrary = (props) => {
  const [data, setData] = React.useState({});
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  //   tableId={"#table2"}
  //             tableIdd={"table2"}
  const refresh = useSelector((state) => state.refresher.value.refreshCount);
  console.log(refresh);
  const { url, tableKeys, editFunc, deleteFunc, tableId, tableIdd } = props;
  console.log(props);
  console.log([
    {
      Header: "ACTION",
      accessor: "_id",
    },
    ...tableKeys.map((elem) => ({
      Header: elem,
      accessor: elem,
    })),
  ]);
  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`${url}?limit=${limit}&page=${page}&sort=_id`)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading(false);
      });
  }, [limit, page, refresh]);

  React.useEffect(() => {
    setIsLoading2(true);
    axiosInstance
      .get(`${url}?limit=${limit}&page=${page}&sort=_id`)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data);
        setIsLoading2(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading2(false);
      });
  }, []);

  return (
    <div className="rounded-primary  bg-white  dark:border-navy-900 dark:bg-navy-800">
      {isLoading ? (
        <>
          <div className="flex h-screen flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          </div>
        </>
      ) : (
        <>
          {data?.docs?.length != 0 && (
            <TableLibrary
              tableId={tableId}
              tableIdd={tableIdd}
              tableKeys={[...tableKeys, "_id"]}
              tableData={data?.docs || []}
              editFunc={editFunc}
              deleteFunc={deleteFunc}
            />
          )}
        </>
      )}
      <div className="flex w-full items-center justify-between space-x-4 p-4">
        <div>Total : {data.totalDocs}</div>
        <div className="flex text-white">
          {data?.hasPrevPage && (
            <div
              onClick={() => {
                setPage(page - 1);
              }}
              className="mr-1 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-chevron-left h-6 w-6"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </div>
          )}
          <div className="flex h-12 rounded-full border bg-white font-medium text-gray-800">
            {data.prevPage !== null && (
              <div
                onClick={() => {
                  setPage(data.prevPage);
                }}
                className="hidden w-12 cursor-pointer items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
              >
                {data.prevPage}
              </div>
            )}
            <div className="hidden w-12 cursor-pointer items-center justify-center  rounded-full border bg-gray-50 leading-5 transition  duration-150 ease-in md:flex ">
              {data.page}
            </div>
            {data.nextPage !== null && (
              <div
                onClick={() => {
                  setPage(data.nextPage);
                }}
                className="hidden w-12 cursor-pointer  items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
              >
                {data.nextPage}
              </div>
            )}
          </div>
          {data.hasNextPage && (
            <div
              onClick={() => {
                setPage(page + 1);
              }}
              className="ml-1 mr-4 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full border bg-white text-gray-800"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-chevron-right h-6 w-6"
              >
                <polyline points="9 18 15 12 9 6"></polyline>
              </svg>
            </div>
          )}
          <select
            value={limit}
            className="rounded-xl border bg-white p-1 text-gray-800 hover:outline-none active:outline-none"
            onChange={(e) => setLimit(e.target.value)}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={150}>150</option>
            <option value={data.totalDocs}>All</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default TableWrapperLibrary;
