// import React from "react";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import { Link } from "react-router-dom";

// const TopBook = (props) => {
//   const data = props.data;
//   console.log(data);
//   const responsive = {
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 4,
//       slidesToSlide: 3, // optional, default to 1.
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2,
//       slidesToSlide: 2, // optional, default to 1.
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1,
//       slidesToSlide: 1, // optional, default to 1.
//     },
//   };

//   return (
//     <div className="mt-28 md-max:mt-11 md-max:p-5 flex w-full justify-center">
//       <div className="container ">
//         <h1 className="text-inherit block font-festive  text-3xl md:text-4xl lg:text-6xl font-semibold leading-tight tracking-normal text-navy-800 antialiased dark:text-white">
//           Top Books
//         </h1>
//         <div className="mt-8  w-full">
//           <Carousel
//             swipeable={true}
//             draggable={false}
//             showDots={false}
//             responsive={responsive}
//             //ssr={true} // means to render carousel on server-side.
//             infinite={false}
//             autoPlay={false}
//             autoPlaySpeed={1000}
//             keyBoardControl={true}
//             customTransition="all .5"
//             transitionDuration={500}
//             containerClass=""
//             removeArrowOnDeviceType={[]}
//             //deviceType={this.props.deviceType}
//             dotListClass="custom-dot-list-style"
//             itemClass="carousel-item-padding-40-px"
//           >
//             {data.map((elem, index) => {
//               return (
//                 <Link to={"/book/" + elem._id}>
//                   <div className="relative md:w-[310px]  rounded-xl bg-[black] ">
//                     <div className="rounded-xl  hover:opacity-80">
//                       <div
//                         style={{ clipPath: "polygon(0 0, 0 100%, 100% 0)" }}
//                         className="absolute  h-14 w-16 rounded-tl-xl bg-yellow-400 pl-3 pt-1 font-festive text-xl font-semibold text-white"
//                       >
//                         #{index + 1}
//                       </div>

//                       <img
//                         className="h-[410px] w-full   rounded-xl"
//                         src={elem.bookImage}
//                       />
//                     </div>
//                   </div>
//                 </Link>
//               );
//             })}
//           </Carousel>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TopBook;
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const TopBook = (props) => {
  const data = props.data;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="w-full">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        //ssr={true} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        removeArrowOnDeviceType={[]}
        //deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        <div className="grid grid-cols-3 gap-5">
          {data.slice(0, 6).map((elem, index) => {
            return (
              <Link key={elem._id} to={"/books/view/" + elem._id}>
                <div className={"  w-full rounded-xl bg-[black]"}>
                  <div className=" rounded-xl  hover:opacity-80">
                    <img
                      className="md:h-60 h-40 w-full rounded-xl"
                      src={elem.bookImage}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="grid grid-cols-3 gap-5">
          {data.slice(6, 12).map((elem, index) => {
            return (
              <Link key={elem._id} to={"/books/view/" + elem._id}>
                <div className={"  w-full rounded-xl bg-[black]"}>
                  <div className=" rounded-xl  hover:opacity-80">
                    <img
                      className="md:h-60 h-40 w-full rounded-xl"
                      src={elem.bookImage}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </Carousel>
    </div>
  );
};

export default TopBook;
