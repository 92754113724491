import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import { updateRefresher } from "features/refresher";

const AddProduct = () => {
  const [isLoading, setIsLoading] = useState(false);

  //refresher setup
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);
 
  //States for price
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [type, setType] = useState("");
  const [qty,setQty] = useState("");
  
  //errors for price
  const [nameMessage, setnameMessage] = useState("");
  const [nameState, setnameState] = useState();

  const [priceMessage, setpriceMessage] = useState("");
  const [priceState, setpriceState] = useState();

  const [qtyMessage, setQtyMessage] = useState("");
  const [qtyState,setQtyState] = useState("")   

  //validation logic
  useEffect(() => {
    if (name === "") {
      setnameMessage("Name cannot be empty!");
      setnameState();
    } else {
      setnameMessage("");
      setnameState("success");
    }

    if (price === "") {
      setpriceMessage("");
      setpriceState();
    } else if (price < 0) {
      setpriceMessage("Price must be a Positive Integer!");
      setpriceState("error");
    } else {
      setpriceMessage("");
      setpriceState("success");
    }

    if(qty === ""){
        setQtyMessage("");
        setQtyState();
      } else if (qty < 0) {
        setQtyMessage("Qty must be a Positive Integer!");
        setQtyState("error");
      } else {
        setQtyMessage("");
        setQtyState("success");
          
    }
  }, [name, price,qty]);

  //importing axios

  const axiosInstance = useAxiosInstance();

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post("/product", {
        name: name,
        price: price,
        category:type,
        qty:qty            
      })
      .then((res) => {
        if (res.data) {
          toast.success(`${res.data.name} Product created Successfully`);
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };

  return (
    <div className="rounded-primary  bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
      {/* <img src={navlogo} height={100}/> */}
      {/* name */}
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        {nameMessage && (
          <span className="text-red-400 ms-1">{nameMessage}</span>
        )}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Name"
          placeholder="Product Name"
          id="name"
          type="name"
          state={nameState}
          value={name}
          onChange={(e) => {
            setname(e.target.value);
          }}
        />

        {/* Price */}

        {priceMessage && (
          <span className="text-sm text-red-500 ms-1">{priceMessage}</span>
        )}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Price"
          placeholder="Price"
          id="price"
          type="Number"
          state={priceState}
          value={price}
          onChange={(e) => {
            setprice(e.target.value);
          }}
        />

        {/* type */}

        <label
          htmlFor="Type"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        >
          Type
        </label>
        <select
          id="Type"
          value={type}
          onChange={(e)=>{setType(e.target.value)}}
          className="mt-2 flex h-12 w-full items-center dark:text-gray-400 dark:border-gray-800 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
        >
          <option selected>Choose</option>
          <option value="FNB">Food and Bevrages</option>
          <option value="GAMING">Gaming</option>
        </select>

        {type === 'FNB'&& <div>{qtyMessage && (
          <span className="text-sm text-red-500 ms-1">{qtyMessage}</span>
        )}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Quantity"
          placeholder="Quantity"
          id="price"
          type="Number"
          state={qtyState}
          value={qty}
          onChange={(e) => {
            setQty(e.target.value);
          }}
        /></div>}

        {isLoading ? (
          <button
            disabled
            className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <Loading />
          </button>
        ) : (
          nameMessage === "" &&
          priceMessage === "" && (
            <button
              type={"submit"}
              className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add
            </button>
          )
        )}
      </form>
    </div>
  );
};

export default AddProduct;
