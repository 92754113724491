import React, { useState } from "react";
import PrimaryHeading from "components/heading/PrimaryHeading";
import SecondaryHeading from "components/heading/SecondaryHeading";
import AddBoardGame from "./componets/addBoardGame";
import TableBoardGame from "./componets/tableBoardGame";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SelectField from "components/fields/selectField";

const BoardGame = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);

  // React.useEffect(() => {
  //   setIsLoading(true);
  //   axiosInstance
  //     .get("/boardgames/all")
  //     .then((res) => {
  //       setData(res.data);
  //       //toast.success('Data loaded Successfully!')
  //       //console.log(res.data)

  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       toast.error("Something went Wrong!");

  //       setIsLoading(false);
  //     });
  // }, []);
  const [isLoading2, setIsLoading2] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const history = useNavigate();
  React.useEffect(() => {
    setIsLoading2(true);
    axiosInstance
      .get(`/boardgames/allCatalog?limit=1&page=1`)
      .then((res) => {
        setIsLoading2(false);
        // dispatch(updateData({ data: res.data.filtedData }));
        setSearchData(res.data.searchData);
      })
      .catch((err) => {
        setIsLoading2(false);
        toast.error("Something went Wrong!");
      });
  }, []);
  if (isLoading === true) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <PrimaryHeading title={"Add Board Game"} />
      <AddBoardGame />
      <div className="w-full">
        <SelectField
          
          placeHolder={"Search Board Game"}
          isMulti={false}
          extra={"w-full border bg-white py-3 px-2 rounded-xl"}
          options={[
            ...searchData.map((elem) => ({
              label: elem.bgName,
              value: elem._id,
            })),
          ]}
          onChange={(e) => {
            history("/boardgames/view/" + e.value);
          }}
        />
      </div>
      <SecondaryHeading title={"Board Games"} />
      <TableBoardGame />
    </div>
  );
};

export default BoardGame;
