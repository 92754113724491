import InputField from "components/fields/InputField";
import UserLayout from "layouts/user";
import React from "react";
import { BsGear, BsGearFill } from "react-icons/bs";
import {
  FaCalendar,
  FaCompass,
  FaHeart,
  FaHourglass,
  FaInfoCircle,
  FaShare,
  FaUser,
  FaUserClock,
  FaUserFriends,
} from "react-icons/fa";
import {
  GiBigGear,
  GiBookCover,
  GiCrown,
  GiGears,
  GiPlayerTime,
  GiRank1,
} from "react-icons/gi";
import { HiUserGroup, HiX, HiXCircle } from "react-icons/hi";
import { MdBookmark, MdCall, MdReply, MdStar, MdThumbUp } from "react-icons/md";
import Rating from "react-rating";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";

import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import { WhatsappShareButton } from "react-share";
import { Helmet } from "react-helmet";

const BoardgameDisplay = () => {
  const [data, setData] = React.useState({
    "bgName": "Splendor: Marvel",
    "bgArtist": "N/A",
    "bgLongDescription": "This classic game returns in a new version! Rediscover Splendor through the Marvel Universe!\r\n \r\nRules as simple and smooth as the original Splendor, with a distinct allocation of colors to provide a different experience of play.\r\n​\r\nEach turn is quick: one, and only one, action!\r\n \r\nDiversify the tactics with the Avengers tile that passes from hand to hand and discover a new game end: get hold of the Infinity Gauntlet!",
    "bgImage": "https://cf.geekdo-images.com/2thZjcw0ZvrsP09PDz002Q__imagepage/img/AZt6VUM0NVl4MQNM7c9azD3qV_U=/fit-in/900x600/filters:no_upscale():strip_icc()/pic5585867.jpg",
    "bgPlayers": "2-4",
    "bgAge": "10+",
    "bgPlayingTime": "30 min",
    "bgCategory": "Engine Builder",
    "bgDesigner": "Marc André",
    "bgPublisher": "Space Cowboys",
    "bgComplexity": "Medium",
    "bgRating": 0,
    "bgType": "",
    "bgMech": "",
    "bgPlayedBestAt": "2,3",
    "bgLabels": "",
    "bgOfficialLink": "",
    "bgAwards": "",
    "bgBuy": "",
    "bgVideo": "https://www.youtube.com/embed/0t1A97Om3Fw",
    "bgWTP": null,
    "bgBGGRank": 753,
    "bgBGGScore": 7.7,
    "bgHomepage": "",
    "bgYear": 2020,
    "bgTimesPlayed": 0,
    "bgAvailable": "YES",
    "bgTimesRented": 0,
    "_id": "63105075d28c7bda5665a839",
    "_id/$oid": "62fcde93aff3857bff492a61",
    "bgImage2": "",
    "bgImage3": "",
    "bgShortDescription": "",
    "bgSerialNumber": null,
    "__v": "",
    "reviews": [
        {
            "_id": "651a014ac87d1a560c527abf",
            "name": "User2",
            "review": "I didn't enjoy Splendor as much as I thought I would. While the components and artwork are beautiful, I found the gameplay to be a bit repetitive. It's not my favorite game, but it's still decent.",
            "stars": 3,
            "likes": 6,
            "replies": [
                {
                    "_id": "651a11fab79ff95968e04040",
                    "replyTo": "User4",
                    "name": "Admin",
                    "likes": 4,
                    "replyMessage": "We're delighted to hear that Splendor has been such a positive experience for you. Thanks for the recommendation!"
                },
                {
                    "_id": "651ab79e294e255da0b08e25",
                    "name": "user1",
                    "replyMessage": "Ya but I think it deserve atleast 4 stars.",
                    "replyTo": "User2",
                    "likes": 1
                }
            ]
        },
        {
            "_id": "651a0184c87d1a560c527ac3",
            "name": "User5",
            "review": "Splendor is a classic that I return to time and time again. The simplicity of its rules, combined with the depth of strategy, make it a game that's easy to teach but hard to master. A must-have for any board game collection.",
            "stars": 5,
            "likes": 4,
            "replies": []
        },
        {
            "_id": "651a1f9213e71205d0f09943",
            "name": "Rahul",
            "review": "A must try Game. Loved playing it.",
            "stars": 5,
            "likes": 1,
            "replies": [
                {
                    "_id": "651a2db413e71205d0f09966",
                    "name": "Meepleton",
                    "replyMessage": "Thanks For Visiting Us.",
                    "replyTo": "Rahul",
                    "likes": 0
                },
                {
                    "_id": "651aa315b0e7964d7847a9a4",
                    "name": "user3",
                    "replyMessage": "ya Man totally agreed!",
                    "replyTo": "Rahul",
                    "likes": 0
                }
            ]
        }
    ],
    "updatedAt": "2023-10-02T12:32:35.257Z",
    "recommendations": [
        {
            "bgName": "Splendor: Marvel",
            "bgImage": "https://cf.geekdo-images.com/2thZjcw0ZvrsP09PDz002Q__imagepage/img/AZt6VUM0NVl4MQNM7c9azD3qV_U=/fit-in/900x600/filters:no_upscale():strip_icc()/pic5585867.jpg",
            "_id": "63105075d28c7bda5665a839"
        }
    ]
});
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [rating, setRating] = React.useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [review, setReview] = React.useState("");
  const [name, setName] = React.useState("");
  const [stars, setStars] = React.useState(0);

  const [replyTo, setReplyTo] = React.useState("");
  const [reviewId, setReviewId] = React.useState("");
  const [replyMessage, setreplyMessage] = React.useState("");

  const [refresh,setRefresh] = React.useState(0);

  const history = useNavigate();

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/boardgames/` + window.location.href.split("/").pop())
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data);
        // console.log(res.data)
        let rating = 0;
        res.data.reviews.forEach((elem) => {
          rating += Number(elem.stars);
        });
        // console.log(res.data);
        setRating((rating / res.data.reviews.length).toFixed(1));
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading(false);
      });
  }, [window.location.href]);

  React.useEffect(() => {
    axiosInstance
      .get(`/boardgames/` + window.location.href.split("/").pop())
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data);
        // console.log(res.data)
        let rating = 0;
        res.data.reviews.forEach((elem) => {
          rating += Number(elem.stars);
        });
        // console.log(res.data);
        setRating((rating / res.data.reviews.length).toFixed(1));
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

      });
  }, [refresh]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
  };

  const handleReview = () => {
    axiosInstance
      .patch(
        `/boardgames/createReview/` + window.location.href.split("/").pop(),
        {
          reviews: {
            name: name,
            review: review,
            stars: stars,
            likes: 0,
          },
        }
      )
      .then((res) => {
        setRefresh(refresh+1);
        setName("");
        setReview("");
        setStars(0);
        toast.success("Thanks for Rating " + res.data.bgName);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
      });
  };

  const handleLikeReview = (reviewId) => {
    axiosInstance
      .patch(
        `/boardgames/likeReview/` + window.location.href.split("/").pop(),
        {
          reviewId: reviewId,
        }
      )
      .then((res) => {
        setRefresh(refresh+1);
        toast.success("Thanks for Liking!");
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
      });
  };

  const handleReply = () => {
    axiosInstance
      .patch(
        `/boardgames/createReply/` + window.location.href.split("/").pop(),
        {
          reviewId: reviewId,
          reply: {
            name: name,
            replyMessage: replyMessage,
            replyTo: replyTo,
            likes: 0,
          },
        }
      )
      .then((res) => {
        setRefresh(refresh+1);
        onClose();
        setReviewId("");
        setReplyTo("");
        setName("");
        setreplyMessage("");
        toast.success("Thanks for Rating " + res.data.bookName);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
      });
  };

  const handleLikeReply = (reviewId, replyId) => {
    axiosInstance
      .patch(`/boardgames/likeReply/` + window.location.href.split("/").pop(), {
        reviewId: reviewId,
        replyId: replyId,
      })
      .then((res) => {
        setRefresh(refresh+1);
        toast.success("Thanks for Liking ");
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
      });
  };

  const handleWTP = (like) =>{
    axiosInstance
      .patch(`/boardgames/like/` + window.location.href.split("/").pop(), {
        likes: like
      })
      .then((res) => {
        setRefresh(refresh+1);
        toast.success("Thanks for showing you love for " +res.data.bgName);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
      });
  };

  return (
    <UserLayout>
      <Helmet>
        <title>{data.bgName} - Meepleton Board Game Cafe</title>
        <meta name="description" content={data.bgLongDescription}/>
      </Helmet>
      {isLoading ? (
        <>
          <div className="flex h-screen flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          </div>
        </>
      ) : (
        <div className="flex w-full bg-gray-50 justify-center pt-24 md:pt-28 lg:pt-28 pb-8 md:pb-12 md-max:px-5">
          <div className="container ">
            {/* grid grid-cols-1 gap-5 md:grid-cols-2 */}
            <div className="w-full items-center  md:flex md:space-x-5">
              <div className="flex h-[360px] flex-col items-center justify-center md:h-[380px] lg:h-[450px] md:w-40p">
                <img src={data.bgImage} className="h-full w-full rounded-xl" />
              </div>
              <div className="h-full sm-max:mt-5 mt-0 w-full md:flex md:w-60p md:flex-col md:justify-between">
                <div className="flex space-x-4 rounded-xl bg-navy-900 p-4">
                  <div className="flex w-20p max-h-28 flex-col justify-center rounded-xl bg-red-600 p-3 md:p-4">
                    <div className="text-center text-3xl font-semibold text-white md:text-4xl">
                      {data.bgBGGScore}
                    </div>
                    <div className="text-center text-[10px] text-white md:text-sm md:font-medium">
                      BGG Score
                    </div>
                  </div>
                  <div className="w-full text-white">
                    <div className="w-full border-b-2 border-dotted pb-1 text-2xl md:text-3xl font-semibold">
                      {data.bgName}
                    </div>
                    <div className="mt-2 flex justify-between">
                      <div className="text-center">
                        <div className="flex items-center  text-xs font-semibold md:text-base">
                          <GiCrown className="md:me-1 mr-1" /> BGG Rank
                        </div>
                        {data.bgBGGRank}
                      </div>

                      <div className="text-center">
                        <div className="flex items-center text-xs font-semibold md:text-base">
                          <FaHeart className="md:me-1 mr-1" /> Likes
                        </div>
                        {data.bgWTP || 0}
                      </div>

                      <div className="text-center">
                        <div className="flex items-center text-xs font-semibold md:text-base">
                          <BsGearFill q className="md:me-1 mr-1" /> Times Played
                        </div>
                        {data.bgTimesPlayed}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 w-full rounded-xl bg-gray-300 border text-center text-base md:text-lg font-normal text-navy-700">
                  <div className="flex border-b-2 border-dotted border-white  ">
                    <div className="w-50p border-r-2 border-dotted border-white   p-2">
                      <div className="flex items-center justify-center text-sm font-semibold md:text-base">
                        <FaUserFriends className="me-1" />
                        Players
                      </div>
                      {data.bgPlayers}
                    </div>

                    <div className="w-50p p-2 ">
                      <div className="flex items-center justify-center text-sm font-semibold md:text-base">
                        <FaUserClock className="me-1" />
                        Age
                      </div>
                      {data.bgAge}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="w-50p border-r-2 border-dotted border-white   p-2 ">
                      <div className="flex items-center justify-center text-sm font-semibold md:text-base">
                        <FaCompass className="me-1" />
                        Complexity
                      </div>
                      {data.bgComplexity}
                    </div>

                    <div className="w-50p p-2">
                      <div className="flex items-center justify-center text-sm font-semibold md:text-base">
                        <FaHourglass className="me-1" />
                        Playing Time
                      </div>
                      {data.bgPlayingTime}
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex w-full justify-between space-x-2">
                  <button onClick={()=>handleWTP((data.bgWTP || 0)+1)} className="flex w-full items-center justify-center rounded-xl bg-amber-500 px-3 py-2 text-base md:py-2 md:px-5 md:text-lg  font-medium text-white transition duration-200 hover:bg-amber-600 active:bg-amber-700 dark:bg-amber-400 dark:text-white dark:hover:bg-amber-300 dark:active:bg-amber-200">
                    <FaHeart className="me-2" />
                    Like
                  </button>
                  <WhatsappShareButton
                  className="flex w-full"
                  url={'https://meepleton.in'}
                  title={`Hello ,\n\nYour friend is inviting you to play\n${data.bgName} at Meepleton\n\Link ${window.location.href}\n\nHave a nice day!`}
                  separator=","  
                 >
                  <button className="flex w-full h-full items-center justify-center rounded-xl bg-gray-400 px-3 py-2 text-base md:py-2 md:px-5 md:text-lg font-medium text-white transition duration-200 hover:bg-gray-400 active:bg-gray-400 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-100 dark:active:bg-gray-400">
                    <FaShare className="me-2" />
                    Invite
                  </button>
                  </WhatsappShareButton>
              
                  {/* <button className="rounded-xl w-full flex justify-center items-center bg-teal-500 px-3 py-2 text-base md:py-2 md:px-5 md:text-lg font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200">
                <MdCall className="me-2" />Call us
              </button> */}
                  <button className="flex w-full items-center justify-center rounded-xl bg-teal-500 px-3 py-2 text-base md:py-2 md:px-5 md:text-lg font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200">
                    <MdBookmark className="me-2" />
                    Reserve
                  </button>
                </div>
                <div className="mt-5 w-full rounded-xl text-sm md:text-base bg-white border p-4 text-gray-800 md:flex md:space-x-4">
                  <div className="w-full md:w-50p">
                    <div className="mb-2 flex justify-between">
                      <div className="flex md-max:w-50p items-center text-sm font-semibold md:text-base">
                        <GiBigGear className="me-1" /> Category
                      </div>
                      <span className="md-max:text-right">
                      {data.bgCategory}
                      </span>
                    </div>
                    <div className="mb-2 flex justify-between items-center">
                      <div className="flex md-max:w-50p items-center text-sm font-semibold md:text-base">
                        <GiBookCover className="me-1" /> Publisher
                      </div>
                      <span className="md-max:text-right">
                      {data.bgPublisher}
                      </span>
                    </div>
                    <div className="flex justify-between ">
                      <div className="flex md-max:w-50p items-center text-sm font-semibold md:text-base">
                        <FaCalendar className="me-1" /> Publishing Year
                      </div>
                      <span className="md-max:text-right">
                      {data.bgYear}
                      </span>
                    </div>
                  </div>

                  <div className="w-full md:w-50p sm-max:mt-2">
                    <div className="mb-2 flex justify-between">
                      <div className="flex md-max:w-50p items-center text-sm font-semibold md:text-base">
                        <GiBigGear className="me-1" /> Designer
                      </div>
                      <span className="md-max:text-right">
                      {data.bgDesigner}
                      </span>
                    </div>
                    <div className="mb-2 flex justify-between">
                      <div className="flex md-max:w-50p items-center text-sm font-semibold md:text-base">
                        <HiUserGroup className="me-1" /> Played Best At:
                      </div>
                      <span className="md-max:text-right">
                      {data.bgPlayedBestAt}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 rounded-xl  bg-gray-300 border p-4 text-sm md:text-base font-normal text-navy-700 lg:text-lg">
              <div className="mb-2 flex items-center text-sm font-semibold md:text-base">
                <FaInfoCircle className="me-1" /> Description
              </div>
              <div className="md-max:max-h-[200px] overflow-y-scroll">
              {data.bgLongDescription}
              </div>
            </div>
            <div className="mt-5 rounded-xl bg-white border p-4 text-lg font-normal text-navy-800">
              <div className="mb-2 flex items-center text-sm font-semibold md:text-base">
                How to Play {data.bgName} ?
              </div>
              <div>
                <iframe
                  className="mb-2 h-[200px] w-full md:h-[400px] lg:h-[500px]"
                  src={data.bgVideo}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </div>

            <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
              <ModalOverlay className="bg-[#000] !opacity-30" />
              <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
                <ModalBody>
                  <div className="mt-5 relative rounded-xl w-full bg-white p-4 text-navy-800">
                  <HiX className="absolute right-3 cursor-pointer" onClick={()=>{onClose()}}/>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (reviewId) {
                          handleReply();
                        } else {
                          handleReview();
                        }
                      }}
                    >
                      {reviewId ? (
                        <>
                          <span className="text-sm">Replying to {replyTo}</span>
                          <div className="mb-2">
                            <label className="text-base font-semibold ">
                              Reply Message
                            </label>
                          </div>
                          <textarea
                            value={replyMessage}
                            onChange={(e) => {
                              setreplyMessage(e.target.value);
                            }}
                            className="mb-4 w-full rounded-md border-0 border-navy-800 bg-gray-100 p-2 focus:border-navy-800 active:border-navy-800"
                            placeholder="Write your reply"
                          />
                          <div className="flex  w-full flex-col-reverse">
                            <div className="">
                              <label className="text-base font-semibold">
                                Name
                              </label>
                              <input
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                className="mt-2  w-full rounded-md border-0 border-navy-800 bg-gray-100 p-2 focus:border-navy-800 active:border-navy-800"
                                placeholder="Write your name"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-2">
                            <label className="text-base font-semibold ">
                              Review
                            </label>
                          </div>

                          <textarea
                            value={review}
                            onChange={(e) => {
                              setReview(e.target.value);
                            }}
                            className="mb-4 w-full rounded-md border-0 border-navy-800 bg-gray-100 p-2 focus:border-navy-800 active:border-navy-800"
                            placeholder="Write your review"
                          />
                          <div className="flex  w-full flex-col-reverse">
                            <div className="mt-4">
                              <label className=" text-base font-semibold">
                                Stars
                              </label>
                              <br />
                              <Rating
                                className="mt-2"
                                start={0}
                                stop={5}
                                onChange={(value) => {
                                  setStars(value);
                                }}
                                emptySymbol={
                                  <MdStar className="text-2xl text-gray-100" />
                                }
                                fullSymbol={
                                  <MdStar className="text-2xl text-teal-400" />
                                }
                              />
                            </div>
                            <div className="">
                              <label className="text-base font-semibold">
                                Name
                              </label>
                              <input
                                value={name}
                                onChange={(e) => {
                                  setName(e.target.value);
                                }}
                                className="mt-2  w-full rounded-md border-0 border-navy-800 bg-gray-100 p-2 focus:border-navy-800 active:border-navy-800"
                                placeholder="Write your name"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <button className="mt-2 items-center justify-center rounded-md bg-teal-500 px-2 py-1 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200">
                        Submit
                      </button>
                    </form>
                  </div>
                </ModalBody>
              </ModalContent>
            </Modal>
            
            {data.reviews.length!=0?
            <div className="mt-5 rounded-xl bg-white border p-4 text-navy-800">
              <div className="mb-6 flex justify-between text-lg font-bold">
                <div>Reviews & Ratings</div>
                <div className="flex items-center space-x-2">
                  <div className="flex  h-10 w-10 items-center justify-center rounded-full bg-teal-500 text-white">
                    {rating}
                  </div>
                  <button
                    onClick={() => {
                      setReviewId("");
                      setReplyTo("");
                      onOpen();
                    }}
                    className="items-center justify-center rounded-md bg-teal-500 px-2 py-1 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200"
                  >
                    Rate Board Game
                  </button>
                </div>
              </div>
              <div>
                {data?.reviews?.map((elem) => {
                  return (
                    <>
                      <div className="mb-4 rounded-xl bg-white border p-3">
                        <div className="text-sm">@{elem.name}</div>
                        <div className="text-base">{elem.review}</div>
                        <div className="mt-2 flex w-full flex-col justify-between">
                          <Rating
                            initialRating={elem.stars}
                            readonly
                            emptySymbol={
                              <MdStar className="text-2xl text-gray-100" />
                            }
                            fullSymbol={
                              <MdStar className="text-2xl text-teal-400" />
                            }
                          />
                        </div>
                        <div className="flex items-center">
                          <div className="flex items-center text-sm">
                            <MdThumbUp
                              onClick={() => {
                                handleLikeReview(elem._id);
                              }}
                              className="mr-2 cursor-pointer hover:text-teal-400"
                            />{" "}
                            {elem.likes}
                          </div>
                          <button
                            onClick={() => {
                              setReviewId(elem._id);
                              setReplyTo(elem.name);
                              onOpen();
                            }}
                            className="flex items-center ms-4 hover:text-teal-500"
                          >
                            <MdReply className="mr-2" /> Reply
                          </button>
                        </div>
                        {elem?.replies?.map((element) => {
                          return (
                            <div className="mt-4">
                              <div className="mb-4 rounded-xl bg-white border p-3">
                                <div className="text-sm">
                                  @{element.name}{" "}
                                  <span className="font-medium">
                                    replying to
                                  </span>{" "}
                                  @{element.replyTo}
                                </div>
                                <div className="text-base">
                                  {element.replyMessage}
                                </div>
                                <div className="flex items-center">
                                  <div className="flex items-center text-sm">
                                    <MdThumbUp
                                      onClick={() => {
                                        handleLikeReply(elem._id, element._id);
                                      }}
                                      className="mr-2 cursor-pointer hover:text-teal-400"
                                    />{" "}
                                    {element.likes}
                                  </div>
                                  <button
                                    onClick={() => {
                                      setReviewId(elem._id);
                                      setReplyTo(element.name);
                                      onOpen();
                                    }}
                                    className="flex items-center ms-4 hover:text-teal-500"
                                  >
                                    <MdReply className="mr-2" /> Reply
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>:<div>
            <div className="mt-5 rounded-xl bg-white border p-4 text-navy-800">
              <div className=" flex justify-between items-center md:text-base text-sm lg:text-lg font-medium">
                <div>Be the first one to write review for "{data.bgName}"</div>
                <div className="flex items-center">
                  <button
                    onClick={() => {
                      setReviewId("");
                      setReplyTo("");
                      onOpen();
                    }}
                    className="items-center justify-center rounded-md bg-white border text-gray-800 px-2 py-1 md:text-base text-sm ms-2 font-medium  transition duration-200 hover:bg-gray-50 active:bg-gray-100 dark:bg-gray-100 dark:text-white dark:hover:bg-gray-50 dark:active:bg-gray-100"
                  >
                    Rate Board Game
                  </button>
                </div>
              </div>
            </div>
            </div>
            }
            {data?.recommendations.length!=0 && (
              <div className="mt-5 flex w-full justify-center">
                <div className="container ">
                  <h1 className="text-inherit block  text-base  font-semibold leading-tight tracking-normal text-navy-800 antialiased dark:text-white md:text-xl lg:text-2xl">
                    Recommended Board Games
                  </h1>
                  <div className="mt-5 md:mt-8 w-full">
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={false}
                      responsive={responsive}
                      //ssr={true} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      removeArrowOnDeviceType={[]}
                      containerClass=""
                      //deviceType={this.props.deviceType}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {data.recommendations?.map((elem, index) => {
                        return (
                          // <Link key={elem._id} to={"/boardgame/" + elem._id}>
                          <div
                            onClick={() => {
                              history("/boardgames/view/" + elem._id);
                            }}
                            className={
                              "relative w-full  rounded-xl  md:w-[310px]"
                            }
                          >
                            <div className="z rounded-xl pr-5  hover:opacity-80">
                              <img
                                className="h-36 md:h-52  lg:h-80  w-full rounded-xl "
                                src={elem.bgImage}
                              />
                            </div>
                          </div>
                          // </Link>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </UserLayout>
  );
};

export default BoardgameDisplay;
