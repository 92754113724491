import React, { useState } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import UserLayout from "layouts/user";
import { toast } from "react-toastify";
import { FoodAndBeverages, Header, FAQ } from "./components";
import { Helmet } from "react-helmet";

const FoodBeverages = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [contentData, setContentData] = useState([]);
  const [faQData, setFaQ] = useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/product/allCatalog`)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data.productData);
        setContentData(res.data.contentData);
        setFaQ(res.data.faqData);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading(false);
      });
  }, [limit, page]);

  
  return (
    <UserLayout>
       <Helmet>
                    <title>Food & Beverages - Meepleton board game cafe</title>
                    <meta name="description" content='Meepleton is game cafe in bhopal which has best pool settings.'/>
                </Helmet>
      {isLoading?<>
        <>
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex items-center justify-center">
        <Loading />
      </div>
    </div>
  </>
  
      </>:<>
      <Header data={contentData} />

      <div className="flex mb-8 md:mb-12 bg-gray-50 w-full justify-center  md-max:px-5">
        <div className="container mt-5 md:mt-8 ">
          <div className="mb-4"></div>
            <>
              <div className="rounded-xl bg-[url('https://res.cloudinary.com/defonzszt/image/upload/v1696111215/ftodjhkpu8lqxak7yg7e.png')]  bg-contain px-4 md:px-16 py-10">
                <div className="text-inherit font-festive ml-2 text-5xl font-semibold leading-tight tracking-normal text-white antialiased dark:text-white md:text-6xl lg:text-8xl">
                  Menu
                </div>
                <div className="grid  grid-cols-1 md:w-50p gap-1 ">
                  {data.map((elem) => {
                    return <FoodAndBeverages data={elem} />;
                  })}
                </div>
              </div>
            </>
                  </div>
      </div>
      <FAQ data={faQData} />
      </>}
    </UserLayout>
  );
};

export default FoodBeverages;
