import { createSlice } from "@reduxjs/toolkit";

export const booksSlice = createSlice({
  name: "books",
  initialState: { value: {
    data:[],
    } 
  },
  reducers: {
    updateData: (state, action) => {
      state.value.data=action.payload.data
    },
  },
});

export const { updateData } = booksSlice.actions;
export default booksSlice.reducer;