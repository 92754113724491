import React from "react";
import { Accordion } from "flowbite-react";
import FaQComponent from "components/FaQ";

const FAQ = (props) => {
  const { data } = props;
  return (
    <FaQComponent data={data}/>
  );
};

export default FAQ;