import React from 'react'

const HeaderComponent = (props) => {
  console.log(props)  
  const {title,para} = props
    return (
    <section className="bg-white py-5 md:py-8 dark:bg-gray-900">
    <div className="mx-auto  max-w-screen-2xl px-4 pt-20 lg:gap-8 xl:gap-0">
      <div className="mr-auto place-self-center ">
        <h1 className="mb-4 max-w-2xl text-3xl font-extrabold leading-none tracking-tight dark:text-white md:text-4xl xl:text-5xl">
          {title}
        </h1>
        {para.map(elem=>{return <p className="mb-2 font-light text-gray-123 dark:text-gray-400 md:text-lg lg:mb-2 lg:text-xl">
        {elem.Content}
        </p>})} 
      </div>
    </div>
  </section>
  
  )
}

export default HeaderComponent
