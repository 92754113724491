import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import InputFieldTextArea from "components/fields/TextField";
import { updateRefresher } from "features/refresher";

const Addreview = () => {
  const [isLoading, setIsLoading] = useState(false);

  //refresher setup
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);

  //States for stars
  const [review, setreview] = useState("");
  const [stars, setstars] = useState("");
  const [name, setname] = useState("");
  
  //errors for stars
  const [reviewMessage, setreviewMessage] = useState("");
  const [reviewState, setreviewState] = useState();

  const [starsMessage, setstarsMessage] = useState("");
  const [starsState, setstarsState] = useState();

  const [nameMessage, setnameMessage] = useState("");
  const [nameState,setnameState] = useState("")   

  //validation logic
  useEffect(() => {
    if (review === "") {
      setreviewMessage("Review cannot be empty!");
      setreviewState();
    } else {
      setreviewMessage("");
      setreviewState("success");
    }

    if (stars === "") {
      setstarsMessage("");
      setstarsState();
    } else if (stars <0 || stars>5) {
      setstarsMessage("Stars must be in range of 0-5!");
      setstarsState("error");
    } else {
      setstarsMessage("");
      setstarsState("success");
    }

    if(name === ""){
        setnameMessage("Name cannot be empty!");
        setnameState();
      } else if (name < 0) {
        setnameMessage("Name must be a Positive Integer!");
        setnameState("error");
      } else {
        setnameMessage("");
        setnameState("success");
          
    }
  }, [review, stars,name]);

  //importing axios

  const axiosInstance = useAxiosInstance();

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post("/review", {
        review: review,
        stars: stars,
        name:name           
      })
      .then((res) => {
        if (res.data) {
          toast.success(`Review by ${res.data.name} created Successfully`);
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };

  return (
    <div className=" rounded-primary  bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
      {/* <img src={navlogo} height={100}/> */}
      {/* review */}
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        {reviewMessage && (
          <span className="text-red-400 ms-1">{reviewMessage}</span>
        )}
        <InputFieldTextArea
          variant="auth"
          extra="mb-3"
          label="Review"
          placeholder="Customer Review.."
          id="review"
          name="review"
          state={reviewState}
          value={review}
          onChange={(e) => {
            setreview(e.target.value);
          }}
        />

        {/* stars */}

        {starsMessage && (
          <span className="text-sm text-red-500 ms-1">{starsMessage}</span>
        )}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Stars"
          placeholder="Number of stars"
          id="stars"
          name="Number"
          state={starsState}
          value={stars}
          onChange={(e) => {
            setstars(e.target.value);
          }}
        />

        {/* name */}

       
       <div>{nameMessage && (
          <span className="text-sm text-red-500 ms-1">{nameMessage}</span>
        )}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Name"
          placeholder="Name"
          id="stars"
          name="Number"
          state={nameState}
          value={name}
          onChange={(e) => {
            setname(e.target.value);
          }}
        /></div>

        {isLoading ? (
          <button
            disabled
            className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <Loading />
          </button>
        ) : (
          reviewMessage === "" &&
          starsMessage === "" && 
          nameMessage ==="" &&
          (
            <button
              name={"submit"}
              className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add
            </button>
          )
        )}
      </form>
    </div>
  );
};

export default Addreview;
