import React from "react";
import { Link } from "react-router-dom";

const Book = (props) => {
  const { bookImage, bookAvailable ,_id } = props.data;
  return (
    <div>
      <Link key={_id} to={"/books/view/" + _id}>
        <div className={" w-full  relative rounded-xl bg-[black]"}>
          {bookAvailable !=='YES' && <div className="absolute left-1/4 top-[40%]"><img className="h-28" src={'https://res.cloudinary.com/dqzute9m4/image/upload/v1696699451/images-removebg-preview_dopmcy.png'}/></div>}
          <div className={ "rounded-xl hover:opacity-80" }>
            <img
              className="h-[210px] w-full rounded-xl  md:h-[240px] lg:h-[280px]"
              src={bookImage}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Book;
