import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useState } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SelectField from "components/fields/selectField";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";
import MainTable2 from "components/table/Tablev2";

const Tableevents = (props) => {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);

  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setid] = React.useState("");

  const [title, settitle] = useState("");
  const [start, setstart] = useState("");
  const [end, setend] = useState("");
  const [eventType, seteventType] = useState("");
  const [imageSrc, setimageSrc] = useState("");
  const [desc, setdesc] = useState("");
  const [time, settime] = useState("");
  const [imageType, setimageType] = useState("");
  const [guests,setGuests] = useState(0);

  const handleImage = async (e) => {
    try {
      toast.info("Image are uploading to database");
      for (const element of e.target.files) {
        const data = new FormData();
        data.append("file", element);
        data.append("upload_preset", "Meepleton");
        data.append("cloud_name", "dqzute9m4");

        const resp = await fetch(
          "https://api.cloudinary.com/v1_1/dqzute9m4/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await resp.json();
        setimageSrc(imageData.secure_url);
      }
      toast.success("Image are successfully Uploaded to database");
    } catch (err) {
      console.error(err);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/events/" + id, {
        title: title,
        start: start,
        end: end,
        imageSrc: imageSrc,
        desc: desc,
        time: time,
        guests:guests
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'events for"' + res.data.title + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.errors.msg) {

          toast.error(err.response.data.errors.msg);
        }
      });
    setIsLoading2(false);
  };

  // React.useEffect(() => {
  //   if(name === ""){
  //     setnameMessage("Name cannot be empty!");
  //     setnameState()
  //   }
  //   else {
  //     setnameMessage("");
  //     setnameState('success');
  //   }

  //   if(price === ""){
  //     setpriceMessage("")
  //     setpriceState()
  //   }else if (price> 0) {
  //     setpriceMessage("Price must be a Negative Integer!");
  //     setpriceState('error')
  //   } else {
  //     setpriceMessage("");
  //     setpriceState('success')
  //   }
  // }, [name, price]);

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " events ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/events/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("events is deleted Successfully!");
            dispatch(updateRefresher({ refreshCount: refresh + 1 }));
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    toast.info("Looking for book in database");
    axiosInstance
      .get("/events/" + id)
      .then((res) => {
        toast.success("Successfully Found!");
        const eventsData = res.data;
        const { _id, title, start, imageSrc, desc, time, guests } = eventsData;
        settitle(title);
        setstart(start);
        setimageSrc(imageSrc);
        setdesc(desc);
        settime(time);
        setGuests(guests)
        setid(_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {(
        <MainTable2
          url="/events/allByQuery"
          tableKeys={["title", "start", "end", "guests", "time"]}
          refresh={refresh}
          editFunc={editFunc}
          deleteFunc={deleteFunc}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[25vh] md:top-[12vh]">
          <ModalBody>
            <div className=" h-[500px] overflow-y-scroll rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800 ">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 ">
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Title"
                    placeholder="Enter title"
                    id="title"
                    start="text"
                    value={title}
                    onChange={(e) => {
                      settitle(e.target.value);
                    }}
                  />
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Description"
                    type="text"
                    placeholder="Enter description"
                    id="start"
                    value={desc}
                    onChange={(e) => {
                      setdesc(e.target.value);
                    }}
                  />
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Time"
                    type="text"
                    placeholder="Enter time"
                    id="time"
                    value={time}
                    onChange={(e) => {
                      settime(e.target.value);
                    }}
                  />
                </div>
                <SelectField
                  label={"Is this Event an all day event?"}
                  isSearchable={false}
                
                  onChange={(e) => {
                    seteventType(e.value);
                  }}
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                />
                {eventType !== "" ? (
                  eventType === true ? (
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Date and Time"
                      type="date"
                      placeholder="Enter date"
                      id="start"
                      start="text"
                      value={start}
                      onChange={(e) => {
                        setstart(e.target.value);
                      }}
                    />
                  ) : (
                    <>
                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="Start Date and Time"
                        type="datetime-local"
                        placeholder="Enter date"
                        id="start"
                        start="text"
                        value={start}
                        onChange={(e) => {
                          setstart(e.target.value);
                        }}
                      />

                      <InputField
                        variant="auth"
                        extra="mb-3"
                        label="End Date and Time"
                        type="datetime-local"
                        placeholder="Enter date"
                        id="start"
                        start="text"
                        value={end}
                        onChange={(e) => {
                          setend(e.target.value);
                        }}
                      />
                    </>
                  )
                ) : (
                  <></>
                )}

                <SelectField
                  label={"Image type"}
                  isSearchable={false}
                  onChange={(e) => {
                    setimageType(e.value);
                  }}
                  options={[
                    { label: "Board game Image", value: true },
                    { label: "Choose Image", value: false },
                  ]}
                />

                {imageType !== "" ? (
                  imageType === true ? (
                    <div className="w-full">
                      <SelectField
                        label={"Games"}
                        // value={games}
                        onChange={(e) => {
                          setimageSrc(e.bgImage);
                        }}
                        options={props.data?.map((element) => ({
                          label: element.bgName,
                          value: element.bgName,
                          bgImage: element.bgImage,
                        }))}
                      />
                    </div>
                  ) : (
                    <>
                      <div>
                        <label className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white">
                          Choose Image
                        </label>
                      </div>
                      <input
                        className="mt-2 w-full rounded-xl border py-2 px-3 text-navy-800 file:rounded-md file:border-0  file:bg-gray-100"
                        variant="auth"
                        type="file"
                        label="Image"
                        placeholder="Choose image"
                        id="image"
                        onChange={(e) => handleImage(e)}
                      />
                    </>
                  )
                ) : (
                  <></>
                )}

                {imageSrc && <img src={imageSrc} className="max-w-xs" />}

                {isLoading2 ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  <button
                    type={"submit"}
                    className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Save Changes
                  </button>
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Tableevents;
