import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import { updateRefresher } from "features/refresher";


const AddDiscount = () => {
    //refresher setup
    const dispatch = useDispatch();
    const refresh = useSelector((state) => state.refresher.value.refreshCount);

    const [isLoading,setIsLoading] = useState(false);

    //States for email  
    const [name,setName] = useState("")  
    const [email,setEmail] = useState("");
    const [password,setPassword] = useState("");
    
    //errors for email 
    const [nameMessage,setNameMessage] = useState("");
    const [nameState,setNameState] = useState();
    
    const [emailMessage,setEmailMessage] = useState("");
    const [emailState,setEmailState] = useState();
    
    const [passwordMessage,setPasswordMessage] = useState("");
    const [passwordState,setPasswordState] = useState();
    
    //validation logic
    useEffect(() => {
      if(name === ""){
            setNameMessage("")
            setNameState()
      }
      else if(name.length === 0){
        setNameMessage("Name cannot be empty!");
        setNameState()
      }
      else {
        setNameMessage("");
        setNameState('success');
      }
    
      if(password === ""){
        setPasswordMessage("")
        setPasswordState()
      }else if (password.length<5) {
        setPasswordMessage("Password cannot be less than 5!");
        setPasswordState('error')
      } else {
        setPasswordMessage("");
        setPasswordState('success')
      }

      if(email === ""){
        setEmailMessage("")
        setEmailState()
      }
      else if(isValidGmailname(email)===false){
        setEmailMessage("Email must have @meepleton.com")
        setEmailState("error")
      }else{
        setEmailMessage("")
        setEmailState("success")
      }
    }, [name, password, email]);
    
    function isValidGmailname(name) {
      // Regular expression for name validation ending with "@gmail.com"
      const gmailRegex = /^[a-zA-Z0-9._-]+@meepleton\.com$/;
    
      return gmailRegex.test(name);
    }
    
    //importing axios
    
    const axiosInstance = useAxiosInstance();
    
    const handleLogin = (e) =>{
      e.preventDefault();
      setIsLoading(true);
      axiosInstance
        .post("/register", {
          name: name,
          email: email,
          password : password,
          role : 'SUBADMIN'
        })
        .then((res) => {
          if (res.data) {
            toast.success(`Sub Admin created Successfully`);
            dispatch(updateRefresher({ refreshCount: refresh + 1 }));
          } else {
            toast.error("Something went wrong!");
          }
          setIsLoading(false);
        })
        .catch((err) => {
        //   console.log(err);
          toast.error(err.response.data.errors.msg);
          setIsLoading(false);
        });
    }

    
return (
    <div className=" bg-white p-4 rounded-primary  dark:border-navy-900 dark:bg-navy-800 ">
        {/* <img src={navlogo} height={100}/> */}
        {/* name */}
        <form onSubmit={(e)=>{handleLogin(e)}}>
        {nameMessage&&<span className="text-red-400 ms-1">{nameMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Name"
          placeholder="Enter your Name"
          id="name"
          type="name"
          state={nameState}
          value={name}
          onChange={(e)=>{
            setName(e.target.value)
          }}
        />

        {/* email */}
        
        {emailMessage&&<span className="text-sm text-red-500 ms-1">{emailMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          placeholder="Enter your Email"
          id="email"
          type="email"
          state={emailState}
          value={email}
          onChange={(e)=>{
            setEmail(e.target.value)
          }}
        />

        {/* Password */}
        
        {passwordMessage&&<span className="text-sm text-red-500 ms-1">{passwordMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password"
          placeholder="Enter your Password"
          id="password"
          type="text"
          state={passwordState}
          value={password}
          onChange={(e)=>{
            setPassword(e.target.value)
          }}
        />
        {isLoading?<button disabled className="linear text-center mt-2 px-4 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          <Loading/>          
        </button>:nameMessage ==="" && emailMessage ==="" && <button
        type={'submit'} 
        className="linear mt-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Add
        </button>
        }
        </form>
      </div>
  )
}

export default AddDiscount
