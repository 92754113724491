import PieChart from "components/charts/PieChart";
import { pieChartData, pieChartOptions } from "variables/charts";
import Card from "components/card";

const CashVsUPIPieChart = (props) => {
    const pieChartOptions = {
        labels: props.labels,
        colors: ["#4318FF", "#6AD2FF"],
        chart: {
          width: "50px",
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        hover: { mode: null },
        plotOptions: {
          donut: {
            expandOnClick: false,
            donut: {
              labels: {
                show: false,
              },
            },
          },
        },
        fill: {
          colors: ["#4318FF", "#6AD2FF" ],
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: undefined,
            backgroundColor: "#000000"
          },
        },
      };
      
    const pieChartData = props.data;
    
  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            UPI Vs Cash
          </h4>
        </div>

        {/* <div className="mb-6 flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
        </div> */}
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        <PieChart options={pieChartOptions} series={pieChartData} />
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">{props?.labels[0]}</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
            {props?.data[0]}
            <span className="text-sm">{` (${Math.round((props?.data[0]/(props?.data[0]+props?.data[1]) * 100) *100)/100}%)`}</span>
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#6AD2FF]" />
            <p className="ml-1 text-sm font-normal text-gray-600">{props?.labels[1]}</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {props?.data[1]}
            <span className="text-sm">{` (${Math.round((props?.data[1]/(props?.data[0]+props?.data[1]) * 100) *100)/100}%)`}</span>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default  CashVsUPIPieChart