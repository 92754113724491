import React, { useState, useEffect } from "react";
import InputField from "components/fields/InputField";
import useAxiosInstance from "../../../../axiosInstance";
import { toast } from "react-toastify";
import Loading from "components/loading";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";

const AddBook = () => {
  const [isLoading, setIsLoading] = useState(false);

  // Define state variables for the book properties
  const [bookName, setBookName] = useState("");
  const [bookShortDescription, setBookShortDescription] = useState("");
  const [bookLongDescription, setBookLongDescription] = useState("");
  const [bookImage, setBookImage] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookPages, setBookPages] = useState("");
  const [bookGenre, setBookGenre] = useState("");
  const [bookGoodreadsRating, setBookGoodreadsRating] = useState("");
  const [bookUserRating, setBookUserRating] = useState("");
  const [bookAwards, setBookAwards] = useState("");
  const [bookYearPublished, setBookYearPublished] = useState("");
  const [bookBuy, setBookBuy] = useState("");
  const [bookPublisher, setBookPublisher] = useState("");
  const [bookWTR, setBookWTR] = useState(0);
  const [bookHomepage, setBookHomepage] = useState("off");
  const [bookSerialNumber, setBookSerialNumber] = useState("");
  const [bookAvailable, setBookAvailable] = useState("N/A");

  // Define an axios instance
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const refresh = useSelector(state=>state.refresher.value.refreshCount)

  // Define a function to handle form submission
  const handleAddBook = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Create an object to send as the book data
    const bookData = {
      bookName,
      bookShortDescription,
      bookLongDescription,
      bookImage,
      bookAuthor,
      bookPages,
      bookGenre,
      bookGoodreadsRating,
      bookUserRating,
      bookAwards,
      bookYearPublished,
      bookBuy,
      bookPublisher,
      bookWTR,
      bookHomepage,
      bookSerialNumber,
      bookAvailable,
    };

    axiosInstance
      .post("/books", bookData) // Replace with your API endpoint
      .then((res) => {
        if (res.data) {
          toast.success(`${res.data.bookName} added successfully`);
          dispatch(updateRefresher({refreshCount:refresh+1}))
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error adding book");
        setIsLoading(false);
      });
  };

  const addByGoogleApi = () => {
    toast.info('Searching in Google Database')
    axios
      .get(
        "https://www.googleapis.com/books/v1/volumes?q=" +
          bookName+
          "&key=AIzaSyB1U8Ns1dz9umNZ8SYK26b0Opv3aVJJfCc"
      )
      .then((result) => {
        const {
          data: { items },
        } = result; // Assuming "result" is your API response

        if (items && items.length > 0) {
          toast.success('Book found!')
          const volumeInfo = items[0].volumeInfo;
          setBookName(volumeInfo.title || "N/A");
          setBookLongDescription(volumeInfo.description || "N/A");
          setBookImage(volumeInfo.imageLinks.thumbnail || "N/A");
          setBookAuthor(volumeInfo.authors[0] || "N/A");
          setBookPages(volumeInfo.pageCount || "N/A");
          setBookGenre(volumeInfo.categories[0] || "N/A");
          setBookGoodreadsRating("");
          setBookUserRating(0);
          setBookAwards("");
          setBookYearPublished(volumeInfo.publishedDate || "N/A");
          setBookBuy("N/A");
          setBookAvailable("YES");
          setBookPublisher(volumeInfo.publisher || "N/A");
          setBookHomepage("off");
          setBookWTR(0);
        }else{
          toast.error("Book is not available in Google Database!")
        }
      }).catch(err=>{toast.error('Something went Wrong!')})
  };

  return (
    <div className="rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
      {bookName&&<button onClick={addByGoogleApi} className="linear mt-2 rounded-xl bg-brand-500 px-2 mb-2 py-[5px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">Populate from Google API</button>}
      <form onSubmit={handleAddBook}>
        <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
          {/* Book Name */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Book Name"
              placeholder="Enter Book Name"
              id="bookName"
              type="text"
              value={bookName}
              onChange={(e) => setBookName(e.target.value)}
            />
          </div>
          {/* Book Short Description */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Short Description"
              placeholder="Enter Short Description"
              id="bookShortDescription"
              type="text"
              value={bookShortDescription}
              onChange={(e) => setBookShortDescription(e.target.value)}
            />
          </div>
          {/* Book Long Description */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Long Description"
              placeholder="Enter Long Description"
              id="bookLongDescription"
              type="text"
              value={bookLongDescription}
              onChange={(e) => setBookLongDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
          {/* Book Image */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Image URL"
              placeholder="Enter Image URL"
              id="bookImage"
              type="text"
              value={bookImage}
              onChange={(e) => setBookImage(e.target.value)}
            />
          </div>
          {/* Book Author */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Author"
              placeholder="Enter Author"
              id="bookAuthor"
              type="text"
              value={bookAuthor}
              onChange={(e) => setBookAuthor(e.target.value)}
            />
          </div>
          {/* Book Pages */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Number of Pages"
              placeholder="Enter Number of Pages"
              id="bookPages"
              type="text"
              value={bookPages}
              onChange={(e) => setBookPages(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
          {/* Book Genre */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Genre"
              placeholder="Enter Genre"
              id="bookGenre"
              type="text"
              value={bookGenre}
              onChange={(e) => setBookGenre(e.target.value)}
            />
          </div>
          {/* Book Goodreads Rating */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Goodreads Rating"
              placeholder="Enter Goodreads Rating"
              id="bookGoodreadsRating"
              type="text"
              value={bookGoodreadsRating}
              onChange={(e) => setBookGoodreadsRating(e.target.value)}
            />
          </div>
          {/* Book User Rating */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="User Rating"
              placeholder="Enter User Rating"
              id="bookUserRating"
              type="text"
              value={bookUserRating}
              onChange={(e) => setBookUserRating(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
          {/* Book Awards */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Awards"
              placeholder="Enter Awards"
              id="bookAwards"
              type="text"
              value={bookAwards}
              onChange={(e) => setBookAwards(e.target.value)}
            />
          </div>
          {/* Book Year Published */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Year Published"
              placeholder="Enter Year Published"
              id="bookYearPublished"
              type="text"
              value={bookYearPublished}
              onChange={(e) => setBookYearPublished(e.target.value)}
            />
          </div>
          {/* Book Buy Link */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Buy Link"
              placeholder="Enter Buy Link"
              id="bookBuy"
              type="text"
              value={bookBuy}
              onChange={(e) => setBookBuy(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-between md:space-x-2 md-max:flex-wrap">
          {/* Book Publisher */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Publisher"
              placeholder="Enter Publisher"
              id="bookPublisher"
              type="text"
              value={bookPublisher}
              onChange={(e) => setBookPublisher(e.target.value)}
            />
          </div>
          {/* Book Weight to Read */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Weight to Read"
              placeholder="Enter Weight to Read"
              id="bookWTR"
              type="number"
              value={bookWTR}
              onChange={(e) => setBookWTR(Number(e.target.value))}
            />
          </div>
          {/* Book Homepage */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Homepage"
              placeholder="Enter Homepage"
              id="bookHomepage"
              type="text"
              value={bookHomepage}
              onChange={(e) => setBookHomepage(e.target.value)}
            />
          </div>
        </div>
        <div className="flex  md:space-x-2 md-max:flex-wrap">
          {/* Book Serial Number */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Serial Number"
              placeholder="Enter Serial Number"
              id="bookSerialNumber"
              type="number"
              value={bookSerialNumber}
              onChange={(e) => setBookSerialNumber(Number(e.target.value))}
            />
          </div>
          {/* Book Available */}
          <div className="w-1/3 md-max:w-full">
            <InputField
              variant="auth"
              extra="mb-3"
              label="Available"
              placeholder="Enter Available"
              id="bookAvailable"
              type="text"
              value={bookAvailable}
              onChange={(e) => setBookAvailable(e.target.value)}
            />
          </div>
          {/* Add more fields as needed */}
        </div>
        {bookImage&&
        <div className="flex justify-center">
        <img className="h-80 rounded-xl" src={bookImage}/>
        </div>}     
        {/* Submit button */}
        {isLoading ? (
          <button
            disabled
            className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <Loading />
          </button>
        ) : (
          <button
            type="submit"
            className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Add Book
          </button>
        )}
      </form>
    </div>
  );
};

export default AddBook;
