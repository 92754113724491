import { createSlice } from "@reduxjs/toolkit";

export const resfresherSlice = createSlice({
  name: "refresher",
  initialState: { value: {
    refreshCount:0,
    } 
  },
  reducers: {
    updateRefresher: (state, action) => {
      state.value.refreshCount=action.payload.refreshCount
    },
  },
});

export const { updateRefresher } = resfresherSlice.actions;
export default resfresherSlice.reducer;