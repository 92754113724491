import UserLayout from "layouts/user";
import React from "react";
import { FaLinkedin, FaUserFriends } from "react-icons/fa";
import { MdCall, MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";

const teamMembers = [
  {
    name: 'Abhishek Chandran',
    role: 'Founder',
    description: 'Bonnie drives the technical strategy of the flowbite platform and brand.',
    imageUrl: 'https://media.licdn.com/dms/image/D4D03AQEL4IpyUnlV0Q/profile-displayphoto-shrink_100_100/0/1641291514203?e=1705536000&v=beta&t=kGiuVTtXcLBgDAPuAScVvPVDN8YCyVnBJeie2mFUGI4',
    phone: 'tel:8989095732',
    email: 'mailto:abhishekchandran94@gmail.com',
    linkedin: 'https://www.linkedin.com/in/abhishek-chandran-782022129/',
  },

  {
    name: 'Purushottam Pal',
    role: 'Guest Services Associate',
    description: 'As a Board Game Cafe Associate, I am passionate about creating a memorable experience for patrons. With a deep love for board games and their diverse rules, I excel in explaining and teaching games to customers, fostering an inviting atmosphere',
    imageUrl: 'https://media.licdn.com/dms/image/C5603AQHXpie4u_wTPw/profile-displayphoto-shrink_100_100/0/1624090180061?e=1705536000&v=beta&t=Fw-J1xZ8CfF7n9E5r_nOZ8OXixnwa7zziDz5lR9hB6g',
    phone: 'tel:9499829700',
    email: 'mailto:abhishekchandran94@gmail.com',
    linkedin: 'https://www.linkedin.com/in/purushottam-pal-00214b20b/',
  },

  {
    name: 'Sohan Panwar',
    role: 'Business Development Executive',
    description: 'As a Board Game Cafe Associate, I am passionate about creating a memorable experience for patrons. With a deep love for board games and their diverse rules, I excel in explaining and teaching games to customers, fostering an inviting atmosphere',
    imageUrl: 'https://media.licdn.com/dms/image/D5603AQGNIyIH-dAh1A/profile-displayphoto-shrink_400_400/0/1681461648620?e=1705536000&v=beta&t=0gyfO8FJwP8HsIejsgcexdEbjZbwUHFAqtQ_vj2J2RQ',
    phone: 'tel:9499829700',
    email: 'mailto:abhishekchandran94@gmail.com',
    linkedin: 'https://www.linkedin.com/in/sohan-panwar-22b679272/',
  }
  // Add more team members as needed
];

const AboutUs = () => {
  return (
    <UserLayout>
      <section className="bg-white py-8 dark:bg-gray-900 md:py-8">
        <div className="mx-auto grid max-w-screen-2xl px-4 pt-20 md-max:flex md-max:flex-col lg:grid-cols-12 lg:gap-8  xl:gap-0">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="mb-4 max-w-2xl text-3xl font-extrabold leading-none tracking-tight  dark:text-white md:text-4xl xl:text-5xl">
              About us
            </h1>
            <p className="mb-6 max-w-2xl font-light text-gray-123 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
              Meepleton is Bhopal's only board game cafe and one of the best
              cafes in Bhopal. Our Cafe provides a fun and family-friendly
              environment for people to get together and play modern Board
              games.
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a
                href="tel:+91 8989095732"
                className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
              >
                {" "}
                <MdCall className="me-2" />
                Call us
              </a>
            </div>
          </div>
          <div className="md-max:mt-6 lg:col-span-5 lg:mt-0 lg:flex">
            <img
              className="rounded-xl"
              src="https://lh3.googleusercontent.com/p/AF1QipN8FFLEixP35x6GBG1tubVN4bfn0A-Dpf9YVacg=s1360-w1360-h1020"
              alt="hero image"
            />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="mx-auto max-w-screen-2xl space-y-12  px-4 pb-8 lg:space-y-20 lg:py-24 lg:px-6">
          {/* Row */}
          <div className="items-center  gap-8 md:grid md:grid-cols-2 md-max:flex md-max:flex-col-reverse xl:gap-16">
            <div className="text-gray-123 dark:text-gray-400 sm:text-lg">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                Our Mission
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Our board game cafe fosters genuine connections and reduces
                screen time by providing a diverse selection of games for
                face-to-face engagement and shared memories.
              </p>
              {/* List */}
              <ul
                role="list"
                className="my-7 space-y-5 border-t border-gray-200 pt-8 dark:border-gray-700"
              >
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Screen Time Balance
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Social Interaction
                  </span>
                </li>
                <li className="flex space-x-3">
                  {/* Icon */}
                  <svg
                    className="h-5 w-5 flex-shrink-0 text-teal-500 dark:text-teal-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                    Community Building
                  </span>
                </li>
              </ul>
              <p className="mb-8 font-light lg:text-xl">
                In a digital age, we're dedicated to reviving the joy of
                in-person interactions and building a stronger community through
                the shared love of board games. Join us in creating memorable
                moments, one game at a time.
              </p>
            </div>
            <div className="flex items-center justify-center">
              <img
                className="mb-4 h-64 rounded-lg md-max:h-36 lg:mb-0 "
                src="https://www.abwebexperts.com/wp-content/uploads/2021/09/target.png"
                alt="board game feature image"
              />
            </div>
          </div>

          {/* Row */}
          <div className="items-center gap-8 lg:grid lg:grid-cols-2  xl:gap-16">
            {/* {data.topBooks && <TopBook data={data.topBooks} />} */}
            <div className="flex items-center justify-center">
              <img
                className="mb-4  h-64 rounded-lg md-max:h-36 lg:mb-0  lg:flex"
                src="https://prolink.insure/wp-content/uploads/2023/07/Our-Story-01.svg"
                alt="board game feature image"
              />
            </div>
            <div className="text-gray-123 dark:text-gray-400 sm:text-lg md-max:mt-6">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                Our Story
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Founded by Abhishek Chandran, our board game cafe is a testament
                to a passion for fostering meaningful connections and building
                community through the joy of board games. Abhishek's vision
                emerged from a desire to create a space where people could
                escape the digital noise, rediscover the joy of face-to-face
                interactions, and share moments of laughter and camaraderie.
              </p>
              {/* List */}

              <p className="mb-8 font-light lg:text-xl">
                Abhishek Chandran's dream of a board game cafe is not just a
                business; it's a passion project built on the foundation of
                genuine connections and a shared love for unplugged
                entertainment. Join us in this journey of community building,
                one roll of the dice at a time.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
          <div className="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">Our Team</h2>
            <p className="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">Meet our loving team</p>
          </div> 
          <div className="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
      {teamMembers.map((member, index) => (
        <div key={index} className="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
          <a href="#">
            <img className="w-full rounded-lg sm:rounded-none sm:rounded-l-lg" src={member.imageUrl} alt={`${member.name} Avatar`} />
          </a>
          <div className="p-5">
            <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              <a href={member.linkedin}>{member.name}</a>
            </h3>
            <span className="text-gray-500 dark:text-gray-400">{member.role}</span>
            <p className="mt-3 mb-4 font-light text-gray-500 dark:text-gray-400">{member.description}</p>
            <ul className="flex space-x-4 sm:mt-0">
              <li>
                <a href={member.phone} className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                  <MdCall />
                </a>
              </li>
              <li>
                <a href={member.email} className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                  <MdEmail />
                </a>
              </li>
              <li>
                <a href={member.linkedin} className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                  <FaLinkedin />
                </a>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div> 
        </div>
      </section>

    </UserLayout>
  );
};

export default AboutUs;
