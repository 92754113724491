import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const TopGaming = (props) => {
  const data = props.data;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="w-full">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        //ssr={true} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        removeArrowOnDeviceType={[]}
        //deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        <div className="grid grid-cols-3 gap-5">
          {data.slice(0, 6).map((elem, index) => {
            return (
              <Link key={elem._id} to={"/boardgames/view/" + elem._id}>
                <div className={"  w-full rounded-xl bg-[black]"}>
                  <div className=" rounded-xl  hover:opacity-80">
                    <img
                      className="md:h-52 h-32 w-full rounded-xl"
                      src={elem.bgImage}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        <div className="grid grid-cols-3 gap-5">
          {data.slice(6, 12).map((elem, index) => {
            return (
              <Link key={elem._id} to={"/boardgames/view/" + elem._id}>
                <div className={"  w-full rounded-xl bg-[black]"}>
                  <div className=" rounded-xl  hover:opacity-80">
                    <img
                      className="md:h-52 h-32 w-full rounded-xl"
                      src={elem.bgImage}
                    />
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </Carousel>
    </div>
  );
};

export default TopGaming;
