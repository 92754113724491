import Chart from "react-apexcharts";

const DonatChart = (props) => {
  const { data, options } = props;

  return (
    <Chart
      options={options}
      type="donut" 
      width="100%"
      height="100%"
      series={data}
    />
  );
};

export default DonatChart;
