import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { useSelector } from "react-redux";
import DisplayInvoice from "views/admin/invoice/components/Invoice";
import ShowInvoices from "views/admin/invoice/components/showInvoices";
import DisplayLibraryInvoice from "views/admin/library/components/displayInvoice";
import Home from "views/user/home";
import UserLayout from "layouts/user";
import BoardGame from "views/user/boardgames";

import BoardGames from "views/admin/boardgame";

import Books from "views/user/books";
import Pool from "views/user/pools";

import Pools from "views/admin/pool";
import FoodBeverages from "./views/user/foodAndBeverages";
import BoardgameDisplay from "views/user/boardgameDisplay";
import BookDisplay from "views/user/bookDisplay";
import { GiAbbotMeeple, GiEightBall } from "react-icons/gi";
import {
  FaBook,
  FaImages,
  FaParagraph,
  FaQuestionCircle,
} from "react-icons/fa";
import Book from "views/admin/book";
import Content from "views/admin/content";
import Review from "views/admin/review";
import { MdReviews } from "react-icons/md";
import FAQ from "views/admin/faQ";
import Gallery from "views/admin/gallery";
import { BsCalendarEvent } from "react-icons/bs";
import Event from "views/admin/events";
import Reservations from "views/user/reservation";
import Parties from "views/user/parties";
import MeesageBox from "views/user/parties/components/MessageBox";
import UserLoginPage from "views/user/parties/components/Login";
import ShowCashVsUpiDateWise from "views/admin/invoice/components/showCashVsUpiDateWise";
import GalleryMainPage from "views/user/gallery";
import AboutUs from "views/user/aboutUs";
import ShowCustomerList from "views/admin/invoice/components/customerList";
const App = () => {
  const userData = useSelector((state) => state.users.value);

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />} />
      <Route
        path="admin/*"
        element={
          (userData.isAuthenticated && userData.role === "ADMIN") ||
          userData.role === "SUBADMIN" ? (
            <AdminLayout />
          ) : (
            <AuthLayout />
          )
        }
      />
      <Route
        path="/admin/invoice/all"
        element={
          (userData.isAuthenticated && userData.role === "ADMIN") ||
          userData.role === "SUBADMIN" ? (
            <AdminLayout>
              <ShowInvoices />
            </AdminLayout>
          ) : (
            <AuthLayout />
          )
        }
      />
      <Route
        path="/admin/invoice/showCustomerList"
        element={
          (userData.isAuthenticated && userData.role === "ADMIN") ||
          userData.role === "SUBADMIN" ? (
            <AdminLayout>
              <ShowCustomerList />
            </AdminLayout>
          ) : (
            <AuthLayout />
          )
        }
      />
      <Route
        path="/admin/invoice/showCashVsUpiDateWise"
        element={
          (userData.isAuthenticated && userData.role === "ADMIN") ||
          userData.role === "SUBADMIN" ? (
            <AdminLayout>
              <ShowCashVsUpiDateWise />
            </AdminLayout>
          ) : (
            <AuthLayout />
          )
        }
      />
      {[
        {
          name: "Board Games",
          layout: "/admin",
          path: "boardgames",
          icon: <GiAbbotMeeple className="h-6 w-6" />,
          component: <BoardGames />,
          role: ["ADMIN", "SUBADMIN"],
        },
        {
          name: "Books",
          layout: "/admin",
          path: "books",
          icon: <FaBook className="h-6 w-6" />,
          component: <Book />,
          role: ["ADMIN", "SUBADMIN"],
        },
        {
          name: "Content",
          layout: "/admin",
          path: "content",
          icon: <FaParagraph className="h-6 w-6" />,
          component: <Content />,
          role: ["ADMIN", "SUBADMIN"],
        },
        {
          name: "Pool",
          layout: "/admin",
          path: "pool",
          icon: <GiEightBall className="h-6 w-6" />,
          component: <Pool />,
          role: ["ADMIN", "SUBADMIN"],
        },
        {
          name: "Reviews",
          layout: "/admin",
          path: "reviews",
          icon: <MdReviews className="h-6 w-6" />,
          component: <Review />,
          role: ["ADMIN", "SUBADMIN"],
        },
        {
          name: "FaQ",
          layout: "/admin",
          path: "FaQ",
          icon: <FaQuestionCircle className="h-6 w-6" />,
          component: <FAQ />,
          role: ["ADMIN", "SUBADMIN"],
        },
        {
          name: "Gallery",
          layout: "/admin",
          path: "Gallery",
          icon: <FaImages className="h-6 w-6" />,
          component: <Gallery />,
          role: ["ADMIN", "SUBADMIN"],
        },
        {
          name: "Event",
          layout: "/admin",
          path: "Event",
          icon: <BsCalendarEvent className="h-6 w-6" />,
          component: <Event />,
          role: ["ADMIN", "SUBADMIN"],
        },
      ].map((elem) => {
        return (
          <Route
            path={"admin/cms/" + elem.path}
            element={
              (userData.isAuthenticated && userData.role === "ADMIN") ||
              userData.role === "SUBADMIN" ? (
                <AdminLayout>{elem.component}</AdminLayout>
              ) : (
                <AuthLayout />
              )
            }
          />
        );
      })}
      <Route path="/parties" element={<Parties />} />
      <Route
        path="/parties/:id"
        element={userData.isAuthenticated ? <MeesageBox /> : <UserLoginPage />}
      />

      <Route path="/invoice/:id" element={<DisplayInvoice />} />
      <Route path="/libraryInvoice/:id" element={<DisplayLibraryInvoice />} />
      {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
      <Route path="/" element={<Home />} />
      <Route path="/boardgames/view" element={<BoardGame />} />
      <Route path="/boardgames/view/:id" element={<BoardgameDisplay />} />
      <Route path="/books/view" element={<Books />} />
      <Route path="/books/view/:id" element={<BookDisplay />} />
      <Route path="/pool" element={<Pool />} />
      <Route path="/foodAndBeverages" element={<FoodBeverages />} />
      <Route path="/reservations" element={<Reservations />} />
      <Route path="/gallery" element={<GalleryMainPage />} />
      <Route path="/aboutus" element={<AboutUs />} />
      {/* <Route path="/" element={<Navigate to="/admin" replace />} /> */}
    </Routes>
  );
};

export default App;
