import React from "react";

// Admin Imports
import MainDashboard from "views/admin/default";

// Icon Imports
import {
  MdCarRental,
  MdGames,
  MdHome,
} from "react-icons/md";

import { TbCurrencyDollar, TbDiscount } from "react-icons/tb"
import Discount from "views/admin/discount";
import Product from "views/admin/products";
import Invoice from "views/admin/invoice";
import { GiBigGear} from "react-icons/gi";
import { BiBookBookmark, BiLibrary, BiUserPlus } from 'react-icons/bi'
import Library from "views/admin/library";
import { RiSendToBack } from "react-icons/ri";
import DepoistAmount from "views/admin/depositAmount";
import Moderator from "views/admin/moderator";
import CMS from "views/admin/cms";
import AdminReservation from "views/admin/reservation";

//AdminReservation
const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "default",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
    role:['ADMIN',]
  },
  {
    name: "Invoice",
    layout: "/admin",
    path: "invoice",
    icon: <TbCurrencyDollar className="h-6 w-6" />,
    component: <Invoice />,
    role:['ADMIN','SUBADMIN']
  },
  {
    name: "Rental",
    layout: "/admin",
    path: "rental",
    icon: <MdCarRental className="h-6 w-6" />,
    component: <Library />,    
    role:['ADMIN','SUBADMIN']
  },
  {
    name: "Reservations",
    layout: "/admin",
    path: "reservations",
    icon: <BiBookBookmark className="h-6 w-6" />,
    component: <AdminReservation />,    
    role:['ADMIN','SUBADMIN']
  },
  {
    name: "Discount",
    layout: "/admin",
    path: "discount",
    icon: <TbDiscount className="h-6 w-6" />,
    component: <Discount />,
    role:['ADMIN']
  },
  {
    name: "Product",
    layout: "/admin",
    path: "product",
    icon: <MdGames className="h-6 w-6" />,
    component: <Product />,
    role:['ADMIN','SUBADMIN']
  },{
    name: "Rent Amount",
    layout: "/admin",
    path: "rentAmount",
    icon: <RiSendToBack className="h-6 w-6" />,
    component: <DepoistAmount />,
    role:['ADMIN']
  },
  {
    name: "Moderators",
    layout: "/admin",
    path: "moderators",
    icon: <BiUserPlus className="h-6 w-6" />,
    component: <Moderator />,
    role:['ADMIN']
  },{
    name: "CMS",
    layout: "/admin",
    path: "cms",
    icon: <GiBigGear className="h-6 w-6" />,
    component: <CMS />,
    role:['ADMIN','SUBADMIN']  
  }
  //Event
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "sign-in",
  //   icon: <MdLock className="h-6 w-6" />,
  //   component: <SignIn />,
  // },
];
export default routes;
