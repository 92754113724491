import React from "react";
import { Accordion } from "flowbite-react";

const FaQComponent = (props) => {
  const data = props.data;
  console.log(data);
  return (
    <>
      <section className="bg-white  dark:bg-gray-900">
        <div className="mx-auto max-w-screen-2xl py-5 px-4 md:py-8 lg:py-24 lg:px-6">
          <h2 className="mb-6 text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:mb-8 lg:text-3xl">
            Frequently asked questions
          </h2>
          <div className="mx-auto max-w-screen-md">
            <Accordion collapseAll className="border-0">
              {data?.map((elem) => {
                return (
                  <Accordion.Panel>
                    <Accordion.Title className="flex w-full  items-center justify-between border-b border-gray-200 bg-white py-5 text-left font-medium text-gray-900 dark:border-gray-700 dark:bg-gray-900 dark:text-white">
                      {/* What is Flowbite? */}
                      {elem.Question}
                    </Accordion.Title>
                    <Accordion.Content className="border-b border-gray-200 py-5 dark:border-gray-700">
                      {elem.Answers.map((element) => {
                        return (
                          <p className="mb-2 text-gray-123 dark:text-gray-400">
                            {element}
                          </p>
                        );
                      })}
                    </Accordion.Content>
                  </Accordion.Panel>
                );
              })}
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default FaQComponent;
