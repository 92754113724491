import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import usersReducer from "./features/user";
import boardgamesReducer from "./features/boardgames";
import booksReducer from "./features/books";
import refresherReducer from "./features/refresher";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import App from "./App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ScrollToTop from "scrollToTop";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Realtime } from "ably";
import { AblyProvider } from "ably/react";

const client = new Realtime({
  key: "2auv1A.x8yU-g:PEq4zBdqU32qZngR6NK3wKYdKtkAYCv7feUZzzM-zBE",
  clientId: "my-client-id",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = configureStore({
  reducer: {
    users: usersReducer,
    boardgames: boardgamesReducer,
    books: booksReducer,
    refresher:refresherReducer
  },
});

root.render(
  <>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
    <GoogleOAuthProvider clientId="339916103018-4i8glf48hrou4uqek9f59ifr7k8a1i5q.apps.googleusercontent.com">
    <AblyProvider client={client}>
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <App />
        </BrowserRouter>
      </Provider>
    </AblyProvider>
    </GoogleOAuthProvider>
  </>
);
