import React from "react";
import { Accordion } from "flowbite-react";

const FAQ = (props) => {
  const data = props.data;
  console.log(data);
  return (
    <>
      <Accordion collapseAll className="border-0" >
        {data?.map((elem) => {
          return (
            <Accordion.Panel>
                <Accordion.Title className="flex items-center  justify-between w-full py-5 font-medium text-left text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white">
                  {/* What is Flowbite? */}
                  {elem.Question}
                </Accordion.Title>
                <Accordion.Content className="py-5 border-b border-gray-200 dark:border-gray-700">
                  {elem.Answers.map((element) => {
                    return <p className="mb-2 text-gray-123 dark:text-gray-400">{element}</p>;
                  })}
                </Accordion.Content>
              </Accordion.Panel>
          );
        })}
      </Accordion>
    </>
  );
};

export default FAQ;
