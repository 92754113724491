import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import { updateRefresher } from "features/refresher";


const AddDeposit = () => {

    const [isLoading,setIsLoading] = useState(false);

    //States for price  
    const [name,setname] = useState("")  
    const [price,setprice] = useState("");
    
    //errors for price 
    const [nameMessage,setnameMessage] = useState("");
    const [nameState,setnameState] = useState();
    
    const [priceMessage,setpriceMessage] = useState("");
    const [priceState,setpriceState] = useState();
    
    //validation logic
    useEffect(() => {
      if(name === ""){
        setnameMessage("Name cannot be empty!");
        setnameState()
      }
      else {
        setnameMessage("");
        setnameState('success');
      }
    
      if(price === ""){
        setpriceMessage("")
        setpriceState()
      }else if (price< 0) {
        setpriceMessage("Price must be a Positive Integer!");
        setpriceState('error')
      } else {
        setpriceMessage("");
        setpriceState('success')
      }
    }, [name, price]);
    
    function isValidGmailname(name) {
      // Regular expression for name validation ending with "@gmail.com"
      const gmailRegex = /^[a-zA-Z0-9._-]+@meepleton\.com$/;
    
      return gmailRegex.test(name);
    }
    
    //importing axios
    
    const axiosInstance = useAxiosInstance();
    const dispatch = useDispatch();
    const refresh = useSelector(state=>state.refresher.value.refreshCount)

    const handleLogin = (e) =>{
      e.preventDefault();
      setIsLoading(true);
      axiosInstance
        .post("/deposit", {
          name: name,
          price: price,
        })
        .then((res) => {
          if (res.data) {
            toast.success(`${res.data.name} Deposit Amount created Successfully`);
            dispatch(updateRefresher({refreshCount:refresh+1}))
          } else {
            toast.error("Something went wrong!");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.errors.msg);
          setIsLoading(false);
        });
    }

    
return (
    <div className=" bg-white p-4 rounded-primary  dark:border-navy-900 dark:bg-navy-800 ">
        {/* <img src={navlogo} height={100}/> */}
        {/* name */}
        <form onSubmit={(e)=>{handleLogin(e)}}>
        {nameMessage&&<span className="text-red-400 ms-1">{nameMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Name"
          placeholder="Enter Deposit Amount Name"
          id="name"
          type="name"
          state={nameState}
          value={name}
          onChange={(e)=>{
            setname(e.target.value)
          }}
        />

        {/* Price */}
        
        {priceMessage&&<span className="text-sm text-red-500 ms-1">{priceMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Price"
          placeholder="Enter Price"
          id="price"
          type="Number"
          state={priceState}
          value={price}
          onChange={(e)=>{
            setprice(e.target.value)
          }}
        />
        {isLoading?<button disabled className="linear text-center mt-2 px-4 rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          <Loading/>          
        </button>:nameMessage ==="" && priceMessage ==="" && <button
        type={'submit'} 
        className="linear mt-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Add
        </button>
        }
        </form>
      </div>
  )
}

export default AddDeposit
