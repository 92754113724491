import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import SelectField from "components/fields/selectField";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { updateRefresher } from "features/refresher";

const AddDiscount = (props) => {
  const data = props.data;
  const [isLoading, setIsLoading] = useState(false);

  //refresher setup
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);

  //States for Deposit Forms
  const [type, setType] = useState("");
  const [typeNew, setTypeNew] = useState("");
  const [image, setimage] = useState([]);

  //Image Validation
  const [imageMessage, setImageMessage] = useState("");
  const [imageState, setImageState] = useState("");

  //Type Validation
  const [typeMessage, setTypeMessage] = useState("");
  const [typeState, setTypeState] = useState("");

  //type new Validation
  const [typeNewMessage, setTypeNewMessage] = useState("");
  const [typeNewState, setTypeNewState] = useState("");

  //validation logic
  // useEffect(() => {
  //   if(name === ""){
  //     setnameMessage("Name cannot be empty!");
  //     setnameState()
  //   }
  //   else {
  //     setnameMessage("");
  //     setnameState('success');
  //   }

  //   if(price === ""){
  //     setpriceMessage("")
  //     setpriceState()
  //   }else if (price> 0) {
  //     setpriceMessage("Price must be a Negative Integer!");
  //     setpriceState('error')
  //   } else {
  //     setpriceMessage("");
  //     setpriceState('success')
  //   }
  // }, [name, price]);

  //importing axios

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const axiosInstance = useAxiosInstance();

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    image.forEach((imageEntity,index)=>{
      axiosInstance
      .post("/gallery", {
        type: typeNew ? typeNew : type,
        images: imageEntity,
      })
      .then((res) => {
        if (res.data) {
          toast.success(`Gallery for ${res.data.Type} created Successfully`);
          if(
            index == image.length-1
          ){
            dispatch(updateRefresher({ refreshCount: refresh + 1 }));
            setTypeNew("")
            setimage([])
            setType("")
          }
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
    })
  };
  const handleImage = async (e) => {
    try {
      toast.info("Images are uploading to database");
      for (const element of e.target.files) {
        const data = new FormData();
        data.append("file", element);
        data.append("upload_preset", "Meepleton");
        data.append("cloud_name", "dqzute9m4");

        const resp = await fetch(
          "https://api.cloudinary.com/v1_1/dqzute9m4/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await resp.json();
        setimage((prev) => [...prev, imageData.secure_url]);
      }
      toast.success("Images are successfully Uploaded to database");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className=" rounded-primary bg-white p-4  dark:border-navy-900 dark:bg-navy-800 ">
      {/* <img src={navlogo} height={100}/> */}
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        {/* image */}
        {imageMessage && (
          <span className="text-red-400 ms-1">{imageMessage}</span>
        )}
        <div>
          <label className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white">
            Choose Images
          </label>
        </div>
        <input
          className="mt-2 w-full rounded-xl border p-3 text-navy-800 file:rounded-md file:border-0  file:bg-gray-100"
          multiple={true}
          variant="auth"
          type="file"
          label="Image"
          placeholder="Choose image"
          id="image"
          state={imageState}
          onChange={(e) => handleImage(e)}
        />
        <div className="mt-2 max-w-xs">
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            //ssr={true} // means to render carousel on server-side.
            infinite={false}
            autoPlay={false}
            autoPlaySpeed={2500}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass=""
            // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
            //deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {image?.map((elem, index) => {
              return (
                <div key={index}>
                  <img className="h-80 w-full rounded-xl" src={elem} />
                </div>
              );
            })}
          </Carousel>
        </div>

        {/* Answer */}

        {typeMessage && (
          <span className="text-sm text-red-500 ms-1">{typeMessage}</span>
        )}
        <SelectField
          variant="auth"
          label="Type"
          placeholder="Enter Type"
          id="type"
          type="text"
          state={typeState}
          value={type}
          onChange={(e) => {
            setType(e.value);
          }}
          options={props.data}
        />
        {type === "NEW" && (
          <InputField
            variant="auth"
            extra="mb-3"
            label="New Type"
            placeholder="Enter new Type"
            id="image"
            type="text"
            state={typeNewState}
            value={typeNew}
            onChange={(e) => {
              setTypeNew(e.target.value.toUpperCase());
            }}
          />
        )}
        {isLoading ? (
          <button
            disabled
            className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <Loading />
          </button>
        ) : (
          imageMessage === "" &&
          typeMessage === "" &&
          imageMessage === "" && (
            <button
              type={"submit"}
              className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add
            </button>
          )
        )}
      </form>
    </div>
  );
};

export default AddDiscount;
