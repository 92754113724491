import HeaderComponent from "components/Header";
import React from "react";

const HeaderSection = (props) => {
  const data = props.data
  return (
    <>
      <HeaderComponent title={'Food & Beverages'} para={data}/>
    </>
  );
};

export default HeaderSection;
