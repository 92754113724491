import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import SelectField from "components/fields/selectField";
import { updateRefresher } from "features/refresher";

const AddDiscount = () => {
  const [isLoading, setIsLoading] = useState(false);

  //refresher setup
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);

  //States for Deposit Forms
  const [answer, setAnswer] = useState([]);
  const [type, setType] = useState("");
  const [question, setQuestion] = useState("");

  //Answer Validation
  const [answerMessage, setAnswerMessage] = useState("");
  const [answerState, setAnswerState] = useState("");

  //Type Validation
  const [typeMessage, setTypeMessage] = useState("");
  const [typeState, setTypeState] = useState("");

  //Question Validation
  const [questionMessage, setQuestionMessage] = useState("");
  const [questionState, setQuestionState] = useState("");

  //validation logic
  // useEffect(() => {
  //   if(name === ""){
  //     setnameMessage("Name cannot be empty!");
  //     setnameState()
  //   }
  //   else {
  //     setnameMessage("");
  //     setnameState('success');
  //   }

  //   if(price === ""){
  //     setpriceMessage("")
  //     setpriceState()
  //   }else if (price> 0) {
  //     setpriceMessage("Price must be a Negative Integer!");
  //     setpriceState('error')
  //   } else {
  //     setpriceMessage("");
  //     setpriceState('success')
  //   }
  // }, [name, price]);

  //importing axios

  const axiosInstance = useAxiosInstance();

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post("/faq", {
        Answers: answer,
        Type: type,
        Question: question,
      })
      .then((res) => {
        if (res.data) {
          toast.success(`FAQ for ${res.data.Type} created Successfully`);
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };

  return (
    <div className=" rounded-primary bg-white p-4  dark:border-navy-900 dark:bg-navy-800 ">
      {/* <img src={navlogo} height={100}/> */}
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        {/* Question */}
        {questionMessage && (
          <span className="text-red-400 ms-1">{questionMessage}</span>
        )}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Question"
          placeholder="Enter Question"
          id="Question"
          type="text"
          state={questionState}
          value={question}
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
        />

        {/* Answer */}

        {answerMessage && (
          <span className="text-sm text-red-500 ms-1">{answerMessage}</span>
        )}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Answer(use '|'for more than one Para)"
          placeholder="Enter Answer"
          id="answer"
          type="text"
          state={answerState}
          value={answer.join("|")}
          onChange={(e) => {
            setAnswer(e.target.value.split("|"));
          }}
        />

        {/* Answer */}

        {typeMessage && (
          <span className="text-sm text-red-500 ms-1">{typeMessage}</span>
        )}
        <SelectField
          variant="auth"
          label="Type"
          placeholder="Enter Type"
          id="type"
          type="text"
          state={typeState}
          value={type}
          onChange={(e) => {
            setType(e.value);
          }}
          options={[
            { label: "Home Page", value: "HOME" },
            { label: "Board game Page", value: "BG" },
            { label: "Book Page", value: "BOOK" },
            { label: "Pool Page", value: "POOL" },
            { label: "FNB Page", value: "FNB" },
            { label: "Reservation Page", value: "RESERVATIONS" },
            { label: "Parties Page", value: "PARTIES" },
          ]}
        />
        {isLoading ? (
          <button
            disabled
            className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <Loading />
          </button>
        ) : (
          answerMessage === "" &&
          typeMessage === "" &&
          questionMessage === "" && (
            <button
              type={"submit"}
              className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add
            </button>
          )
        )}
      </form>
    </div>
  );
};

export default AddDiscount;
