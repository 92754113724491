import React from 'react'

const SecondaryHeading = (props) => {
  return (
    <div>
      <h4 className="mb-2.5 mt-5 bg-white p-3 outline-none  rounded-primary text-xl dark:border-navy-900 dark:bg-navy-800 font-bold round text-navy-700 dark:text-white">
          {props.title}
        </h4>
    </div>
  )
}

export default SecondaryHeading