import PrimaryHeading from 'components/heading/PrimaryHeading'
import MainTable2 from 'components/table/Tablev2'
import React from 'react'

const ShowCashVsUpiDateWise = () => {
  return (
    <div>
      <PrimaryHeading title={'Datewise CASH and UPI distribution'}/>  
      <MainTable2
            url="/invoice/CashVsUpiDateWise"
            tableKeys={["date", "upiTotal",'cashTotal']}
            editFunc={()=>{alert("This componet doesn't support this feature")}}
            deleteFunc={()=>{alert("This componet doesn't support this feature")}}
          />
    </div>
  )
}

export default ShowCashVsUpiDateWise
