import React from "react";

const PoolComponent = (props) => {
  const { name, price } = props.data;
  return (
    <div className="w-full flex border-b-2 border-white mb-2 justify-between">
        <span className="text-white font-poppins font-normal text-sm  md:text-base lg:text-lg">{name}</span>
        <span className="font-poppins font-normal w-9 flex justify-center bg-white text-teal-400 text-sm  md:text-base lg:text-lg">{price}</span>
    </div>
   );
};

export default PoolComponent;