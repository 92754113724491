import React from "react";
import AddGallery from "./components/addGallery";
import TableGallery from "./components/tableGallery";
import PrimaryHeading from "components/heading/PrimaryHeading";
import SecondaryHeading from "components/heading/SecondaryHeading";
import useAxiosInstance from "../../../axiosInstance";
import Loading from "components/loading";
import { toast } from "react-toastify";

const Gallery = () => {
  const [data, setData] = React.useState({});
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("/gallery/all")
      .then((res) => {
        setData(res.data);
        //toast.success('Data loaded Successfully!')
        console.log(res.data);

        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading(false);
      });
  }, []);

  if (isLoading === true) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div>
      <PrimaryHeading title={"Add Gallery"} />
      <AddGallery data={data.category}/>
      <SecondaryHeading title={"Gallerys"} />
      <TableGallery data={data.category} />
    </div>
  );
};

export default Gallery;
