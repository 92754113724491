import React from "react";
import "../user/style.css";
import ad from "../../assets/img/layout/navlogo.png";
import {
  GiBookCover,
  GiChessKing,
  GiChessKnight,
  GiEightBall,
  GiMeeple,
  GiMeepleKing,
  GiVideoConference,
} from "react-icons/gi";
import {
  MdCall,
  MdEmail,
  MdEmojiFoodBeverage,
  MdLocationPin,
  MdMenu,
} from "react-icons/md";
import {
  FaBookmark,
  FaFacebook,
  FaHome,
  FaHourglass,
  FaInstagram,
  FaLinkedin,
  FaUserFriends,
  FaYoutube,
} from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import { HiX } from "react-icons/hi";


const UserLayout = (props) => {
  // console.log(props);
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <header className="fixed w-full z-[5000] font-sans">
        <nav className="border-gray-200 bg-white py-2.5 dark:bg-gray-900">
          <div className="mx-auto flex max-w-screen-2xl py-1 flex-wrap items-center justify-between px-4">
            <Link to="/" className="flex items-center">
              <img src={ad} className="h-10 md-max:h-7"/>
            </Link>
            <div className="flex items-center lg:order-2">
              {/* <a href="#" class="text-gray-800 dark:text-white  focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Log in</a> */}
              <Link
                // href="https://themesberg.com/product/tailwind-css/landing-page"
                to={'/reservations'}
                className="rounded-lg bg-teal-400 px-4 py-2 text-sm font-medium text-white hover:bg-teal-500 focus:outline-none focus:ring-4 focus:ring-teal-200 dark:bg-teal-500 dark:hover:bg-teal-600 dark:focus:ring-teal-700 sm:mr-2 lg:mr-0 lg:px-5 lg:py-2.5"
              >
                Book table
              </Link>
              <MdMenu
                onClick={() => {
                  open ? setOpen(false) : setOpen(true);
                }}
                className="hidden text-2xl ml-2 text-teal-500 md-max:block "
              />
            </div>
            <div
              className={`sm:none duration-175 linear fixed !z-50 left-0 flex top-14 flex-col h-screen bg-white shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-50 ${
                open ? "translate-x-0" : "-translate-x-96"
              }`}
            >
              <span
                className="absolute top-4 right-4 block cursor-pointer xl:hidden"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <HiX className="rounded-full text-navy-900" />
              </span>
              <div className="px-8 py-5  font-normal text-navy-900 ">
              <NavLink to={"/"} exact className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"} end>
                  <div className="block  py-2   dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Home
                  </div>
                </NavLink>

                <NavLink to={"/boardgames/view"} className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}>
                  <div className="block  py-2    dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Board Games
                  </div>
                </NavLink>
                <NavLink to={"/books/view"} className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}>
                  <div className="block  py-2    dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Books
                  </div>
                </NavLink>
                <NavLink to={"/pool"} className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}>
                  <div className="block  py-2    dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Pool
                  </div>
                </NavLink>
                {/* //foodAndBeverages */}
                <NavLink
                  to={"/foodAndBeverages"}
                  className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}
                >
                  <div className="block  py-2    dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Food & Beverages
                  </div>
                </NavLink>

                <NavLink
                  to={"/gallery"}
                  className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}
                >
                  <div className="block  py-2    dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Gallery
                  </div>
                </NavLink>
                <NavLink
                  to={"/aboutus"}
                  className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}
                >
                  <div className="block  py-2    dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    About Us
                  </div>
                </NavLink>
              </div>
            </div>
            <div
              className="hidden w-full items-center justify-between lg:order-1 lg:flex lg:w-auto"
              id="mobile-menu-2"
            >
              <ul className="mt-4 flex flex-col font-medium lg:mt-0 lg:flex-row lg:space-x-8">
                <NavLink to={"/"} exact className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"} end>
                  <div className="block border-b border-gray-100 py-2 pl-3 pr-4  dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Home
                  </div>
                </NavLink>

                <NavLink to={"/boardgames/view"} className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}>
                  <div className="block border-b border-gray-100 py-2 pl-3 pr-4   dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Board Games
                  </div>
                </NavLink>
                <NavLink to={"/books/view"} className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}>
                  <div className="block border-b border-gray-100 py-2 pl-3 pr-4   dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Books
                  </div>
                </NavLink>
                <NavLink to={"/pool"} className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}>
                  <div className="block border-b border-gray-100 py-2 pl-3 pr-4   dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Pool
                  </div>
                </NavLink>
                {/* //foodAndBeverages */}
                <NavLink
                  to={"/foodAndBeverages"}
                  className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}
                >
                  <div className="block border-b border-gray-100 py-2 pl-3 pr-4   dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Food & Beverages
                  </div>
                </NavLink>
                <NavLink
                  to={"/gallery"}
                  className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}
                >
                  <div className="block border-b border-gray-100 py-2 pl-3 pr-4   dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    Gallery
                  </div>
                </NavLink>
                <NavLink
                  to={"/aboutus"}
                  className={({ isActive }) =>
              isActive ? "text-teal-400" : "text-gray-800"}
                >
                  <div className="block border-b border-gray-100 py-2 pl-3 pr-4   dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white lg:border-0 lg:p-0 lg:hover:bg-transparent lg:hover:text-teal-400 lg:dark:hover:bg-transparent lg:dark:hover:text-white">
                    About Us
                  </div>
                </NavLink>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <main className="font-sans">
        <div>{props.children}</div>
      </main>
      <footer className="bg-white font-sans dark:bg-gray-900">
        <div className="container mx-auto w-full py-6 sm-max:p-4 md-max:px-5 lg:py-8">
          <div className="flex flex-col md:items-center justify-between">
            <div className="mb-6 mt-3 md:mb-0">
              <Link
                to="/"
                className="-m-1 flex md-max:items-center  md-max:justify-center"
              >
                <img src={ad} className="mr-3 h-10" alt="Meepleton Logo" />
              </Link>
              {/* <div className="max-w-full  text-gray-123 md-max:text-center lg:max-w-xs ">
                Meepleton is a Board Game Cafe where you can bond and spend
                quality time with your friends and family while playing fun and
                engaging board games. We not only provide popular board games
                like CODENAMES, PICTIONARY, PANDEMIC, TICKET TO RIDE, CATAN, etc
                but we also provide a library for books lovers. Our book shelf
                contains best books of different genres.
                <br />
                To top it all, we also have a POOL TABLE.
              </div> */}
            </div>
            <div className="mt-6 grid grid-cols-1  gap-6 md:grid md:grid-cols-4 md:gap-12">
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Services
                </h2>
                <ul className="text-navy-900 dark:text-gray-400">
                  <li className="mb-2">
                    <Link to={'/boardgames/view'} className="text-gray-123 dark:text-gray-400">
                      {" "}
                      Board Games
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to={'/books/view'} className="text-gray-123 dark:text-gray-400">
                    Books
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to={'/pool'} className="text-gray-123 dark:text-gray-400">
                     Pool
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to={'/foodAndBeverages'} className="text-gray-123 dark:text-gray-400">
                    Food & Beverages
                    </Link>
                  </li>
                  <li className="mb-2">
                    <Link to={'/parties'} className="text-gray-123 dark:text-gray-400">
                    Parties
                    </Link>
                  </li>
                  <li className="">
                    <Link to={'/reservations'} className="text-gray-123 dark:text-gray-400">
                     Reservations
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Request Form
                </h2>
                <ul className=" text-gray-123">
                  <label className="">Name</label>
                  <br />
                  <input className="mt-1 h-9 w-full rounded-md bg-gray-50 pl-2" />
                </ul>
                <ul className="mt-2  text-gray-123">
                  <label className="">Book or Board game Name</label>
                  <br />
                  <input className="mt-1 h-9 w-full rounded-md bg-gray-50 pl-2" />
                </ul>
                <button className="mt-2 rounded-md bg-teal-500 px-3 py-1 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200">
                  <div className="flex items-center justify-center font-semibold">
                    Submit
                  </div>
                </button>
              </div>
              <div>
                <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Contact
                </h2>
                <a target="_blank" href="https://www.google.com/maps/place/Meepleton+Board+Game+Cafe/@23.1734382,77.4250563,15z/data=!4m6!3m5!1s0x397c4385ededf0b5:0xc20b3674648b9e52!8m2!3d23.1734382!4d77.4250563!16s%2Fg%2F11t30n5z7v?entry=ttu" className="mb-2 flex items-center  text-gray-123 hover:text-gray-800">
                  <MdLocationPin className="me-2" /> DK-2/232, Danish Kunj Kolar
                  Rd,
                  <br /> Bhopal, Madhya Pradesh 462042
                </a>
                
                <a href={'tel:+91 8989095732'} className="mb-2 flex items-center  text-gray-123 hover:text-gray-800">
                  <MdCall className="me-2" /> +91 8989208432
                </a>
                <p className="flex gap-4 mt-3">
                <a 
                href={'https://www.instagram.com/meepleton_boardgamecafe/'}
                target="_blank"
                className="flex items-center  text-gray-123  hover:text-gray-800 ">
                  <FaInstagram className=" text-2xl" />
                </a>
                <a 
                href={'https://www.linkedin.com/company/meepleton-board-game-cafe/'}
                target="_blank"
                className="flex items-center  text-gray-123  hover:text-gray-800">
                  <FaLinkedin className="text-2xl" />
                </a>
                <a 
                href={'mailto:meepleton.bgc@gmail.com'}
                //target="_blank"
                className="flex items-center  text-gray-123  hover:text-gray-800">
                  <MdEmail className="text-2xl" />
                </a>
                <a 
                href={'https://www.facebook.com/meepletonboardgamecafe?mibextid=ZbWKwL'}
                target="_blank"
                className="flex items-center  text-gray-123  hover:text-gray-800">
                  <FaFacebook className="text-2xl" />
                </a>
                <a 
                href={'https://youtube.com/@meepletonboardgamecafe3253?si=qloVX4GxZnCeEmNb'}
                target="_blank"
                className="flex items-center  text-gray-123  hover:text-gray-800">
                  <FaYoutube className="text-2xl" />
                </a>
                </p>
              </div>
              <div>
              <h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 dark:text-white">
                  Timing
                </h2>
                <div className="flex items-center  text-gray-123  hover:text-gray-800">
                  <FaHourglass className="me-2" />
                  <div>
                    Wednesday to Monday
                    <br />
                    2:00 PM - 10:00 PM
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border sm:mx-auto lg:my-8" />
          <div className="sm:flex text-gray-123 sm:items-center sm:justify-between">
            <span className=" text-sm  dark:text-gray-400 sm:text-center">
              © 2023{" "}
              <a href="" className="hover:underline">
                Meepleton
              </a>
              . All Rights Reserved.
            </span>
            <a href='https://www.linkedin.com/in/rahul-parlani-b02a0a226/' className="mt-4 space-x-5 text-sm sm:mt-0 sm:justify-center">
              Developed by Rahul Parlani
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};                             

export default UserLayout;
