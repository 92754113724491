import React from "react";
import AddModerator from "./components/addModerator";
import TableModerator from "./components/tableModerator";
import PrimaryHeading from "components/heading/PrimaryHeading";
import SecondaryHeading from "components/heading/SecondaryHeading";
import useAxiosInstance from "../../../axiosInstance";
import Loading from "components/loading";
import { toast } from "react-toastify";

const Moderator = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <div>
      <PrimaryHeading title={"Add Moderator"} />
      <AddModerator />
      <SecondaryHeading title={"Moderators"} />
      <TableModerator />
    </div>
  );
};

export default Moderator;
