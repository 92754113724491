import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "components/loading";
import InputFieldTextArea from "components/fields/TextField";
import { updateRefresher } from "features/refresher";

const AddContent = () => {
  const [isLoading, setIsLoading] = useState(false);

  //States for price
  const [Content, setContent] = useState("");
  const [Type, setType] = useState("");
  
  //errors for Type
  const [ContentMessage, setContentMessage] = useState("");
  const [ContentState, setContentState] = useState();

  const [TypeMessage, setTypeMessage] = useState("");
  const [TypeState, setTypeState] = useState();

  
  //validation logic
  useEffect(()=>{
    if (Content === "") {
      setContentMessage("Content cannot be empty!");
      setContentState();
    } else {
      setContentMessage("");
      setContentState("success");
    }
    if (Type === "") {
      setTypeMessage("Type cannot be empty!");
      setTypeState();
    } else {
      setTypeMessage("");
      setTypeState("success");
    }
  },[Content,Type]) 

  //importing axios
  const axiosInstance = useAxiosInstance();
  const dispatch = useDispatch();
  const refresh = useSelector(state=>state.refresher.value.refreshCount)

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    axiosInstance
      .post("/content", {
        Content:Content,
        Type:Type            
      })
      .then((res) => {
        if (res.data) {
          toast.success(`Content with ${res.data.Type} created Successfully`);
          dispatch(updateRefresher({refreshCount:refresh+1}))
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };

  return (
    <div className=" rounded-primary  bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
      {/* <img src={navlogo} height={100}/> */}
      {/* name */}
      <form
        onSubmit={(e) => {
          handleLogin(e);
        }}
      >
        {ContentMessage && (
          <span className="text-red-400 ms-1">{ContentMessage}</span>
        )}
        <InputFieldTextArea
          variant="auth"
          extra="mb-3"
          label="Content Para"
          placeholder="content para here"
          id="name"
          type="name"
          required
          state={ContentState}
          value={Content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
        {/* type */}
        {TypeMessage && (
          <span className="text-red-400 ms-1">{TypeMessage}</span>
        )}
        <label
          htmlFor="Type"
          className="mb-2 ms-3 block text-sm font-medium text-gray-900 dark:text-white"
        >
          Type
        </label>
        <select
          id="Type"
          required
          value={Type}
          onChange={(e)=>{setType(e.target.value)}}
          className="mt-2 flex h-12 w-full items-center dark:text-gray-400 dark:border-gray-800 justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
        >
          <option value="" selected>Choose</option>
          <option value="BG">Board game</option>
          <option value="BOOK">Book</option>
          <option value="POOL">Pool</option>
          <option value="FNB">Food and Beverages</option>
          <option value="GALLERY">Gallery</option>
        </select>

        {isLoading ? (
          <button
            disabled
            className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            <Loading />
          </button>
        ) :
        TypeMessage===""&&ContentMessage ===""&&
        (
            <button
              type={"submit"}
              className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            >
              Add 
            </button>
          )
        }
      </form>
    </div>
  );
};

export default AddContent;
