import React from "react";
import { MdStar } from "react-icons/md";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";

const Stars = (props) => {
  let arr = [0, 0, 0, 0, 0];
  let value = props.value;
  for (let index = 0; index < arr.length; index++) {
    if (value > 0) {
      arr[index] = 1;
      value--;
    }
  }
  return (
    <>
      {arr.map((elem) => {
        return elem === 1 ? (
          <div>
            <MdStar className="text-teal-400" />
          </div>
        ) : (
          <div>
            <MdStar className="text-gray-50" />
          </div>
        );
      })}
    </>
  );
};

const Testimonials = (props) => {
  const data = props.data;
  console.log(data);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <><div class="mx-auto max-w-screen-2xl">
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={false}
        responsive={responsive}
        //ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={2500}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass=""
        // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        //deviceType={this.props.deviceType}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        {data.map((elem, index) => {
          return (
            <div key={elem._id} className="px-4 py-8 max-w-screen-xl mx-auto text-center lg:py-24 lg:px-6">
                <figure class="mx-auto ">
                  <svg
                    class="mx-auto mb-3 h-12 text-gray-400 dark:text-gray-600"
                    viewBox="0 0 24 27"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                      fill="currentColor"
                    />
                  </svg>
                  <blockquote>
                    <p class="text-xl font-medium text-gray-900 dark:text-white md:text-2xl">
                      {elem.review}
                    </p>
                  </blockquote>
                  <figcaption class="mt-6 flex items-center justify-center space-x-3">
                    <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                      <div class="pr-3 font-medium text-gray-900 dark:text-white">
                        {elem.name}
                      </div>
                      <div class="flex pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                        <Stars value={elem.stars} />
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
          );
        })}
      </Carousel>
      </div>
    </>
  );
};

export default Testimonials;
