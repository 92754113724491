import Widget from "components/widget/Widget";
import React from "react";
import { BsCalendarEvent } from "react-icons/bs";
import { FaBook, FaImages, FaParagraph, FaQuestionCircle } from "react-icons/fa";
import { GiAbbotMeeple, GiEightBall } from "react-icons/gi";
import { MdReviews } from "react-icons/md";
import { Link } from "react-router-dom";

const CMS = () => {
  return (
    <div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-6">
        {[
          {
            name: "Board Games",

            path: "boardgames",
            icon: <GiAbbotMeeple className="h-6 w-6" />
          },
          {
            name: "Books",

            path: "books",
            icon: <FaBook className="h-6 w-6" />
          },
          {
            name: "Content",
            path: "content",
            icon: <FaParagraph className="h-6 w-6" />
          },
          {
            name: "Pool",

            path: "pool",
            icon: <GiEightBall className="h-6 w-6" />
          },
          {
            name: "Reviews",

            path: "reviews",
            icon: <MdReviews className="h-6 w-6" />
          },
          {
            name: "FaQ",

            path: "FaQ",
            icon: <FaQuestionCircle className="h-6 w-6" />,
            
          },
          {
            name: "Gallery",

            path: "Gallery",
            icon: <FaImages className="h-6 w-6" />,
            
          },
          {
            name: "Event",

            path: "Event",
            icon: <BsCalendarEvent className="h-6 w-6" />,
            
          },
        ].map((elem) => { return <Link to={`/admin/cms/${elem.path}`}>
          <Widget
            
            icon={elem.icon}
            //   title={"Total"}
            subtitle={elem.name}
          />
          </Link>
        })}
      </div>
    </div>
  );
};

export default CMS;
