import React from "react";

const PoolComponent = (props) => {
  const { title, videolink } = props.data;
  return (
    <div className="w-full rounded-xl bg-gray-300 border p-4">
          <iframe
            className="w-full lg:h-64 md:h-52 h-48 mb-2"
            src={videolink}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
      <span className="text-navy-800  text-sm  md:text-base lg:text-lg">{title}</span>
    </div>
   );
};

export default PoolComponent;
