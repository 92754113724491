import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useState } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SecondaryHeading from "components/heading/SecondaryHeading";
import InputFieldTextArea from "components/fields/TextField";
import MainTable2 from "components/table/Tablev2";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";

const TableContent = (props) => {
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setid] = React.useState("");

  //States for price
  const [Content, setContent] = useState("");
  const [Type, setType] = useState("");

  //errors for Type
  const [ContentMessage, setContentMessage] = useState("");
  const [ContentState, setContentState] = useState();

  const [TypeMessage, setTypeMessage] = useState("");
  const [TypeState, setTypeState] = useState();

  const dispatch = useDispatch();
  const refresh = useSelector(state=>state.refresher.value.refreshCount)

  //validation logic
  const submit = (e) => {
    e.preventDefault();
    if (Content === "") {
      setContentMessage("Content cannot be empty!");
      setContentState();
    } else {
      setContentMessage("");
      setContentState("success");
    }
    if (Type === "") {
      setTypeMessage("Type cannot be empty!");
      setTypeState();
    } else {
      setTypeMessage("");
      setTypeState("success");
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/content/" + id, {
        Content: Content,
        Type: Type,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'content "' + res.data.Type + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({refreshCount:refresh+1}))
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("content with this info already exists!");
        }
      });
    setIsLoading2(false);
  };

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " content ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/content/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("content is deleted Successfully!");
            // setData(data.filter((res) => res._id !== id));
            dispatch(updateRefresher({refreshCount:refresh+1}))
       
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    // const discountData = data.find((elem) => elem._id === id);
    onOpen();
    toast.info('Looking for Content in database')
    axiosInstance.get('/content/'+id).then(res=>{
    toast.success('Successfully Found!')
    const { _id, Content, Type } = res.data;
    setContent(Content);
    setType(Type);
    setid(_id);
    }).catch(err=>{toast.error('Something went wrong!')})
  };

  return (
    <>
      {/* <button onClick={()=>{setRefresh(refresh+1)}} className='linear mt-2 me-2 px-4 rounded-xl bg-brand-600 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'><MdRefresh/></button>  */}
      {(
        <div>
          <MainTable2
            url={'/content/allbyQuery'}
            tableKeys={["Content", "Type"]}
            refresh={refresh}
            editFunc={editFunc}
            deleteFunc={deleteFunc}
          />
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[25vh] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-lg border bg-white p-4 dark:border-navy-900 dark:bg-navy-800">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                {ContentMessage && (
                  <span className="text-red-400 ms-1">{ContentMessage}</span>
                )}
                 <InputFieldTextArea
          variant="auth"
          extra="mb-3"
          label="Content Para"
          placeholder="content para here"
          id="name"
          type="name"
          required
          state={ContentState}
          value={Content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
                {/* type */}
                {TypeMessage && (
                  <span className="text-red-400 ms-1">{TypeMessage}</span>
                )}
                <label
                  htmlFor="Type"
                  className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
                >
                  Type
                </label>
                <select
                  id="Type"
                  value={Type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none dark:border-gray-800 dark:text-gray-400"
                >
                  <option value="" selected>Choose</option>
                  <option value="BG">Board game</option>
                  <option value="BOOK">Book</option>
                  <option value="POOL">Pool</option>
                  <option value="FNB">Food and Beverages</option>
                </select>
                {isLoading2 ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  <button
                    type={"submit"}
                    className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Save Changes
                  </button>
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableContent;
