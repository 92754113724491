import React from "react";
import PrimaryHeading from "components/heading/PrimaryHeading";
import SecondaryHeading from "components/heading/SecondaryHeading";
import AddProduct from "./componets/addProduct";
import TableProduct from "./componets/tableProduct";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import { toast } from "react-toastify";

const Product = () => {
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);

  if (isLoading === true) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div>
      <PrimaryHeading title={"Add Product"} />
      <AddProduct />
      <SecondaryHeading title={"Gaming Products"} />
      <TableProduct/>
    </div>
  );
};

export default Product;
