import React from "react";
import { Link } from "react-router-dom";

const Boardgame = (props) => {
  const { bgImage, _id } = props.data;
  return (
    <div>
      <Link key={_id} to={"/boardgames/view/" + _id}>
        <div className={" w-full rounded-xl bg-[black]"}>
          <div className="z rounded-xl  hover:opacity-80">
            <img
              className="h-32 w-full rounded-xl  md:h-56 lg:h-[250px]"
              src={bgImage}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Boardgame;
