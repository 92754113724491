import React, { useEffect, useState } from "react";
import useAxiosInstance from "../../axiosInstance";
import $ from "jquery";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import { Link } from "react-router-dom";
import { MdDelete, MdEdit } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { useSelector } from "react-redux";

const BoardGame = (props) => {
  const { bg, keys, deleteFunc, editFunc, variant } = props;
  const userRole = useSelector((state) => state.users.value.role);
  return (
    <tr className="even:bg-teal-50 dark:text-white   dark:even:bg-navy-800">
      <td className="border-b-2 text-base font-medium dark:border-b-navy-900">
        {variant === "INVOICE" && userRole === "SUBADMIN" ? (
          <Link to={"/invoice/" + bg._id} target="_blank">
            <button className="rounded-xl bg-yellow-500  px-2 py-2 text-base font-medium text-white transition duration-200 me-2 hover:bg-yellow-600 active:bg-yellow-700 dark:bg-yellow-400 dark:text-white dark:hover:bg-yellow-300 dark:active:bg-yellow-200">
              <FaEye />
            </button>
          </Link>
        ) : (
          <>
          {variant === "INVOICE" && userRole === 'ADMIN' &&  <Link to={"/invoice/" + bg._id} target="_blank"> <button className="rounded-xl bg-teal-500  px-2 py-2 text-base font-medium text-white transition duration-200 me-2 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200">
              <FaEye />
            </button></Link>}
            <button
              onClick={() => {
                editFunc(bg["_id"]);
              }}
              className="rounded-xl bg-yellow-500  px-2 py-2 text-base font-medium text-white transition duration-200 me-2 hover:bg-yellow-600 active:bg-yellow-700 dark:bg-yellow-400 dark:text-white dark:hover:bg-yellow-300 dark:active:bg-yellow-200"
            >
              <MdEdit />
            </button>
            <button
              onClick={() => {
                deleteFunc(bg["_id"]);
              }}
              className="rounded-xl bg-red-500 px-2 py-2 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700 dark:bg-red-400 dark:text-white dark:hover:bg-red-300 dark:active:bg-red-200"
            >
              <MdDelete />
            </button>
          </>
        )}
      </td>
      {keys.map((key) => {
        return (
          <td
            className="border-b-2 text-sm font-medium dark:border-b-navy-900 "
            key={key}
          >
            {bg[key]}
          </td>
        );
      })}
    </tr>
  );
};

const MainTable = (props) => {
  const { tableKeys, tableData, deleteFunc, editFunc, refresh, variant } =
    props;
  //const [bgs, setBgs] = useState(tableData);
  const [keys, setKeys] = useState(tableKeys);

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable(props.tableId || "#table")) {
      $(document).ready(function () {
        setTimeout(function () {
          $(props.tableId || "#table")
            .DataTable({
              paging:false,   
              pagingType: "full_numbers",
              pageLength: 20,
              processing: true,
              retrieve: true,
              dom: "Bfrtip",
              select: {
                style: "single",
              },
              buttons: [
                {
                  extend: "pageLength",
                  className:
                    "rounded-full bg-teal-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200",
                },

                {
                  extend: "copy",
                  className:
                    "rounded-full bg-teal-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200",
                },
                {
                  extend: "csv",
                  className:
                    "rounded-full bg-teal-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200",
                },
                {
                  extend: "print",
                  customize: function (win) {
                    $(win.document.body).css("font-size", "10pt");
                    $(win.document.body)
                      .find("table")
                      .addClass("compact")
                      .css("font-size", "inherit");
                  },
                  className:
                    "rounded-full bg-teal-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-teal-600 active:bg-teal-700 dark:bg-teal-400 dark:text-white dark:hover:bg-teal-300 dark:active:bg-teal-200",
                },
              ],
              lengthMenu: [
                [10, 20, 30, 50, -1],
                [10, 20, 30, 50, "All"],
              ],
            })
            .columns()
            .every(function () {
              var column = this;
              var title = column.footer().textContent;

              $('<input type="text" placeholder="Search ' + title + '" />')
                .appendTo($(column.footer()).empty())
                .on("keyup change clear", function () {
                  if (column.search() !== this.value) {
                    column.search(this.value).draw();
                  }
                });
            });
        }, 0);
      });
    }
  }, [refresh]);

  const bgsList = () => {
    return tableData.map((bg) => {
      return (
        <BoardGame
          bg={bg}
          keys={keys}
          deleteFunc={deleteFunc}
          key={bg._id}
          editFunc={editFunc}
          link={props.isLink}
          variant={variant}
        />
      );
    });
  };

  return (
    <>
      <div className="">
        <div className="overflow-auto rounded-primary bg-white p-4 dark:bg-navy-800 dark:text-white">
          <table
            id={props.tableIdd || "table"}
            className=" table-striped align-items-center  justify-content-center mb-0"
          >
            <thead>
              <tr>
                <th className="text-sm uppercase  text-gray-400">Action</th>
                {keys?.map((key) => (
                  <th className="text-sm uppercase  text-gray-400" key={key}>
                    {key}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{bgsList()}</tbody>
            <tfoot>
              <tr>
                <th className="font-weight-bold text-xs">Action</th>

                {keys?.map((key) => (
                  <th
                    className="font-weight-bold text-xs"
                    key={key + "-footer"}
                  >
                    <input
                      type="text"
                      className="py-2 dark:bg-navy-900"
                      placeholder={key}
                      onChange={(e) => {
                        const table = $(props.tableId || "#table").DataTable();
                        table
                          .column(key + ":name")
                          .search(e.target.value)
                          .draw();
                      }}
                    />
                  </th>
                ))}
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </>
  );
};

export default MainTable;
