import React, { useEffect } from "react";
import InputField from "components/fields/InputField";
import { useState } from "react";
import useAxiosInstance from "../../axiosInstance";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../features/user";
import { toast } from "react-toastify";
import Loading from "components/loading";

export default function SignIn() {

//state for loading
const dispatch = useDispatch();
const [isLoading,setIsLoading] = useState(false);

//States for password  
const [email,setEmail] = useState("")  
const [password,setpassword] = useState("");

//errors for password 
const [emailMessage,setEmailMessage] = useState("");
const [emailState,setEmailState] = useState();

const [passwordMessage,setPasswordMessage] = useState("");
const [passwordState,setPasswordState] = useState();

//validation logic
useEffect(() => {
  if(email === ""){
    setEmailMessage("");
    setEmailState()
  }
  else if (isValidGmailEmail(email) === false) {
    setEmailMessage("Email must contain @meepleton.com");
    setEmailState("error")
  } else {
    setEmailMessage("");
    setEmailState('success');
  }

  if(password === ""){
    setPasswordMessage("")
    setPasswordState()
  }else if (password.length < 5) {
    setPasswordMessage("Password length must be at least 5 characters.");
    setPasswordState('error')
  } else {
    setPasswordMessage("");
    setPasswordState('success')
  }
}, [email, password]);

function isValidGmailEmail(email) {
  // Regular expression for email validation ending with "@gmail.com"
  const gmailRegex = /^[a-zA-Z0-9._-]+@meepleton\.com$/;

  return gmailRegex.test(email);
}

//importing axios

const axiosInstance = useAxiosInstance();

const handleLogin = (e) =>{
  e.preventDefault();
  setIsLoading(true);
  axiosInstance
    .post("/login", {
      email: email,
      password: password,
    })
    .then((res) => {
      if (res.data.user.role === "ADMIN" || res.data.user.role === "SUBADMIN") {
        dispatch(
          login({
            username: res.data.user.name,
            tokin: res.data.token,
            role: res.data.user.role,
            id: res.data.user._id,
           // isSuccessFullyRegistered: res.data.user.isSuccessFullyRegistered,
            verified: res.data.user.verified,
            //picUrl: res.data.user.picUrl,
          })
        );
        toast.success("Sign-in Successfully");
        localStorage.setItem(
          "meepleton-Admin-User",
          JSON.stringify({ email: email, password: password })
        );
      } else {
        toast.error("You are not a Admin");
      }
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
      toast.error(err.response.data.errors.msg);
      setIsLoading(false);
    });
}

return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        {/* <img src={navlogo} height={100}/> */}
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Welcome to Admin Panel
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>
        {/* Email */}
        <form onSubmit={(e)=>{handleLogin(e)}}>
        {emailMessage&&<span className="text-red-400 ms-1">{emailMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          placeholder="mail@meepleton.com"
          id="email"
          type="email"
          state={emailState}
          value={email}
          onChange={(e)=>{
            setEmail(e.target.value)
          }}
        />

        {/* Password */}
        
        {passwordMessage&&<span className="text-sm text-red-500 ms-1">{passwordMessage}</span>}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password"
          placeholder="Min. 5 characters"
          id="password"
          type="password"
          state={passwordState}
          value={password}
          onChange={(e)=>{
            setpassword(e.target.value)
          }}
        />
        {isLoading?<div className="linear text-center mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          <Loading/>          
        </div>:emailMessage ==="" && passwordMessage ==="" && <button
        type={'submit'} 
        className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Sign In
        </button>}
        </form>
      </div>
    </div>
  );
}
