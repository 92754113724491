import Loading from 'components/loading';
import React from 'react'
import { MdChat, MdChatBubble, MdDelete } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const formatDate = (givenDate) => {
  const gDate = new Date(givenDate);
  const day = gDate.getDate().toString().padStart(2, "0");
  const month = (gDate.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based, so add 1
  const year = gDate.getFullYear().toString();
  const time = `${gDate.getHours().toString().padStart(2, "0")}:${gDate.getMinutes().toString().padStart(2, "0")}`
  return `${day}/${month}/${year} ${time}`;
};

const PartyComponent = (props) => {
  const {_id,gameImg,title,participants,noOfUsers,time,desc,creatorId,isLoading} = props.data
  const userData = useSelector(state=>state.users.value);  
  return (
        <div class="mt-6 rounded-xl flex flex-col items-center  border border-gray-200 bg-white p-6 dark:border-gray-700 dark:bg-gray-800">
        <img className="w-40 h-40 rounded-xl mb-2" src={gameImg}/>
        <div className="w-full text-center">
        <a >
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {title}
          </h5>
        </a>
        
        <p className="mb-2  w-full font-normal text-gray-700 dark:text-gray-400">
          {desc}
        </p>
        <p className="mb-2  w-full font-normal text-gray-700 dark:text-gray-400">
          <span className="font-semibold">People Joined :</span> {participants?.length+'/'+noOfUsers}  
          <br/>
          <span className="font-semibold">Visiting Time :</span> {formatDate(time)}
        </p>
        <div className='flex space-x-2 justify-center'>
        {participants?.filter(elem=>elem.userId === userData.id)?.length!=0 ? <>
        <Link
          to={'/parties/'+_id}
          className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
        >
          Chat
        </Link> 
        <a
          onClick={()=>{props.isLoading ? console.log(): props.handleLeave(_id)}}
          className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-yellow-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-100 dark:border-yellow-700 dark:text-white dark:hover:bg-yellow-700 dark:focus:ring-yellow-800 sm:w-auto"
        >
         {props.isLoading ? <Loading/>: <>Leave</>} 
        </a>
        {creatorId === userData.id && <a
          onClick={()=>{ props.isLoading ? console.log(): props.handleDelete(_id)}}
          className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-red-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-red-500 focus:ring-4 focus:ring-red-100 dark:border-red-700 dark:text-white dark:hover:bg-red-700 dark:focus:ring-red-800 sm:w-auto"
        >
          {props.isLoading ? <Loading/>: <>Delete</>}  
        </a>
        }
        </>: 
        noOfUsers>=participants.length ?
        <a
          onClick={()=>props.handleJoin(_id)}
          className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
        >
         {props.isLoading?<Loading/> : <>Join <svg
            class="ml-2 h-3.5 w-3.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg></>} 
        </a> :
        <a
        // onClick={()=>props.handleJoin(_id)}
        className="inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-3 py-2 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
      >
       {props.isLoading?<Loading/> : <>Full <svg
          class="ml-2 h-3.5 w-3.5"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 14 10"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 5h12m0 0L9 1m4 4L9 9"
          />
        </svg></>} 
      </a>
        }
        
        </div>
        </div>
      </div>
  )
}

export default PartyComponent
