import Card from "components/card";
import { MdBarChart } from "react-icons/md";
import DonatChart from "components/charts/DonatChart";

const TopDiscounts = (props) => {
    const { data } = props
    const ChartOptions = {
        labels: data?.x,
        colors: ["#4318FF", "#6AD2FF","#00FFFF", "#FF00FF", "#32CD32", "#00BFFF", "#FF8C00"],
        chart: {
          width: "50px",
        },
        states: {
          hover: {
            filter: {
              type: "none",
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
        },
        hover: { mode: null },
        plotOptions: {
          donut: {
            expandOnClick: false,
            donut: {
              labels: {
                show: true,
              },
            },
          },
        },
        fill: {
          colors: ["#4318FF", "#6AD2FF","#00FFFF", "#FF00FF", "#32CD32", "#00BFFF", "#FF8C00" ],
        },
        tooltip: {
          enabled: true,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: undefined,
            backgroundColor: "#000000"
          },
        },
      };
        
  return (
    <Card extra="flex flex-col bg-white w-full rounded-3xl py-6 px-2 text-center">
      <div className="mb-auto flex items-center justify-between px-6">
        <h2 className="text-lg font-bold text-navy-700 dark:text-white">
          Top Discounts
        </h2>
        <button className="!linear z-[1] flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-brand-500 !transition !duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
          <MdBarChart className="h-6 w-6" />
        </button>
      </div>

      <div className="md:mt-16 lg:mt-0">
        <div className="h-[250px] w-full xl:h-[350px]">
          <DonatChart
            data={data?.y}
            options={ChartOptions}
          />
        </div>
      </div>
    </Card>
  );
};

export default TopDiscounts;