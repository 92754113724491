import InputField from "components/fields/InputField";
import SelectField from "components/fields/selectField";
import React, { useState } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import UserLayout from "layouts/user";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import { HiX } from "react-icons/hi";
import UserLoginPage from "./components/Login";
import { useSelector } from "react-redux";
import user from "features/user";
import PartyComponent from "./components/party";
import { useChannel } from "ably/react";
import UserLoginIndexPage from "./components/loginIndex";
import GlobalChatBox from "./components/globalChatBox";
import { MdLock } from "react-icons/md";

const Pools = () => {
  const userData = useSelector((state) => state.users.value);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = React.useState({});
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [isLoading3, setIsLoading3] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(
        `/conversation/?limit=${limit}&page=${page}&sort=_id&filter=false&fields=isDeleted`
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
        setIsLoading(false);
      });
  }, [limit, page, refresh]);

  React.useEffect(() => {
    setIsLoading2(true);
    axiosInstance
      .get(
        `/conversation/?limit=${limit}&page=${page}&sort=_id&filter=false&fields=isDeleted`
      )
      .then((res) => {
        // dispatch(updateData({ data: res.data }));

        setData(res.data);
        setIsLoading2(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");
        setIsLoading2(false);
      });
  }, []);

  const [fieldData, setFieldData] = useState({
    noOfUsers: "",
    participants: [],
    gameId: "",
    gameImg: "",
    title: "",
    desc: "",
    time: "",
  });

  const handleJoin = (id) => {
    setIsLoading3(true);
    axiosInstance
      .patch("/conversation/joinConversation/" + id, {
        userId: userData.id,
        userName: userData.username,
      })
      .then((res) => {
        if (res.data) {
          //alert(res.data._id);
          toast.success(`Party have been joined Successfully`);
          channel.publish("test-message", "UPDATE_IN_PARTY_PAGE");
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading3(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading3(false);
      });
  };

  const handleLeave = (id) => {
    setIsLoading3(true);
    axiosInstance
      .patch("/conversation/removeMember/" + id, {
        userId: userData.id,
        userName: userData.username,
      })
      .then((res) => {
        if (res.data) {
          //alert(res.data._id);
          toast.success(`Party have been leaved Successfully`);

          channel.publish("test-message", "UPDATE_IN_PARTY_PAGE");
          // setRefresh(prev=>prev+1);
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading3(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading3(false);
      });
  };

  const { channel } = useChannel("Party-Page", (message) => {
    if (message) {
      setRefresh(refresh + 1);
    }
  });

  React.useEffect(() => {
    if (!userData.isAuthenticated) {
      // onOpen()
    } else {
      onClose();
    }
  }, [userData.isAuthenticated]);

  const handleSubmit = () => {
    setIsLoading3(true);
    axiosInstance
      .post("/conversation", {
        creatorId: userData.id,
        noOfUsers: fieldData.noOfUsers,
        participants: [
          {
            userId: userData.id,
            userName: userData.username,
          },
        ],
        gameId: fieldData.gameId,
        gameImg: fieldData.gameImg,
        title: fieldData.title,
        desc: fieldData.desc,
        time: fieldData.time,
      })
      .then((res) => {
        if (res.data) {
          //alert(res.data._id);
          toast.success(`Party have been created Successfully`);
          channel.publish("test-message", "UPDATE_IN_PARTY_PAGE");
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading3(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading(false);
      });
  };

  const handleDelete = (id) => {
    setIsLoading3(true);
    const data = {
      userId: userData.id,
      userName: userData.username,
    };
    console.log(data);
    axiosInstance
      .patch("/conversation/delete/" + id, data)
      .then((res) => {
        if (res.data) {
          //alert(res.data._id);
          toast.success(`Party have been delete Successfully`);
          // setRefresh(prev=>prev+1);
        } else {
          toast.error("Something went wrong!");
        }
        setIsLoading3(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.errors.msg);
        setIsLoading3(false);
      });
  };

  return (
    <UserLayout>
      <Helmet>
        <title>Parties - Meepleton board game cafe</title>
        <meta
          name="description"
          content="Meepleton is game cafe in bhopal which has best pool settings."
        />
      </Helmet>
      {isLoading2 ? (
        <>
          <div className="flex h-screen flex-col items-center justify-center">
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal isOpen={isOpen} className="!z-[1010]">
            <ModalOverlay className="bg-[#000] !opacity-30" />
            <ModalContent className="top-[25vh] !z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
              <ModalBody>
                <div className="relative mt-5 w-full rounded-xl bg-white p-4 text-navy-800">
                  {userData.id && (
                    <HiX
                      className="absolute right-3 cursor-pointer"
                      onClick={() => {
                        onClose();
                      }}
                    />
                  )}
                  <UserLoginIndexPage onClose={onClose} />
                </div>
              </ModalBody>
            </ModalContent>
          </Modal>
          <section className="bg-white py-8 dark:bg-gray-900 md:py-8">
            <div className="mx-auto grid max-w-screen-2xl px-4 pt-20 md-max:flex md-max:flex-col lg:grid-cols-12 lg:gap-8  xl:gap-0">
              <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="mb-4 max-w-2xl text-3xl font-extrabold leading-none tracking-tight  dark:text-white md:text-4xl xl:text-5xl">
                  Host a Game Night Party
                </h1>
                <p className="mb-6 max-w-2xl font-light text-gray-123 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
                  Discover the joy of socializing and planning unforgettable
                  gatherings at Meepleton Board Game Cafe. Our party planning
                  service is all about bringing people together, sparking
                  connections, and creating lasting memories. Whether it's a
                  special occasion or a casual night out, our wide selection of
                  board games and welcoming atmosphere ensure a fantastic time.
                  Reserve your party table now and let the good times roll at
                  Meepleton.
                </p>
              </div>
              <div className="md-max:mt-6 lg:col-span-5 lg:mt-0 ">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    userData.isAuthenticated ? handleSubmit() : onOpen();
                  }}
                  className="rounded-xl bg-white p-5"
                >
                  <InputField
                    label={"Title"}
                    variant={"auth"}
                    value={fieldData.title}
                    onChange={(e) => {
                      setFieldData({
                        ...fieldData,
                        title: e.target.value,
                      });
                    }}
                    placeholder={"Enter Title"}
                    type={"text"}
                  />
                  <div className="mt-4 grid grid-cols-3 gap-4">
                    <InputField
                      label={"Number of Players"}
                      variant={"auth"}
                      placeholder={"Enter Number of Guests"}
                      type={"Number"}
                      value={fieldData.noOfUsers}
                      onChange={(e) => {
                        setFieldData({
                          ...fieldData,
                          noOfUsers: e.target.value,
                        });
                      }}
                    />
                    <InputField
                      label={"Visiting Time"}
                      variant={"auth"}
                      type={"datetime-local"}
                      value={fieldData.time}
                      onChange={(e) => {
                        setFieldData({
                          ...fieldData,
                          time: e.target.value,
                        });
                      }}
                    />
                    <div className="-mt-2">
                      <SelectField
                        label={"Game"}
                        variant={"auth"}
                        type={"time"}
                        options={[
                          ...(data.gameData || []).map((elem) => ({
                            label: elem.bgName,
                            value: elem.bgName,
                            gameImg: elem.bgImage,
                            gameId: elem._id,
                          })),
                        ]}
                        onChange={(e) => {
                          setFieldData({
                            ...fieldData,
                            gameId: e.gameId,
                            gameImg: e.gameImg,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <InputField
                    label={"Description"}
                    variant={"auth"}
                    type={"text"}
                    placeholder={"Enter description"}
                    value={fieldData.desc}
                    onChange={(e) => {
                      setFieldData({
                        ...fieldData,
                        desc: e.target.value,
                      });
                    }}
                  />
                  <button
                    type="submit"
                    className="mt-4 inline-flex w-full items-center justify-center rounded-lg border border-gray-200 bg-teal-400 px-5 py-3 text-center text-sm font-medium text-white hover:bg-teal-500 focus:ring-4 focus:ring-teal-100 dark:border-teal-700 dark:text-white dark:hover:bg-teal-700 dark:focus:ring-teal-800 sm:w-auto"
                  >
                    {isLoading3 ? <Loading /> : <>Create Party</>}
                  </button>
                </form>
              </div>
            </div>
          </section>
          <section className="bg-gray-50 dark:bg-gray-900">
            <div className="mx-auto max-w-screen-2xl px-4 py-8">
              <h2 className="text-center text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white lg:mb-8 lg:text-3xl">
                Parties
              </h2>
              <div className="mx-auto">
                {isLoading ? (
                  <>
                    <div className="flex h-screen flex-col items-center justify-center">
                      <div className="flex items-center justify-center">
                        <Loading />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                      {data?.docs?.map((elem) => {
                        if (elem._id === "653a0cfccd014000b0abaf9d") {
                          return <></>;
                        } else
                          return (
                            <PartyComponent
                              handleLeave={handleLeave}
                              isLoading={isLoading3}
                              data={elem}
                              handleJoin={(id) => {
                                userData.isAuthenticated
                                  ? handleJoin(id)
                                  : onOpen();
                              }}
                              handleDelete={handleDelete}
                            />
                          );
                      })}
                    </div>
                  </>
                )}
                <div className="my-6 flex flex-col items-center">
                  <div className="flex text-white">
                    {data?.hasPrevPage && (
                      <div
                        onClick={() => {
                          setPage(page - 1);
                        }}
                        className="mr-1 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gray-400"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-chevron-left h-6 w-6"
                        >
                          <polyline points="15 18 9 12 15 6"></polyline>
                        </svg>
                      </div>
                    )}
                    <div className="flex h-12 rounded-full border bg-white font-medium text-gray-800">
                      {data.prevPage !== null && (
                        <div
                          onClick={() => {
                            setPage(data.prevPage);
                          }}
                          className="hidden w-12 cursor-pointer items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
                        >
                          {data.prevPage}
                        </div>
                      )}
                      <div className="hidden w-12 cursor-pointer items-center justify-center  rounded-full border bg-gray-50 leading-5 transition  duration-150 ease-in md:flex ">
                        {data.page}
                      </div>
                      {data.nextPage !== null && (
                        <div
                          onClick={() => {
                            setPage(data.nextPage);
                          }}
                          className="hidden w-12 cursor-pointer  items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
                        >
                          {data.nextPage}
                        </div>
                      )}
                    </div>
                    {data.hasNextPage && (
                      <div
                        onClick={() => {
                          setPage(page + 1);
                        }}
                        className="ml-1 mr-4 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full border bg-white text-gray-800"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="100%"
                          height="100%"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-chevron-right h-6 w-6"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </div>
                    )}
                    <select
                      value={limit}
                      className="rounded-xl border bg-white p-1 text-gray-800 hover:outline-none active:outline-none"
                      onChange={(e) => setLimit(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={150}>150</option>
                      <option value={data.totalDocs}>All</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-white dark:bg-gray-900">
            <div className="mx-auto max-w-screen-2xl px-4 py-16">
              <div className="mx-auto">
                {userData.isAuthenticated ? (
                  <GlobalChatBox />
                ) : (
                  <div className="flex w-full justify-center bg-white pb-3 md-max:px-5">
                    <div className="container flex w-full rounded-xl border bg-white">
                      <div className="relative w-full rounded-t-xl rounded-b-xl bg-gray-50">
                        <div className="mb-2 flex items-center justify-between rounded-t-xl  bg-teal-400 px-3 py-2 text-xl font-medium text-white">
                          Global Chat{" "}
                        </div>
                        <>
                          <div
                            className="flex h-[60vh] flex-col items-center justify-center overflow-y-auto p-2"
                          >
                            <MdLock/>
                            <div className="flex">Please <div className="text-teal-400 mx-1 underline cursor-pointer" onClick={()=>{onOpen()}}>Sign in</div> to chat globally.</div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </UserLayout>
  );
};

export default Pools;
