import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction' 
import React from "react";
import { MdClose } from "react-icons/md";

// const events = [
//   {
//     title: "Ticket to Ride Game Night",
//     start: "2023-10-10T05:38",
//     end:"2023-10-10T06:00",
//     imageSrc:
//       "https://cf.geekdo-images.com/0K1AOciqlMVUWFPLTJSiww__imagepage/img/pC5hC440R46jn4EpfdYV5rL4VOc=/fit-in/900x600/filters:no_upscale():strip_icc()/pic66668.jpg",
//     desc: "Join us for a thrilling adventure on the tracks! 🚂 Grab your tickets and get ready to embark on a journey in the world of 'Ticket to Ride.' Whether you're a seasoned traveler or a newcomer, this board game night promises hours of fun and strategic gameplay. Build railways, complete routes, and race to victory. Don't miss out on this exciting event that combines strategy, competition, and lots of laughter. See you on the tracks!",
//     time: "2:00PM - 2:30PM",
//   },
//   {
//     title: "Magic Maze Game Night",
//     start: "2023-10-02",
//     imageSrc:
//       "https://cf.geekdo-images.com/wJfDIveg2zpTGn8E1WzEpA__imagepagezoom/img/s67ltt2-VEAHAgo8YBU-sPSnUm0=/fit-in/1200x900/filters:no_upscale():strip_icc()/pic3268473.jpg",
//     desc: "Navigate a magical shopping mall in Magic Maze. Work together with friends to steal what you need, but remember, there's no talking allowed!",
//     time: "2:00PM - 2:30PM",
//   },
//   {
//     title: "Love Letter Game Night",
//     start: "2023-10-03",
//     imageSrc:
//       "https://cf.geekdo-images.com/T1ltXwapFUtghS9A7_tf4g__imagepage/img/wf3HgW2e24O0mcsB5OIHJnxaeIM=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1401448.jpg",
//     desc: "In Love Letter, compete for the heart of the princess. Use cunning and strategy to eliminate rivals and deliver your love letter.",
//   },
//   {
//     title: "Splendor Game Night",
//     start: "2023-10-04",
//     imageSrc:
//       "https://cf.geekdo-images.com/rwOMxx4q5yuElIvo-1-OFw__imagepage/img/qXhBOrqJMEeA0pe6TMzWNZUTlbk=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1904079.jpg",
//     desc: "Become a Renaissance merchant in Splendor. Acquire gems, develop mines, and build a trade empire in this strategy game.",
//   },
//   {
//     title: "Catan Game Night",
//     start: "2023-10-05",
//     imageSrc:
//       "https://cf.geekdo-images.com/W3Bsga_uLP9kO91gZ7H8yw__itemrep/img/IzYEUm_gWFuRFOL8gQYqGm5gU6A=/fit-in/246x300/filters:strip_icc()/pic2419375.jpg",
//     desc: "Settle the island of Catan. Collect resources, trade with others, and build roads and settlements in this classic board game.",
//   },
//   {
//     title: "Sherlock Holmes Game Night",
//     start: "2023-10-06",
//     imageSrc:
//       "https://cf.geekdo-images.com/demwo7nCkIHbUcFohLwfsQ__imagepage/img/uuKYUId8QBUiET2Vjcstzofp6cA=/fit-in/900x600/filters:no_upscale():strip_icc()/pic5198810.jpg",
//     desc: "Join Sherlock Holmes' Baker Street Irregulars and solve mysteries in 19th-century London. Gather clues, unravel cases, and be the detective!",
//   },
//   {
//     title: "Sushi Go Game Night",
//     start: "2023-10-07",
//     imageSrc:
//       "https://cf.geekdo-images.com/Fn3PSPZVxa3YurlorITQ1Q__imagepage/img/qD1BnVr1_QYh9Dwr2G6eXVqTdBs=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1900075.jpg",
//     desc: "Savor the delights of Sushi Go. Draft sushi dishes and create the most delicious meal while competing with friends in this card game.",
//   },
//   {
//     title: "Ticket to Ride Game Night",
//     start: "2023-10-08",
//     end:"2023-10-10",
//     imageSrc:
//       "https://cf.geekdo-images.com/0K1AOciqlMVUWFPLTJSiww__imagepage/img/pC5hC440R46jn4EpfdYV5rL4VOc=/fit-in/900x600/filters:no_upscale():strip_icc()/pic66668.jpg",
//     desc: "Join us for a thrilling adventure on the tracks! 🚂 Grab your tickets and get ready to embark on a journey in the world of 'Ticket to Ride.' Whether you're a seasoned traveler or a newcomer, this board game night promises hours of fun and strategic gameplay. Build railways, complete routes, and race to victory. Don't miss out on this exciting event that combines strategy, competition, and lots of laughter. See you on the tracks!",
//   },
//   {
//     title: "Magic Maze Game Night",
//     start: "2023-10-10",
//     imageSrc:
//       "https://cf.geekdo-images.com/wJfDIveg2zpTGn8E1WzEpA__imagepagezoom/img/s67ltt2-VEAHAgo8YBU-sPSnUm0=/fit-in/1200x900/filters:no_upscale():strip_icc()/pic3268473.jpg",
//     desc: "Navigate a magical shopping mall in Magic Maze. Work together with friends to steal what you need, but remember, there's no talking allowed!",
//   },
//   {
//     title: "Love Letter Game Night",
//     start: "2023-10-10",
//     imageSrc:
//       "https://cf.geekdo-images.com/T1ltXwapFUtghS9A7_tf4g__imagepage/img/wf3HgW2e24O0mcsB5OIHJnxaeIM=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1401448.jpg",
//     desc: "In Love Letter, compete for the heart of the princess. Use cunning and strategy to eliminate rivals and deliver your love letter.",
//   },
//   {
//     title: "Splendor Game Night",
//     start: "2023-10-11",
//     imageSrc:
//       "https://cf.geekdo-images.com/rwOMxx4q5yuElIvo-1-OFw__imagepage/img/qXhBOrqJMEeA0pe6TMzWNZUTlbk=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1904079.jpg",
//     desc: "Become a Renaissance merchant in Splendor. Acquire gems, develop mines, and build a trade empire in this strategy game.",
//   },
//   {
//     title: "Catan Game Night",
//     start: "2023-10-12",
//     imageSrc:
//       "https://cf.geekdo-images.com/W3Bsga_uLP9kO91gZ7H8yw__itemrep/img/IzYEUm_gWFuRFOL8gQYqGm5gU6A=/fit-in/246x300/filters:strip_icc()/pic2419375.jpg",
//     desc: "Settle the island of Catan. Collect resources, trade with others, and build roads and settlements in this classic board game.",
//   },
//   {
//     title: "Sherlock Holmes Game Night",
//     start: "2023-10-13",
//     imageSrc:
//       "https://cf.geekdo-images.com/demwo7nCkIHbUcFohLwfsQ__imagepage/img/uuKYUId8QBUiET2Vjcstzofp6cA=/fit-in/900x600/filters:no_upscale():strip_icc()/pic5198810.jpg",
//     desc: "Join Sherlock Holmes' Baker Street Irregulars and solve mysteries in 19th-century London. Gather clues, unravel cases, and be the detective!",
//   },
//   {
//     title: "Sushi Go Game Night",
//     start: "2023-10-14",
//     imageSrc:
//       "https://cf.geekdo-images.com/Fn3PSPZVxa3YurlorITQ1Q__imagepage/img/qD1BnVr1_QYh9Dwr2G6eXVqTdBs=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1900075.jpg",
//     desc: "Savor the delights of Sushi Go. Draft sushi dishes and create the most delicious meal while competing with friends in this card game.",
//   },
//   {
//     title: "Sushi Go Game Night",
//     start: "2023-10-15",
//     imageSrc:
//       "https://cf.geekdo-images.com/Fn3PSPZVxa3YurlorITQ1Q__imagepage/img/qD1BnVr1_QYh9Dwr2G6eXVqTdBs=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1900075.jpg",
//     desc: "Savor the delights of Sushi Go. Draft sushi dishes and create the most delicious meal while competing with friends in this card game.",
//   },
//   {
//     title: "Sushi Go Game Night",
//     start: "2023-10-16",
//     imageSrc:
//       "https://cf.geekdo-images.com/Fn3PSPZVxa3YurlorITQ1Q__imagepage/img/qD1BnVr1_QYh9Dwr2G6eXVqTdBs=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1900075.jpg",
//     desc: "Savor the delights of Sushi Go. Draft sushi dishes and create the most delicious meal while competing with friends in this card game.",
//   },
//   {
//     title: "Ticket to Ride Game Night",
//     start: "2023-10-17",
//     imageSrc:
//       "https://cf.geekdo-images.com/0K1AOciqlMVUWFPLTJSiww__imagepage/img/pC5hC440R46jn4EpfdYV5rL4VOc=/fit-in/900x600/filters:no_upscale():strip_icc()/pic66668.jpg",
//     desc: "Join us for a thrilling adventure on the tracks! 🚂 Grab your tickets and get ready to embark on a journey in the world of 'Ticket to Ride.' Whether you're a seasoned traveler or a newcomer, this board game night promises hours of fun and strategic gameplay. Build railways, complete routes, and race to victory. Don't miss out on this exciting event that combines strategy, competition, and lots of laughter. See you on the tracks!",
//   },
//   {
//     title: "Magic Maze Game Night",
//     start: "2023-10-18",
//     imageSrc:
//       "https://cf.geekdo-images.com/wJfDIveg2zpTGn8E1WzEpA__imagepagezoom/img/s67ltt2-VEAHAgo8YBU-sPSnUm0=/fit-in/1200x900/filters:no_upscale():strip_icc()/pic3268473.jpg",
//     desc: "Navigate a magical shopping mall in Magic Maze. Work together with friends to steal what you need, but remember, there's no talking allowed!",
//   },
//   {
//     title: "Love Letter Game Night",
//     start: "2023-10-19",
//     imageSrc:
//       "https://cf.geekdo-images.com/T1ltXwapFUtghS9A7_tf4g__imagepage/img/wf3HgW2e24O0mcsB5OIHJnxaeIM=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1401448.jpg",
//     desc: "In Love Letter, compete for the heart of the princess. Use cunning and strategy to eliminate rivals and deliver your love letter.",
//   },
//   {
//     title: "Splendor Game Night",
//     start: "2023-10-20",
//     imageSrc:
//       "https://cf.geekdo-images.com/rwOMxx4q5yuElIvo-1-OFw__imagepage/img/qXhBOrqJMEeA0pe6TMzWNZUTlbk=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1904079.jpg",
//     desc: "Become a Renaissance merchant in Splendor. Acquire gems, develop mines, and build a trade empire in this strategy game.",
//   },
//   {
//     title: "Catan Game Night",
//     start: "2023-10-21",
//     imageSrc:
//       "https://cf.geekdo-images.com/W3Bsga_uLP9kO91gZ7H8yw__itemrep/img/IzYEUm_gWFuRFOL8gQYqGm5gU6A=/fit-in/246x300/filters:strip_icc()/pic2419375.jpg",
//     desc: "Settle the island of Catan. Collect resources, trade with others, and build roads and settlements in this classic board game.",
//   },
//   {
//     title: "Sherlock Holmes Game Night",
//     start: "2023-10-22",
//     imageSrc:
//       "https://cf.geekdo-images.com/demwo7nCkIHbUcFohLwfsQ__imagepage/img/uuKYUId8QBUiET2Vjcstzofp6cA=/fit-in/900x600/filters:no_upscale():strip_icc()/pic5198810.jpg",
//     desc: "Join Sherlock Holmes' Baker Street Irregulars and solve mysteries in 19th-century London. Gather clues, unravel cases, and be the detective!",
//   },
//   {
//     title: "Sushi Go Game Night",
//     start: "2023-10-23",
//     imageSrc:
//       "https://cf.geekdo-images.com/Fn3PSPZVxa3YurlorITQ1Q__imagepage/img/qD1BnVr1_QYh9Dwr2G6eXVqTdBs=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1900075.jpg",
//     desc: "Savor the delights of Sushi Go. Draft sushi dishes and create the most delicious meal while competing with friends in this card game.",
//   },
//   {
//     title: "Ticket to Ride Game Night",
//     start: "2023-10-24",
//     imageSrc:
//       "https://cf.geekdo-images.com/0K1AOciqlMVUWFPLTJSiww__imagepage/img/pC5hC440R46jn4EpfdYV5rL4VOc=/fit-in/900x600/filters:no_upscale():strip_icc()/pic66668.jpg",
//     desc: "Join us for a thrilling adventure on the tracks! 🚂 Grab your tickets and get ready to embark on a journey in the world of 'Ticket to Ride.' Whether you're a seasoned traveler or a newcomer, this board game night promises hours of fun and strategic gameplay. Build railways, complete routes, and race to victory. Don't miss out on this exciting event that combines strategy, competition, and lots of laughter. See you on the tracks!",
//   },
//   {
//     title: "Magic Maze Game Night",
//     start: "2023-10-25",
//     imageSrc:
//       "https://cf.geekdo-images.com/wJfDIveg2zpTGn8E1WzEpA__imagepagezoom/img/s67ltt2-VEAHAgo8YBU-sPSnUm0=/fit-in/1200x900/filters:no_upscale():strip_icc()/pic3268473.jpg",
//     desc: "Navigate a magical shopping mall in Magic Maze. Work together with friends to steal what you need, but remember, there's no talking allowed!",
//   },
//   {
//     title: "Love Letter Game Night",
//     start: "2023-10-26",
//     imageSrc:
//       "https://cf.geekdo-images.com/T1ltXwapFUtghS9A7_tf4g__imagepage/img/wf3HgW2e24O0mcsB5OIHJnxaeIM=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1401448.jpg",
//     desc: "In Love Letter, compete for the heart of the princess. Use cunning and strategy to eliminate rivals and deliver your love letter.",
//   },
//   {
//     title: "Splendor Game Night",
//     start: "2023-10-27",
//     imageSrc:
//       "https://cf.geekdo-images.com/rwOMxx4q5yuElIvo-1-OFw__imagepage/img/qXhBOrqJMEeA0pe6TMzWNZUTlbk=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1904079.jpg",
//     desc: "Become a Renaissance merchant in Splendor. Acquire gems, develop mines, and build a trade empire in this strategy game.",
//   },
//   {
//     title: "Catan Game Night",
//     start: "2023-10-28",
//     imageSrc:
//       "https://cf.geekdo-images.com/W3Bsga_uLP9kO91gZ7H8yw__itemrep/img/IzYEUm_gWFuRFOL8gQYqGm5gU6A=/fit-in/246x300/filters:strip_icc()/pic2419375.jpg",
//     desc: "Settle the island of Catan. Collect resources, trade with others, and build roads and settlements in this classic board game.",
//   },
//   {
//     title: "Sherlock Holmes Game Night",
//     start: "2023-10-29",
//     imageSrc:
//       "https://cf.geekdo-images.com/demwo7nCkIHbUcFohLwfsQ__imagepage/img/uuKYUId8QBUiET2Vjcstzofp6cA=/fit-in/900x600/filters:no_upscale():strip_icc()/pic5198810.jpg",
//     desc: "Join Sherlock Holmes' Baker Street Irregulars and solve mysteries in 19th-century London. Gather clues, unravel cases, and be the detective!",
//   },
//   {
//     title: "Sushi Go Game Night",
//     start: "2023-10-30",
//     imageSrc:
//       "https://cf.geekdo-images.com/Fn3PSPZVxa3YurlorITQ1Q__imagepage/img/qD1BnVr1_QYh9Dwr2G6eXVqTdBs=/fit-in/900x600/filters:no_upscale():strip_icc()/pic1900075.jpg",
//     desc: "Savor the delights of Sushi Go. Draft sushi dishes and create the most delicious meal while competing with friends in this card game.",
//   },
// ];

const EventCalendar = (props) => {
  const events = props.data
  const [hoveredDate, setHoveredDate] = React.useState(null);
  const [eventDetails, setEventDetails] = React.useState({});
  const eventRender = (arg) => {
    let arrayOfDomNodes = [];
    // title event
    let titleEvent = document.createElement("div");
    if (arg.event._def.title) {
      titleEvent.innerHTML = arg.event._def.title;
      titleEvent.classList = "fc-event-title fc-sticky";
    }

    // image event
    let imgEventWrap = document.createElement("div");
    if (arg.event.extendedProps.imageSrc) {
      let imgEvent =
        '<img class="lg:h-36 lg:w-[144px] md:w-[90px] md:h-[90px]  w-[50px] h-[50px] rounded-xl" src="' +
         arg.event.extendedProps.imageSrc +
        '" >';
      imgEventWrap.classList = "fc-event-img";
      imgEventWrap.innerHTML = imgEvent;
    }

    arrayOfDomNodes = [imgEventWrap];

    return { domNodes: arrayOfDomNodes };
  };

  const handleDateMouseEnter = (arg) => {
    setEventDetails({
      title: arg.event._def.title,
      imgUrl: arg.event._def.extendedProps.imageSrc,
      desc: arg.event._def.extendedProps.desc,
      time: arg.event._def.extendedProps.time,
    });
  };

  const handleDateMouseLeave = () => {
    // Clear the hovered date and event details when the mouse leaves a date cell
    //setHoveredDate(null);
    setEventDetails({});
  };

  return (
      <div className="container">
        <div className="relative w-full rounded-xl block md:hidden">
          <FullCalendar
            plugins={[dayGridPlugin,listPlugin,interactionPlugin]}
            initialView="listWeek"
            events={events}
            handleWindowResize={true}
            
            // className={'even'}
            // class={'text-gr'}
            // dayCellClassNames={
            //   "even:bg-white odd:bg-gray-100 font-medium border-navy-900 text-lg"
            // }
            // eventContent={eventRender}
            // dayHeaderClassNames={
            //   "text-white bg-teal-400 md:text-base p-2 text-sm capitalize"
            // }
            // eventClassNames={"flex justify-center border-0"}
            // eventBackgroundColor="inherit"
            contentHeight={500}
            eventClick={handleDateMouseEnter}
            nowIndicatorClassNames={"bg-teal-200"}
            // dayCellMouseLeave={handleDateMouseLeave}
          />
          {eventDetails.title && (
            // <div className="absolute top-5 right-0 mt-4 mr-4 bg-white p-4 shadow-md">
            //   <h3>{eventDetails.title}</h3>

            //   {/* Add more details here */}
            // </div>

            <div className="absolute top-20 left-5 z-50 w-56 max-w-sm rounded-xl border border-navy-200 bg-white shadow-xl  dark:border-gray-700 dark:bg-gray-800">
              <div className="relative">
                <div className="absolute right-2 top-2 rounded-full bg-gray-400">
                  <MdClose
                    onClick={handleDateMouseLeave}
                    className="cursor-pointer text-lg font-semibold text-white"
                  />
                </div>
                <a>
                  <img
                    className="h-[224px] w-56 rounded-t-xl"
                    src={eventDetails.imgUrl}
                    alt=""
                  />
                </a>
                <div className="">
                  <a>
                    <h5 className="mt-2 p-2 text-left text-lg font-bold tracking-tight text-navy-900 dark:text-white">
                      {eventDetails.title}
                    </h5>
                  </a>
                  <p className="overflow-y-auto px-2 pb-2 md-max:text-sm font-normal text-navy-700 dark:text-navy-400">
                    {eventDetails.desc}
                  </p>
                  <p className="px-2 pb-2 font-semibold text-navy-700 dark:text-navy-400">
                    ~ {eventDetails.time}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="relative w-full rounded-xl hidden md:block">
          <FullCalendar
            plugins={[dayGridPlugin,listPlugin,interactionPlugin]}
            initialView="dayGridMonth"
            events={events}
            handleWindowResize={true}
            
            // className={'even'}
            // class={'text-gr'}
            dayCellClassNames={
              "even:bg-gray-50 odd:bg-gray-100 font-medium border-navy-900"
            }
            // eventContent={eventRender}
            dayHeaderClassNames={
              "text-white bg-teal-400  md:text-base text-sm capitalize"
            }
            // eventClassNames={"text-navy-800 border-0"}
            // eventBackgroundColor="white"
            contentHeight={500}
            eventClick={handleDateMouseEnter}
            nowIndicatorClassNames={"bg-teal-200"}
            // dayCellMouseLeave={handleDateMouseLeave}
          />
          {eventDetails.title && (
            // <div className="absolute top-5 right-0 mt-4 mr-4 bg-white p-4 shadow-md">
            //   <h3>{eventDetails.title}</h3>

            //   {/* Add more details here */}
            // </div>

            <div className="absolute top-20 left-5 z-50 w-56 max-w-sm rounded-xl border border-navy-200 bg-white shadow-xl  dark:border-gray-700 dark:bg-gray-800">
              <div className="relative">
                <div className="absolute right-2 top-2 rounded-full bg-gray-400">
                  <MdClose
                    onClick={handleDateMouseLeave}
                    className="cursor-pointer text-lg font-semibold text-white"
                  />
                </div>
                <a>
                  <img
                    className="h-[224px] w-56 rounded-t-xl"
                    src={eventDetails.imgUrl}
                    alt=""
                  />
                </a>
                <div className="">
                  <a>
                    <h5 className="mt-2 p-2 text-left text-lg font-bold tracking-tight text-navy-900 dark:text-white">
                      {eventDetails.title}
                    </h5>
                  </a>
                  <p className="overflow-y-auto px-2 pb-2 md-max:text-sm font-normal text-navy-700 dark:text-navy-400">
                    {eventDetails.desc}
                  </p>
                  <p className="px-2 pb-2 font-semibold text-navy-700 dark:text-navy-400">
                    ~ {eventDetails.time}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  );
};

export default EventCalendar;
