import React, { useState } from "react";
import Loading from "components/loading";
import useAxiosInstance from "../../../axiosInstance";
import UserLayout from "layouts/user";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { Book, Header } from "./components";
import SelectField from "components/fields/selectField";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "features/books";
import { FaFilter, FaSort } from "react-icons/fa";
import Dropdown from "components/dropdown";
import Card from "components/card";
import { HiX } from "react-icons/hi";
import { Accordion } from "flowbite-react";
import HeaderComponent from "../../../components/Header";
import FaQComponent from "components/FaQ";
import { Helmet } from "react-helmet";

const Books = () => {
  const [data, setData] = React.useState({});
  const [searchData, setSearchData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const history = useNavigate();
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.books.value.data);
  const [open, setOpen] = React.useState(false);
  const [filterMenu, setFilterMenu] = useState({});
  const [contentData,setContentData] = useState([]);
  const [faQData,setFaQ] = useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(`/books/allCatalog?limit=${limit}&page=${page}`)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data.filtedData);
        setSearchData(res.data.searchData);
        setContentData(res.data.contentData)
        setFaQ(res.data.faqData)
        //toast.success('Data loaded Successfully!')
        setFilterMenu(res.data.filterMenu);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading(false);
      });
  }, [limit, page]);

  React.useEffect(() => {
    setIsLoading2(true);
    axiosInstance
      .get(`/books/allCatalog?limit=${limit}&page=${page}`)
      .then((res) => {
        // dispatch(updateData({ data: res.data.filtedData }));
        setData(res.data.filtedData);
        setSearchData(res.data.searchData);
        setContentData(res.data.contentData)
        setFaQ(res.data.faqData)
        //toast.success('Data loaded Successfully!')
        setFilterMenu(res.data.filterMenu);
        setIsLoading2(false);
      })
      .catch((err) => {
        toast.error("Something went Wrong!");

        setIsLoading2(false);
      });
  }, []);

  const Sort = (fieldName, order, isNumeric) => {
    if (isNumeric === true) {
      if (order === 1) {
        const dataa = data.docs;
        var docs = dataa.sort(
          (a, b) => (a[fieldName] || 0) - (b[fieldName] || 0)
        );
        setData({ ...data, docs });
      } else {
        const dataa = data.docs;
        var docs = dataa.sort(
          (a, b) => (b[fieldName] || 0) - (a[fieldName] || 0)
        );
        setData({ ...data, docs });
      }
    } else {
      if (order === 1) {
        const dataa = data.docs;
        var docs = dataa.sort((a, b) =>
          a[fieldName].toLowerCase().localeCompare(b[fieldName].toLowerCase())
        );
        setData({ ...data, docs });
      } else {
        const dataa = data.docs;
        var docs = dataa.sort((a, b) =>
          b[fieldName].toLowerCase().localeCompare(a[fieldName].toLowerCase())
        );
        setData({ ...data, docs });
      }
    }
  };
  const filter = (fieldName, value) => {
    console.log(fieldName, value);
    var filtedData = data.docs.filter((elem) =>
      elem[`book${fieldName}`].includes(value)
    );
    dispatch(updateData({ data: filtedData }));
  };

  return (
    <UserLayout>
      <Helmet>
                    <title>Books Collection - Meepleton Board Game Cafe</title>
                    <meta name="description" content='Meepleton is best cafe in bhopal which has lots of books from harry potter set to rare books. Click here to view our books collection.'/>
                </Helmet>
     <div
            className={`sm:none duration-175 linear fixed top-48 !z-[50000000000000] flex h-[500px] flex-col rounded-xl bg-white border p-5 pb-10  shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-[50000000000000] lg:!z-[50000000000000] xl:!z-[50000000000] ${
              open ? "translate-x-0" : "-translate-x-96"
            }`}
          >
            <div className="mb-2 flex items-center justify-between border-b-2 border-navy-800 pb-2 text-navy-800">
              <FaFilter />
              <span className="cursor-pointer" onClick={() => setOpen(false)}>
                <HiX />
              </span>
            </div>
            <Accordion collapseAll className="border-0">
              {Object.keys(filterMenu).map((element) => {
                return (
                  <Accordion.Panel>
                    <div className="mb-1 w-[300px] rounded-md ">
                      <Accordion.Title className=" py-2 px-1  font-medium capitalize text-gray-800  sm:text-sm md:text-base focus:border-0 lg:text-lg">
                        {element}
                      </Accordion.Title>
                      <Accordion.Content className="h-52 overflow-y-scroll py-1  text-gray-123 dark:text-gray-400 sm:text-xs md:text-sm lg:text-base">
                        {filterMenu[element].map((elem) => {
                          return (
                            <p
                              onClick={() => {
                                filter(element, elem);
                              }}
                              className="mb-1 cursor-pointer hover:text-gray-800"
                            >
                              {elem}
                            </p>
                          );
                        })}
                      </Accordion.Content>
                    </div>
                  </Accordion.Panel>
                );
              })}
            </Accordion>
            <button
              onClick={() => dispatch(updateData({ data: [] }))}
              className="absolute bottom-3 right-1 mr-2 rounded-xl bg-white px-2 py-1 text-base font-medium text-gray-800 border transition duration-200 hover:bg-gray-600 active:bg-gray-700 dark:bg-gray-400 dark:text-white dark:hover:bg-gray-300 dark:active:bg-gray-200"
            >
              Clear Filter
            </button>
          </div>
      {isLoading2?<>
              <div className="flex h-screen flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <Loading />
                </div>
              </div>
            </>:<>   
      <HeaderComponent title={'Books'} para={contentData}/>

      <div className="flex bg-gray-50 w-full justify-center md-max:px-5">
        <div className="container mt-5 md:mt-8">
          <div className="flex w-full justify-center">
            <SelectField
              placeHolder={"Search Book"}
              isMulti={false}
              extra={
                "w-[320px] border bg-white py-3 px-2 rounded-xl"
              }
              options={[
                ...searchData.map((elem) => ({
                  label: elem.bookName,
                  value: elem._id,
                })),
              ]}
              onChange={(e) => {
                history("/books/view/" + e.value);
              }}
            />
          </div>

          <div className="right-7 mt-5 flex w-full justify-end ">
            <button
              onClick={() => {
                open ? setOpen(false) : setOpen(true);
              }}
              className="mr-2 rounded-xl border bg-white px-5 py-3 text-base font-medium text-gray-800 transition duration-200 hover:bg-white active:bg-white dark:bg-white dark:text-white dark:hover:bg-white dark:active:bg-white"
            >
              <FaFilter />
            </button>

            <Dropdown
              button={
                <button className="rounded-xl border bg-white px-5 py-3 text-base font-medium text-gray-800 transition duration-200 hover:bg-white active:bg-white dark:bg-white dark:text-white dark:hover:bg-white dark:active:bg-white">
                  <FaSort />
                </button>
              }
              children={
                <Card extra="flex flex-col  border p-2  mt-4 rounded-[20px]">
                  <div
                    onClick={() => Sort("bookName", 1, false)}
                    className="cursor-pointer border-b-2 p-2 hover:font-medium"
                  >
                    By A - Z
                  </div>
                  <div
                    onClick={() => Sort("_id", -1, false)}
                    className="cursor-pointer border-b-2 p-2 hover:font-medium"
                  >
                    By Latest
                  </div>
                  <div
                    onClick={() => Sort("bookTimesIssued", -1, true)}
                    className="cursor-pointer border-b-2 p-2 hover:font-medium"
                  >
                    By Most Issued
                  </div>
                  <div
                    onClick={() => Sort("bookWTR", -1, true)}
                    className="cursor-pointer border-b-2 p-2 hover:font-medium"
                  >
                    By Most Popular
                  </div>
                  <div
                    onClick={() => Sort("bookGoodreadsRating", -1, true)}
                    className="cursor-pointer border-b-2 p-2 hover:font-medium"
                  >
                    By BGG Score
                  </div>
                  <div
                    onClick={() => Sort("bookAvailable", -1, false)}
                    className=" cursor-pointer p-2 hover:font-medium"
                  >
                    By Availablity
                  </div>
                </Card>
              }
              classNames={"py-2 -left-[120px] w-max"}
            />
          </div>

          <div className="mb-4">
            <span className="text-base md:text-lg lg:text-xl font-medium text-navy-800">
              Total :{" "}
            </span>
            <span className="text-base md:text-lg lg:text-xl font-medium text-navy-800">
              {filterData.length || data.totalDocs}
            </span>
          </div>
          {isLoading ? (
            <>
              <div className="flex h-screen flex-col items-center justify-center">
                <div className="flex items-center justify-center">
                  <Loading />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="grid grid-cols-2 gap-4 md:grid-cols-4 lg:grid-cols-6">
                {filterData.length !== 0
                  ? filterData.map((elem) => {
                      return <Book data={elem} />;
                    })
                  : data?.docs?.map((elem) => {
                      return <Book data={elem} />;
                    })}
              </div>
            </>
          )}
          <div className="my-8 md:my-12 flex flex-col items-center">
                <div className="flex text-white">
                  {data?.hasPrevPage && (
                    <div
                      onClick={() => {
                        setPage(page - 1);
                      }}
                      className="mr-1 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-gray-400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-chevron-left h-6 w-6"
                      >
                        <polyline points="15 18 9 12 15 6"></polyline>
                      </svg>
                    </div>
                  )}
                  <div className="flex h-12 rounded-full bg-white border text-gray-800 font-medium">
                    {data.prevPage !== null && (
                      <div
                        onClick={() => {
                          setPage(data.prevPage);
                        }}
                        className="hidden w-12 cursor-pointer items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
                      >
                        {data.prevPage}
                      </div>
                    )}
                    <div className="hidden w-12 cursor-pointer items-center justify-center  rounded-full leading-5 bg-gray-50 border transition  duration-150 ease-in md:flex ">
                      {data.page}
                    </div>
                    {data.nextPage !== null && (
                      <div
                        onClick={() => {
                          setPage(data.nextPage);
                        }}
                        className="hidden w-12 cursor-pointer  items-center justify-center  rounded-full leading-5 transition duration-150 ease-in  md:flex  "
                      >
                        {data.nextPage}
                      </div>
                    )}
                  </div>
                  {data.hasNextPage && (
                    <div
                      onClick={() => {
                        setPage(page + 1);
                      }}
                      className="ml-1 mr-4 flex h-12 w-12 cursor-pointer border items-center justify-center rounded-full bg-white text-gray-800"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-chevron-right h-6 w-6"
                      >
                        <polyline points="9 18 15 12 9 6"></polyline>
                      </svg>
                    </div>
                  )}
                  <select
                    value={limit}
                    className="rounded-xl bg-white p-1 hover:outline-none border text-gray-800 active:outline-none"
                    onChange={(e) => setLimit(e.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={150}>150</option>
                    <option value={data.totalDocs}>All</option>
                  </select>
                </div>
              </div>
        </div>
      </div>
      
      <FaQComponent data={faQData}/>
      </>}
    </UserLayout>
  );
};

export default Books;
