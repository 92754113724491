import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useState } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdRefresh } from "react-icons/md";
import SelectField from "components/fields/selectField";
import { HiXCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { updateRefresher } from "features/refresher";
import MainTable2 from "components/table/Tablev2";

const TableGallery = (props) => {
  const [data, setData] = React.useState(props.data);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [id, setid] = React.useState("");

  //refresher setup
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresher.value.refreshCount);

  //States for Deposit Forms
  const [type, setType] = useState("");
  const [typeNew, setTypeNew] = useState("");
  const [image, setimage] = useState([]);

  //Image Validation
  const [imageMessage, setImageMessage] = useState("");
  const [imageState, setImageState] = useState("");

  //Type Validation
  const [typeMessage, setTypeMessage] = useState("");
  const [typeState, setTypeState] = useState("");

  //type new Validation
  const [typeNewMessage, setTypeNewMessage] = useState("");
  const [typeNewState, setTypeNewState] = useState("");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/Gallery/" + id, {
        type: typeNew ? typeNew : type,
        images: image,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'Gallery for"' + res.data.type + '" is Edited Successfully!'
          );
          dispatch(updateRefresher({ refreshCount: refresh + 1 }));
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          toast.error("Something went wrong!");
        }
      });
    setIsLoading2(false);
  };

  // React.useEffect(() => {
  //   if(name === ""){
  //     setnameMessage("Name cannot be empty!");
  //     setnameState()
  //   }
  //   else {
  //     setnameMessage("");
  //     setnameState('success');
  //   }

  //   if(price === ""){
  //     setpriceMessage("")
  //     setpriceState()
  //   }else if (price> 0) {
  //     setpriceMessage("Price must be a Negative Integer!");
  //     setpriceState('error')
  //   } else {
  //     setpriceMessage("");
  //     setpriceState('success')
  //   }
  // }, [name, price]);

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " Gallery ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/Gallery/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("Gallery is deleted Successfully!");
            dispatch(updateRefresher({ refreshCount: refresh + 1 }));
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    toast.info("Looking for FaQ in database");
    axiosInstance
      .get("/gallery/" + id)
      .then((res) => {
        toast.success("Successfully Found!");
        const { _id, type, images } = res.data;
        setType(type);
        setimage(images);
        setid(_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImage = async (e) => {
    try {
      toast.info("Images are uploading to database");
      for (const element of e.target.files) {
        const data = new FormData();
        data.append("file", element);
        data.append("upload_preset", "Meepleton");
        data.append("cloud_name", "dqzute9m4");

        const resp = await fetch(
          "https://api.cloudinary.com/v1_1/dqzute9m4/image/upload",
          {
            method: "post",
            body: data,
          }
        );

        const imageData = await resp.json();
        setimage((prev) => [...prev, imageData.secure_url]);
      }
      toast.success("Images are successfully Uploaded to database");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {(
        <MainTable2
          url={'/gallery/allByQuery'}
          tableKeys={["images", "type"]}
          refresh={refresh}
          editFunc={editFunc}
          deleteFunc={deleteFunc}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] top-[25vh] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800 ">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                {imageMessage && (
                  <span className="text-red-400 ms-1">{imageMessage}</span>
                )}
                <div>
                  <label className="ml-1.5 text-sm font-medium text-navy-700 dark:text-white">
                    Choose Images
                  </label>
                </div>
                <input
                  className="mt-2 w-full rounded-xl border p-3 text-navy-800 file:rounded-md file:border-0  file:bg-gray-100"
                  multiple={true}
                  variant="auth"
                  type="file"
                  label="Image"
                  placeholder="Choose image"
                  id="image"
                  state={imageState}
                  onChange={(e) => handleImage(e)}
                />
                <div className="mt-2 max-w-xs">
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    //ssr={true} // means to render carousel on server-side.
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={2500}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass=""
                    // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                    //deviceType={this.props.deviceType}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {image?.map((elem, index) => {
                      return (
                        <div className="relative" key={index}>
                          <HiXCircle
                            onClick={() => {
                              var cs = window.confirm(
                                "Are you sure you want to remove this Image ?"
                              );
                              if (cs) {
                                setimage(
                                  image.filter((element) => element !== elem)
                                );
                                toast.success("Image removed");
                              }
                            }}
                            className="absolute right-2 top-2 cursor-pointer  text-xl text-yellow-400 "
                          />
                          <img className="h-80  w-full rounded-xl" src={elem} />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>

                {/* Answer */}

                {typeMessage && (
                  <span className="text-sm text-red-500 ms-1">
                    {typeMessage}
                  </span>
                )}
                <SelectField
                  variant="auth"
                  label="Type"
                  placeholder="Enter Type"
                  id="type"
                  type="text"
                  state={typeState}
                  value={props?.data?.find((element) => element.value === type)}
                  onChange={(e) => {
                    setType(e.value);
                  }}
                  options={props.data}
                />
                {type === "NEW" && (
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="New Type"
                    placeholder="Enter new Type"
                    id="image"
                    type="text"
                    state={typeNewState}
                    value={typeNew}
                    onChange={(e) => {
                      setTypeNew(e.target.value.toUpperCase());
                    }}
                  />
                )}
                {isLoading2 ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  typeMessage === "" &&
                  imageMessage === "" && (
                    <button
                      type={"submit"}
                      className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Save Changes
                    </button>
                  )
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TableGallery;
