import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../../../features/user";
import useAxiosInstance from "../../../../axiosInstance";
import { GoogleLogin } from "@react-oauth/google";
import { decodeToken } from "react-jwt";
import { toast } from "react-toastify";
import Loading from "components/loading";
import UserLayout from "layouts/user";

const UserLoginPage = (props) => {
  const userData = useSelector(state=>state.users.value);  
  const [isLoading, setIsLoading] = useState(false);
  const axiosInstance = useAxiosInstance();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passHide, setPassHide] = useState(true);
  const dispatch = useDispatch();
  const history = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("user")) {
  //     const data = JSON.parse(localStorage.getItem("user"));
  //     console.log(data)
  //     dispatch(login({ username: data.username, tokin: data.tokin ,id:data.id,role:data.role}));
  //   }
  //   }, []);
  const handleSubmit = (value) => {
    setIsLoading(true);
    axiosInstance
      .post("/login", {
        email: value.email,
        password: value.name + value.email,
      })
      .then((res) => {
        if (res.data.user.verified) {
          toast.success("Signed in Successfully");
          dispatch(
            login({
              username: res.data.user.name,
              tokin: res.data.token,
              role: res.data.user.role,
              id: res.data.user._id,
              verified: res.data.user.verified,
            })
          );
        } else {
          toast.warning("You Have Been Banned From Meepleton");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        axiosInstance
          .post("/register", {
            name: value.name,
            email: value.email,
            password: value.name + value.email,
          })
          .then((res) => {
            toast.success("Signed Up Successfully!");
            dispatch(
              login({
                username: res.data.user.name,
                tokin: res.data.token,
                role: res.data.user.role,
                id: res.data.user._id,
                verified: res.data.user.verified,
              })
            );
            setIsLoading(false);
          })
          .catch((err) => {
            toast.error("Something went Wrong!");
            console.log(err);
            setIsLoading(false);
          });
        setIsLoading(false);
      });
  };

  return (
    <>
    <UserLayout>
      {isLoading ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
              width: "100%",
            }}
          >
           <Loading/>
          </div>
        </>
      ) : (
        <>
        <section className="bg-white py-8 md:py-8 dark:bg-gray-900">
            <div className="mx-auto grid max-w-screen-2xl pt-20 md-max:flex md-max:flex-col px-4 lg:grid-cols-12 lg:gap-8  xl:gap-0">
              <div className="mr-auto place-self-center lg:col-span-7">
                <h1 className="mb-4 max-w-2xl text-3xl tracking-tight font-extrabold leading-none  dark:text-white md:text-4xl xl:text-5xl">
                  Welcome to Meepleton!
                </h1>
                <p className="max-w-2xl mb-6 font-light text-gray-123 dark:text-gray-400 md:text-lg lg:mb-8 lg:text-xl">
                Meepleton, Bhopal's premier board game cafe, provides a friendly and fun environment for modern board game enthusiasts. Our unique "Partis" chat application facilitates easy socialization by helping users organize and join cafe gatherings, making Meepleton the ultimate destination for board game lovers in Bhopal.
                </p>
              </div>
              <div className="md-max:mt-6 lg:col-span-5 lg:mt-0 lg:flex">
              <div>
            {userData.isAuthenticated ? <p className="mb-4">Successfully Signed up. Feel free to explore our party Section. To close click <a className="text-teal-400 cursor-pointer" onClick={()=>props.onClose()}>here</a></p> : <p className="mb-4">Please sign-in to your account to continue</p>}
            {userData.isAuthenticated === false && <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleSubmit(decodeToken(credentialResponse.credential));
                }}
                onError={() => {
                  toast.error("Login Failed");
                }}
              />
            </div>
            }
          </div>
              </div>
            </div>
          </section>
        </>
      )}
      </UserLayout>
    </>
  );
};

export default UserLoginPage;
