import { toast } from "react-toastify";
import useAxiosInstance from "../../../../axiosInstance";
import React, { useEffect } from "react";
import MainTable from "components/table";
import Loading from "components/loading";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";

import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import { MdBarChart, MdRefresh } from "react-icons/md";
import PrimaryHeading from "components/heading/PrimaryHeading";
import MainTable2 from "./tableWraper";
import Widget from "components/widget/Widget";
import { HiStatusOnline } from "react-icons/hi";
import { BsCash } from "react-icons/bs";
import SelectField from "components/fields/selectField";

const ShowInvoices = (props) => {
  const [refresh, setRefresh] = React.useState(0);
  const [data, setData] = React.useState([]);
  const axiosInstance = useAxiosInstance();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoading2, setIsLoading2] = React.useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [customerName, setCustomerName] = React.useState("");
  const [customerMb, setCustomerMb] = React.useState("");
  const [customerAddress, setCustomerAddress] = React.useState("");

  const [gameHost, setGameHost] = React.useState("");
  const [gameHostId, setGameHostId] = React.useState("");

  const [id, setid] = React.useState("");

  //errors for price

  const [nameMessage, setnameMessage] = React.useState("");
  const [nameState, setnameState] = React.useState();

  const [priceMessage, setpriceMessage] = React.useState("");
  const [priceState, setpriceState] = React.useState();

  const handleEdit = (e) => {
    e.preventDefault();
    setIsLoading2(true);
    axiosInstance
      .patch("/invoice/" + id, {
        customerName,
        customerMb,
        customerAddress,
        gameHost,
        gameHostId,
      })
      .then((res) => {
        if (res.data) {
          toast.success(
            'invoice "' + res.data.name + '" is Edited Successfully!'
          );
        }
      setIsLoading2(false);
      })
      .catch((err) => {
        console.log(err)
        if (err.response.data.errors.msg) {
          toast.error("invoice with Name already exists!");
        }
        setIsLoading2(false);
      });
  };

  // React.useEffect(() => {
  //   if(name === ""){
  //     setnameMessage("Name cannot be empty!");
  //     setnameState()
  //   }
  //   else {
  //     setnameMessage("");
  //     setnameState('success');
  //   }

  //   if(price === ""){
  //     setpriceMessage("")
  //     setpriceState()
  //   }else if (price> 0) {
  //     setpriceMessage("Price must be a Negative Integer!");
  //     setpriceState('error')
  //   } else {
  //     setpriceMessage("");
  //     setpriceState('success')
  //   }
  // }, [name, price]);

  const deleteFunc = (id) => {
    var confirm = window.confirm("Do you want to delete #" + id + " invoice ?");
    if (confirm) {
      setIsLoading(true);
      axiosInstance
        .delete("/invoice/" + id)
        .then((res) => {
          if (res.data.msg) {
            toast.success("invoice is deleted Successfully!");
            // setData(data.filter(res=>res._id!==id))
          }
        })
        .catch((err) => {
          toast.error("Something Went Wrong!");
        });
      setIsLoading(false);
    }
  };

  const editFunc = (id) => {
    onOpen();
    setCustomerName("");
    setCustomerAddress("");
    setCustomerMb("");
    setGameHost("");
    setGameHostId("");
    toast.info("Looking for invoice in database");
    axiosInstance.get("/invoice/" + id).then((res) => {
      toast.success("Successfully founded invoice");
      const invoiceData = res.data;
      const {
        _id,
        customerName,
        customerAddress,
        customerMb,
        gameHost,
        gameHostId,
      } = invoiceData;
      setCustomerName(customerName);
      setCustomerAddress(customerAddress);
      setCustomerMb(customerMb);
      setGameHost(gameHost);
      setGameHostId(gameHostId);
      setid(_id);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get("/users/allMod")
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-screen flex-col items-center justify-center">
        <div className="flex items-center justify-center">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <>
      <PrimaryHeading title={"Show Invoices"} />

      {
        <MainTable2
          variant={"INVOICE"}
          url={"/invoice/all/structured"}
          editFunc={editFunc}
          deleteFunc={deleteFunc}
        />
      }

      <Modal isOpen={isOpen} onClose={onClose} className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <div className=" rounded-primary bg-white p-4 dark:border-navy-900 dark:bg-navy-800 ">
              {/* <img src={navlogo} height={100}/> */}
              {/* name */}

              <form
                onSubmit={(e) => {
                  handleEdit(e);
                }}
              >
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Customer Mobile Number"
                  placeholder="Mb"
                  id="customerMb"
                  type="tel"
                  //state={priceState}
                  value={customerMb}
                  onChange={(e) => {
                    setCustomerMb(e.target.value);
                  }}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Customer Name"
                  placeholder="Name"
                  id="customerName"
                  type="name"
                  //state={nameState}
                  value={customerName}
                  onChange={(e) => {
                    setCustomerName(e.target.value);
                  }}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Customer Address"
                  placeholder="Address"
                  id="customerAddress"
                  type="text"
                  //state={priceState}
                  value={customerAddress}
                  onChange={(e) => {
                    setCustomerAddress(e.target.value);
                  }}
                />
                <SelectField
                  label={"GameHost Name"}
                  selectedValue={[
                    { label: "Choose", value: "" },
                    ...data.map((elem) => ({
                      label: elem.name,
                      value: elem.name,
                    })),
                  ].find((elem) => elem.value === gameHost)}
                  onChange={(e) => {
                    setGameHost(e.value);
                    const sunAdminInfoInfo = data.find(
                      (elem) => elem.name === e.value
                    );
                    if (sunAdminInfoInfo) {
                      setGameHostId(sunAdminInfoInfo._id);
                    }
                  }}
                  options={[
                    { label: "Choose", value: "" },
                    ...data.map((elem) => ({
                      label: elem.name,
                      value: elem.name,
                    })),
                  ]}
                  isSearchable={false}
                />
                {isLoading2 ? (
                  <button
                    disabled
                    className="linear mt-2 rounded-xl bg-brand-500 px-4 py-[12px] text-center text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    <Loading />
                  </button>
                ) : (
                  <button
                    type={"submit"}
                    className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 me-2 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                  >
                    Save Changes
                  </button>
                )}
                <button
                  type="button"
                  onClick={onClose}
                  className="linear mt-2 rounded-xl bg-brand-600 px-4 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-700 active:bg-brand-800 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                >
                  Close
                </button>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ShowInvoices;
